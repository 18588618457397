import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Box, Typography } from '@mui/material';
import sharepoint from '../assets/Images/technologies/SharePoint.png'; 
import exchange from '../assets/Images/technologies/Exchange.png'; 
import openai from '../assets/Images/technologies/OpenAI.png'; 
import asp from '../assets/Images/technologies/ASP.png'; 
import dynamic from '../assets/Images/technologies/dynamic365.png'; 
import powerplatform from '../assets/Images/technologies/PowerPlatform.png'; 
import powerBi from '../assets/Images/technologies/PowerBI.png'; 
import office365 from '../assets/Images/technologies/office365.png'; 
import aws from '../assets/Images/technologies/aws.png'; 
import Azure from '../assets/Images/technologies/Azure.png'; 
import Salesforce from '../assets/Images/technologies/Salesforce.png'; 
import '../styles/sections/Technologies.css';

const technologiesList = [
  { img: sharepoint, alt: 'SharePoint Image', text: 'SharePoint' },
  { img: exchange, alt: 'Excel Exchange Image', text: 'Excel Exchange' },
  { img: openai, alt: 'Open AI Image', text: 'Open AI' },
  { img: asp, alt: 'ASP.NET Image', text: 'ASP.NET' },
  { img: dynamic, alt: 'Dynamics 365 Image', text: 'Dynamics 365' },
  { img: powerplatform, alt: 'Power Platform Image', text: 'Power Platform' },
  { img: office365, alt: 'Office 365 Image', text: 'Office 365' },
  { img: powerBi, alt: 'Power BI Image', text: 'Power BI' },
  { img: aws, alt: 'AWS', text: 'AWS' },
  { img: Azure, alt: 'Azure', text: 'Azure' },
  { img: Salesforce, alt: 'Salesforce', text: 'Salesforce' }
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    slidesToSlide:5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 2,
  }
};

const Technologies = () => (
  <Box id="Technologies"
    className="outerbox whitebgcolor">
    <Box className="OurTechnologies">
      <Box className="boxcontent">
        <Typography variant="h5" gutterBottom className="heading blackcolor">
          Technologies
        </Typography>
        </Box>
        <Box className="carousel-container Technologies">
          <Carousel
            responsive={responsive}
            arrows={false} // Hide arrows
            containerClass="carousel-container"
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={4000}
            itemClass="carousel-item"
          >
            {technologiesList.map((item, index) => (
              <Box
                sx={{ p: 2 }}
                className="carousel-item"
                key={index}
              >
                <img
                  src={item.img}
                  alt={item.alt}
                  className="carousel-image"
                />
              </Box>
            ))}
          </Carousel>
        </Box>
        </Box>
        </Box>
    );

    export default Technologies;
