// TabPanel.tsx
import React from 'react';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  bgColor?: string; // Optional background color for the TabPanel
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, bgColor, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, bgcolor: bgColor || 'background.paper' }}>
          {children}
        </Box>
      )}
    </div>
  );
};

export default TabPanel;
