import React, { useEffect, useRef, useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close'; // Import Close Icon
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import logo from '../assets/Images/header/logo.png';

const Header: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const [activeLink, setActiveLink] = useState<string>('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Check if it's mobile view
  const isTabletOrLarger = useMediaQuery(theme.breakpoints.up('md')); // Check if it's tablet or larger view
  const location = useLocation();
  let isWidgetHidden = false;
  const servicePaths = ['/artificial-intelligence', '/sharepoint-consulting/', '/salesforce-consulting/', '/azure-aws-cloud/', '/microsoft-power-platform/', '/mobile-solutions/', '/custom-application-development/', '/content-management-systems/'];
  const isServicePage = servicePaths.some(path => location.pathname.includes(path));
  const navigate = useNavigate();

  const handleActiveClass = (link: string) => {
    if (link == "services") {
      localStorage.setItem('isServiceClick', 'true');
      if (location.pathname !== "/") {
        navigate(`/#${link}`); // Navigate to home with hash
      } else {
        window.location.hash = link; // Only update hash if already on home
      }
    }
    if (link == 'work') {
      sessionStorage.setItem("Selected-Vertical", "All");
      sessionStorage.setItem("Selected-Technology", "All");
    }
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
    document.documentElement.style.overflow = 'hidden';
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    document.documentElement.style.overflow = 'auto';
  };
  const handleWorkDrawerClose = () => {
    sessionStorage.setItem("Selected-Vertical", "All");
    sessionStorage.setItem("Selected-Technology", "All");
    setIsDrawerOpen(false);
    document.documentElement.style.overflow = 'auto';
  };
  const handleServiceDrawerClose = () => {
    localStorage.setItem('isServiceClick', 'true');
    setIsDrawerOpen(false);
    if (location.pathname !== "/") {
      navigate(`/#services`); // Navigate to home with hash
    } else {
      window.location.hash = 'services'; // Only update hash if already on home
    }
    document.documentElement.style.overflow = 'auto';
  }
  const handleDrawerCloseLogoClick = () => {
    setIsDrawerOpen(false);
    document.documentElement.style.overflow = 'auto';
    if (window.location.pathname === '/' && !window.location.hash) {
      window.location.reload(); // Reload the page if already on the home page
    } else {
      window.location.href = '/'; // Redirect to the home page if not on home page
    }
  };
  const [scrolled, setScrolled] = useState(false); // Track scroll state
  useEffect(() => {
    if (location.pathname.includes("careers")) {
      localStorage.setItem("isShowWidget", "false");
      // window.Tawk_API.hideWidget();
    } else {
      localStorage.setItem("isShowWidget", "true");
      // window.Tawk_API.showWidget();
    }
if(location.pathname === '/careers' || location.pathname === '/careers/'){
  handleActiveClass('careers');
}
else if(location.pathname === '/contact/' || location.pathname === '/contact' ){
  handleActiveClass('contact');
}
  }, [location.pathname]);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 400) { // Change background after 50px scroll
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <AppBar className={`headerbox ${isDrawerOpen ? 'scrolledSticky' : scrolled ? 'scrolled' : ''}`}> {/* Light red background color */}
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          {/* Logo */}
          <a href="/" className="BRLogo" onClick={handleDrawerCloseLogoClick}>
            <img src={logo} alt="Logo" />
          </a>
        </Box>

        {/* Links for larger screens */}


        {/* Hamburger Menu for mobile view */}
        {isMobile && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: 'right' }}>
              <span
                color="inherit"
                onClick={isDrawerOpen ? handleDrawerClose : handleDrawerOpen} // Toggle between open/close
              >
                {isDrawerOpen ? <span className="cd-nav-trigger close-nav"><div className="cd-icon"></div>
                </span> : <span className="cd-nav-trigger">
                  <div className="cd-icon"></div>
                </span>} {/* Toggle between Menu and Close icon */}
              </span>

              {/* Mobile Full-Screen Drawer */}
              <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={handleDrawerClose}
                className='mobilemenu'
                sx={{
                  '& .MuiDrawer-paper': {
                    width: '100%', // Full width for mobile view
                    height: '100%', // Full height
                    backgroundColor: '#000', // Same background color
                    display: 'flex',
                    justifyContent: 'center', // Center content vertically
                    alignItems: 'center', // Center content horizontally
                    // Adjust the transform property for the desired effect
                    transform: isDrawerOpen ? 'translateX(0)' : 'translateX(100%)', // Slide out to the right when closed
                    transition: 'transform 0.3s ease-in-out', // Smooth transition effect
                  },
                }}
              >
                {/* Close Icon in Drawer */}
                <IconButton
                  onClick={handleDrawerClose}
                  sx={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    color: 'black', // Ensure visibility of the icon
                  }}
                >
                  <CloseIcon />
                </IconButton>

                {/* List of Items Centered */}
                <List>
                  {/* <ListItem onClick={handleDrawerClose} component={HashLink} to="/#work">
                    <Typography variant="h6" align="center">Work</Typography>
                  </ListItem> */}
                <ListItem>  <a onClick={handleWorkDrawerClose} href="/work">  Work  </a></ListItem>
                <ListItem>  <a onClick={handleServiceDrawerClose} href="#services">  Services  </a></ListItem>
                <ListItem>  <a onClick={handleDrawerClose} href="/careers">  Careers  </a></ListItem>
                <ListItem>  <a onClick={handleDrawerClose} href="/contact">  Contact us  </a></ListItem>
                  {/* <ListItem onClick={handleServiceDrawerClose} component={HashLink} to="/#services">
                    <Typography variant="h6" align="center">Services</Typography>
                  </a>
                  <a onClick={handleDrawerClose} href="/careers">
                    <Typography variant="h6" align="center">Careers</Typography>
                  </a>
                  <a onClick={handleDrawerClose} href="/contact">
                    <Typography variant="h6" align="center">Contact us</Typography>
                  </ListItem> */}
                </List>
              </Drawer>
            </Box>
          </>
        )}
        {/* Links for larger screens */}
        <Box sx={{ display: { xs: 'none', md: 'flex', alignItems: 'baseline', flexGrow: 1, justifyContent: 'right' } }} >
          <div className="TopNavigation">
            {/* <Button color="inherit" component={HashLink} className={location.pathname === '/work' || location.hash === '#work' || location.pathname.includes('/case-study') ? 'active-menu' : ''} onClick={() => handleActiveClass('work')} to="/#work">WORK</Button> */}
            <a color="inherit" className={location.pathname === '/work' || location.pathname === '/work/'  ? 'active-menu' : ''} onClick={() => handleActiveClass('work')} href="/work">WORK</a>
            <a color="inherit" className={isServicePage || location.hash === '#services' ? 'active-menu' : ''} onClick={() => handleActiveClass('services')} href="#services">SERVICES</a>
            <a color="inherit" className={location.pathname === '/careers' || location.pathname === '/careers/' ? 'active-menu' : ''} onClick={() => handleActiveClass('careers')} href="/careers">CAREERS</a>
            <a color="inherit" className={location.pathname === '/contact/' || location.pathname === '/contact'? 'active-menu' : ''} onClick={() => handleActiveClass('contact')} href="/contact/">CONTACT US</a></div>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
