import React from 'react'
import { Typography, Container, Box, Link } from '@mui/material';
import useMetaTags from '../common-functions/useMetaTags';

const NotFoundPage: React.FC = () => {
    useMetaTags(
        "Page Not Found | Binary Republik",
        "",
        ""
      );
return(
    <>
    <Container maxWidth="md">
        <Box sx={{ padding: 4, textAlign: 'center' }} className="boxcontent pagenotfound ">
            <div>
            <Typography variant="h2" gutterBottom>Page Not Found</Typography>
            <Typography variant="body1">The page you're looking for doesn't exist.</Typography>
            <Link href="/">Return to Home</Link></div>
        </Box>
    </Container>
    </>
 
)
}
export default NotFoundPage 