import React, { useState } from "react";
import {
    Typography,
    Button,
    Card,
    CardContent,
    CardMedia,
    Box,
    Stack,
    Divider,
    Modal,
} from "@mui/material";
import ContactFormModel from "./ContactFormModel";
import { useNavigate } from "react-router-dom";
import {works} from "../../interface/Works";

const OurWorkCaseStudyCards: React.FC<{work:works,index:number}> = ({
    work,index
}) => {
    const [showContactForm, setShowContactForm] = useState<boolean>(false);
    const popupOpen = () => {
        setShowContactForm(true);
    };
    const closeContactForm = () => {
        setShowContactForm(false);
    };
    const navigate = useNavigate();

    return (
        <div>
            <Stack direction="row" spacing={4} sx={{ overflowX: 'auto', justifyContent: 'center' }} >
                <Card
                className="casestudies"
                    sx={{
                        maxWidth: 345,                        
                        backgroundColor: 'white',
                        color: '#000',
                        margin: 5,
                        padding: 0,
                        '&:hover': {
                            backgroundColor: '#d1422c',
                            color: 'white',
                        },
                        transition: 'background-color 0.3s, color 0.3s',
                    }}
                >
                     <CardContent className="casestudiesIteam">
                        <Box display="flex" justifyContent="center">
                        <CardMedia
                            component="img"                           
                            // loading="lazy"
                            image={work.projectImage}
                            alt={`${work.clientName} Logo`}
                            sx={{ margin: 'auto', padding: 0, width: 'auto', maxWidth: '100%' }}
                        />
                    </Box>
                   
                        <Typography variant="body2" component="p" align="center" className="name">
                            {work.clientName} - {work.location}
                        </Typography>
                        <Typography variant="h6" component="h3" align="center" gutterBottom>
                            {work.projectTechnology}
                        </Typography>
                        <Typography variant="body2" component="p" align="center">
                            {work.projectDescription}
                        </Typography>
                        <Typography variant="body2" component="p" align="center" gutterBottom>
                            Tags: {work.skills}
                        </Typography>
                    </CardContent>
                    <Box display="flex" justifyContent="center" mb={2} className="workbtn">
                        {work.demoURL === 'popupOpen' || work.demoURL===null || work.demoURL===''|| work.demoURL===undefined ? (
                            <Button
                                onClick={popupOpen}
                              className="showcase-btn"
                            >
                                Case Study
                            </Button>
                        ) : (
                            <Button
                            onClick={() => navigate(`/case-study/${work.demoURL.toLowerCase()}`)}
                                className="showcase-btn"
                            >
                                Case Study
                            </Button>
                        )}
                    </Box>
                    {/* Add the horizontal line */}
                   
                </Card>
            </Stack>
            {showContactForm && 
            <Modal
            open={showContactForm}
             // This will be triggered on clicking outside the modal
        >
            <Box>
            <ContactFormModel closeContactForm={closeContactForm} projectName={work.projectName} pageName="work" />
            </Box></Modal>
            }
        </div>
    );
};

export default OurWorkCaseStudyCards;
