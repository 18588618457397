// src/pages/PositionChild.tsx
import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ShareIcon from '@mui/icons-material/Share';
import { Link } from 'react-router-dom';
import { JobOpening } from '../../interface/JobOpeningProps';


const PositionChild: React.FC<{ job: JobOpening }> = ({ job }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const toggleTooltip = () => setTooltipVisible(!isTooltipVisible);
  const handleMouseEnter = () => setTooltipVisible(true);
  const handleMouseLeave = () => setTooltipVisible(false);

  const currentUrl = window.location.href;
  const encodedTitle = encodeURIComponent(`Job Opening @ Binary Republik - ${job.openingTitle}`);
  const encodedDescription = encodeURIComponent(
    `Binary Republik is an Offshore SharePoint Consulting and Development Company based out of India focusing on SharePoint Implementations, Office 365, SharePoint App Model, SharePoint 2016, SharePoint 2013, SharePoint Customization, and SharePoint Branding.`
  );
  const facebookLink = `http://www.facebook.com/sharer.php?u=${currentUrl}&title=${encodedTitle}`;
  const twitterLink = `http://twitter.com/share?url=${currentUrl}&text=${encodedTitle}`;
  const gmailLink = `https://mail.google.com/mail/?view=cm&su=${encodedTitle}&body=${encodedDescription}%0D%0D${currentUrl}`;
  const blogLink = `https://www.blogger.com/blog-this.g?t&u=${currentUrl}&n=${encodedTitle}&b=${encodedDescription}<br><br>${currentUrl}`;
  const linkedinLink = `http://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}&title=${encodedTitle}`;
  const googleplusLink = `https://plus.google.com/share?url=${currentUrl}`;

  return (
    <Box className="position_list">
      <Typography variant='h5'  className="position_list_title">
        {job.openingTitle}
      </Typography>
      <Typography className="posittion_details">Experience: {job.experience}<br></br>
      No. of Vacancies: {job.numberOfVacancies}<br></br>
      Location: {job.location}</Typography>
    
      {/* Using Box with Flexbox for layout */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} className="sharebtngroup">
        <Box  >
          <Link
            to={`/careers/${job.openingTitle.toLowerCase().replace(/[^a-z0-9]/gi, '-').replace(/-+/g, '-').replace(/^-|-$/g, '')}`}
            state={{ job }} // Pass the job data
            className='position_btn'
          >
          
              <p>View details</p> <span><AddRoundedIcon /></span>
          
          </Link>

        </Box>
         <Box sx={{  textAlign: 'center' }}>
          <Link to={`/careers/${job.openingTitle.toLowerCase().replace(/[^a-z0-9]/gi, '-').replace(/-+/g, '-').replace(/^-|-$/g, '')}/#apply`}
            state={{ job }} // Pass the job data
           className='position_btn'
          >
          
          <p>Apply now</p> <span><AddRoundedIcon  /></span>
         
          </Link>
        </Box>
        
        {/*<Box sx={{ flex: 1, textAlign: 'center' }}>
          <Typography variant='body1' display="flex" alignItems="center" justifyContent="left">
            Share <ShareIcon sx={{ ml: 1 }} />
          </Typography>
        </Box> */}
        <Box sx={{ textAlign: 'center', position: 'relative' }} className="sharebtn">
          <Box className="tooltip" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Typography
            variant="body1"
            display="flex"
            alignItems="center"
            justifyContent="left"
            sx={{ cursor: 'pointer', position: 'relative' }}
  className="sharebtn"
            onClick={toggleTooltip}
            
          >
            <p>Share</p> <span><ShareIcon  /></span>
          </Typography>

          {/* Tooltip with social media links */}
          {isTooltipVisible && (
          <Box
           
            onMouseEnter={handleMouseEnter}
            className="tooltip-content"
          >
            
            <Box className="tooltip-inner" sx={{ textAlign: 'left' }}>
              <ul className="shareicons" style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                <li>
                  <a href={facebookLink} target="_blank" rel="noopener noreferrer">
                    <img src="/assets/Images/career/f-Facebook.png" alt="facebook" style={{ width: '24px', marginRight: '8px' }} />
                  </a>
                </li>
                <li>
                  <a href={twitterLink} target="_blank" rel="noopener noreferrer">
                    <img src="/assets/Images/career/f-Twitter.png" alt="twitter" style={{ width: '24px', marginRight: '8px' }} />
                  </a>
                </li>
                <li>
                  <a href={googleplusLink} target="_blank" rel="noopener noreferrer">
                    <img src="/assets/Images/career/f-GooglePlus.png" alt="google plus" style={{ width: '24px', marginRight: '8px' }} />
                  </a>
                </li>
                <li>
                  <a href={blogLink} target="_blank" rel="noopener noreferrer">
                    <img src="/assets/Images/career/f-Blogger.png" alt="blogger" style={{ width: '24px', marginRight: '8px' }} />
                  </a>
                </li>
                <li>
                  <a href={gmailLink} target="_blank" rel="noopener noreferrer">
                    <img src="/assets/Images/career/f-NewGmail.png" alt="gmail" style={{ width: '24px', marginRight: '8px' }} />
                  </a>
                </li>
                <li>
                  <a href={linkedinLink} target="_blank" rel="noopener noreferrer">
                    <img src="/assets/Images/career/f-linkedin.png" alt="linkedIn" style={{ width: '24px', marginRight: '8px' }} />
                  </a>
                </li>
              </ul>
            </Box>
          </Box>
          )}
        </Box></Box>
      </Box>
    </Box>
  );
};

export default PositionChild;
