import React, { useEffect, useState } from 'react';
import HeaderSection from '../sections/HeaderSection';
import { Backdrop, Box, CircularProgress, Container, Typography, useMediaQuery } from '@mui/material';
import ApplyNow from '../sub-sections/Careers/ApplyNow';
import { fetchJobOpeningsByUrlName } from '../services/ApiService';
import { useLocation } from 'react-router-dom';
import { JobOpening } from '../interface/JobOpeningProps';
import HeadImg from '../assets/Images/common/career.jpg';
import Footer from '../components/Footer';
import useMetaTags from '../common-functions/useMetaTags';
import theme from '../theme';

const JoinOurTeam: React.FC = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    useMetaTags(
        "Binary Republik - Careers",
        "SharePoint Consulting – Careers | Reach out to us if you aspire to work with the most relevant technologies to stay ahead of the curve.",
        ""
    );
    const location = useLocation();
    const pathname = location.pathname;
    const jobOpening = pathname.split("/")[2];
    const [jobDetail, setJobDetail] = useState<JobOpening>();

    // Scroll to the element with the hash
    const scrollToHashElement = () => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1)); // Get the element by ID
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' }); // Smooth scrolling
            }
        }
    };
    // Scroll on hash change
    // useEffect(() => {
    //     scrollToHashElement();
    //     const checkTawkReady = setInterval(() => {
    //         clearInterval(checkTawkReady);
    //         if (location.pathname.includes("/careers")) {
    //             console.log('Header page useEffect console for hide', localStorage.getItem('isShowWidget'));
    //             window.Tawk_API.hideWidget();
    //         }
    //     }, 100);
    // }, [location]);

    // Fetch job details and scroll after loading
    useEffect(() => {
        const loadJobs = async () => {
            const jobDetail = await fetchJobOpeningsByUrlName(jobOpening);
            setJobDetail(jobDetail);

            // Scroll after the data has loaded
            setTimeout(scrollToHashElement, 0);
        };
        loadJobs();
    }, [jobOpening]); // Add jobOpening as a dependency

    // Combine job data from location state and fetched data
    let job: JobOpening = location.state?.job;
    job = job == null ? jobDetail as JobOpening : job;

    // Format the date
    const formatDate = (dateString: string | Date): string => {
        return new Date(dateString).toLocaleString('en-US', {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        }).replace(',', '');
    };

    return (
        <>
            <HeaderSection pageName="Join Our Team" imageUrl={HeadImg} alt="Background Image" />
            {job.openingTitle != null || job.openingTitle != undefined ?
                <Box sx={{ padding: 4 }} className="whitebox contentbox">
                    <Container className="career-detail-page innerpagecontainer ">
                        <Typography variant="h5">{job.openingTitle}</Typography>
                        <Container className="position_details">
                            <Box className="position_dsc">
                                <Typography variant="body1"><b>Experience: </b><span>{job.experience}</span></Typography>
                                <Typography variant="body1"><b>Location: </b><span>{job.location}</span></Typography>
                                <Typography variant="body1"><b>No. of Vacancies: </b><span>{job.numberOfVacancies}</span></Typography>
                                <Typography variant="body1"><b>Job Type: </b><span>{job.jobType}</span></Typography>
                                <Typography variant="body1"><b>Date Posted: </b><span>{formatDate(job.postingDate)}</span></Typography>
                                <Typography variant="body1"><b>Organization: </b><span>{job.organization}</span></Typography>
                            </Box>
                            <Container className="expan_list">
                                <Box className="collapsdiv">
                                    <Typography variant="body1" className="jd" dangerouslySetInnerHTML={{ __html: job.discription }} />
                                </Box>
                            </Container>
                        </Container>
                        <ApplyNow />
                    </Container>
                </Box>
                :
                <Backdrop
                    sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>}
            <Footer hasWorkItem={false} />
            {isMobile ?
                (<a href="https://api.whatsapp.com/send?phone=919909950592" className="wupicoweb">
                    <img src="\assets\Images\icons\whatico.png" alt="Mailto" /></a>) :
                (<a href="https://web.whatsapp.com/send?phone=919909950592" target="_blank" className="wupicoweb">
                    <img src="\assets\Images\icons\whatico.png" alt="whatsapp" /></a>)}
        </>
    );
};

export default JoinOurTeam;
