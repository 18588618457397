// src/pages/SharePointEvents.tsx
import React, { useEffect, useState } from 'react';
import HeaderSection from '../sections/HeaderSection';
import { Box, Button, Card, CardContent, Container, Grid2, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { fetchArchivedEvent, fetchUpcomingEvent } from '../services/ApiService';
import { EventList } from '../interface/EventList';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import HeadImg from '../assets/Images/common/event-header.jpg';
import Footer from '../components/Footer';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Event } from '../interface/Event';
import EventCard from '../sub-sections/Events/EventCard';
import useMetaTags from '../common-functions/useMetaTags';

const SharePointEvents: React.FC = () => {
  useMetaTags(
    "Global SharePoint Events | Binary Republik",
    "Global SharePoint Events",
    ""
  );
  const [upcomingEvents, setUpcomingEvents] = useState<Event[]>([]);
  const [activeLink, setActiveLink] = useState<string>('upcoming');

  useEffect(() => {
    const loadEvents = async () => {
      try {
        const UpcomingEvent: Event[] = await fetchUpcomingEvent();
        setUpcomingEvents(UpcomingEvent);
      } catch (error) {
        console.error('Failed to load events:', error);
      }
    };

    loadEvents();
  }, []);

  const handleActiveClass = (link: string) => {
    setActiveLink(link);
  };
  const renderEventDate = (eventDate: Date) => {
    const date = new Date(eventDate);

    // Extract the day, month (short format), and year
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' }); // Example: "Nov"
    const year = date.getFullYear();

    return (
      <div className="spe_date_grop">
        <Typography variant="body2" className="spedate">
          {day}
        </Typography>
        <div className="spe_monthyear">
          <Typography variant="body2" className="speMonth">
            {month}
          </Typography>
          <Typography variant="body2" className="speyear">
            {year}
          </Typography>
        </div>
      </div>
    );
  };
  const [firstEvent, ...restEvents] = upcomingEvents;
  return (
    <>
      <HeaderSection pageName='Events' imageUrl={HeadImg} alt="Background Image" />

      <Box className="graybox">
        <Box className="innerpagecontainer ">
          <Container  className='contentpage'>
            <Typography variant="h2" gutterBottom>
              Upcoming SharePoint Events Globally
            </Typography>

            <Typography variant="body1" paragraph>
              SharePoint has a thriving community globally, which is actively involved in spreading the knowledge. Pick a location close to you and you should be able to find a SharePoint Saturday being hosted by your local SharePoint community.
            </Typography>

            <Typography variant="body1" paragraph>
              Listed here you will find details for all the major SharePoint and Office 365 related events, along with SharePoint Saturdays happening globally.{' '}
              <Box
                component="a"
                href="https://binaryrepublik.com/" // Add the actual link here
                className='underlineancorwhitebg'
                sx={{
                  textDecoration: 'none',
                  color: '#666'
                }}
              >
                Binary Republik
              </Box>{' '}
              is proud to be part of the SharePoint community!
            </Typography>

          </Container>



          <Container sx={{ display: 'flex', justifyContent: 'flex-end', mb: 4, }}  >
            <Stack direction="row" spacing={2} alignItems="center" className="spe-page-list">
              <Link to="/sharepoint-events" className={activeLink == 'upcoming' ? 'active' : ''} style={activeLink == 'upcoming' ? { pointerEvents: 'none', textDecoration: 'none', color: 'inherit' } : { textDecoration: 'none', color: 'inherit' }} onClick={() => handleActiveClass('upcoming')}>
                Upcoming
              </Link>
              <Typography>|</Typography>


              <Link to="/Archive-SharePoint-Events"
                style={activeLink == 'previous' ? { pointerEvents: 'none', textDecoration: 'none', color: 'inherit' } : { textDecoration: 'none', color: 'inherit' }} className={activeLink == 'previous' ? 'active' : ''} onClick={() => handleActiveClass('previous')}>

                Previous

              </Link>

            </Stack>
          </Container>

          <Container >

            {/* Card Grid Layout */}
            {upcomingEvents.length > 0 && (
              <Grid2 container spacing={3}>
                {/* First Row - 8 and 4 columns */}
                <Grid2 size={{ md: 8 }}>
                  <Card sx={{ backgroundColor: '#e2593a', color: 'white', height: 300, position: 'relative' }}>
                    <CardContent>
                      {/* Location in the top-right corner */}
                      <div className="spe_loction"><Typography
                        variant="body2"
                      >
                        <RoomOutlinedIcon /> {firstEvent.eventLocation}

                      </Typography></div>

                      {/* Event Date */}
                      {renderEventDate(firstEvent.eventDate)}

                      <hr></hr>
                      {/* Event Title */}
                      <Typography variant="body1" className="eventTitle">
                        {firstEvent.eventTitle}
                      </Typography>


                      {/* Event Description */}
                      <Typography variant="body2" className="eventDescription" sx={{ marginBottom: 2 }} >
                        {firstEvent.eventDescription}
                      </Typography>

                      {/* Event Link */}
                      <Link to={firstEvent.eventLink} className='morelink'>
                        View More <ArrowCircleRightIcon />
                      </Link>
                    </CardContent>
                  </Card>
                </Grid2>

                {/* Other Event Cards */}
                {restEvents.map((event: Event, index: number) => (
                  <EventCard key={index} event={event} />
                ))}
              </Grid2>

            )}</Container>
        </Box>
      </Box>
      <Footer hasWorkItem={false} />
    </>
  );
};

export default SharePointEvents;
