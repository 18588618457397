// src/pages/HomePage.tsx
import React from 'react';
import HeaderSection from '../sections/HeaderSection';
import SharepointConsultingSection from '../sections/SharepointConsultingSection';
import ContentManagementSystemsSection from '../sections/ContentManagementSystemsSection';
import CustomApplicationDevelopmentSection from '../sections/CustomApplicationDevelopmentSection';
import MobileSolutionsSection from '../sections/MobileSolutionsSection';
import MicrosoftPowerPlatformSection from '../sections/MicrosoftPowerPlatformSection';
import AzureAWSCloudSection from '../sections/AzureAWSCloudSection';
import SalesforceConsultingSection from '../sections/SalesforceConsultingSection';
import ArtificialIntelligenceSection from '../sections/ArtificialIntelligenceSection';
import HeadImg from '../assets/Images/common/career.jpg';

interface Page {
  pageName: string;
}
const ServicePages: React.FC<Page> = ({ pageName }) => {
  const renderContent = () => {
    switch (pageName) {
      case "ArtificialIntelligence":
        return (
          <>
            <HeaderSection pageName='Artificial Intelligence' imageUrl={HeadImg} alt="Background Image" />
            <ArtificialIntelligenceSection />
          </>
        );
      case "SharepointConsulting":
        return (
          <>
            <HeaderSection pageName='Sharepoint Consulting' imageUrl={HeadImg} alt="Background Image" />
            <SharepointConsultingSection />
          </>
        );
      case "SalesforceConsulting":
        return (
          <>
            <HeaderSection pageName='Salesforce Consulting' imageUrl={HeadImg} alt="Background Image" />
            <SalesforceConsultingSection />
          </>
        );
      case "AzureAWSCloud":
        return (
          <>
            <HeaderSection pageName='Azure, AWS Cloud' imageUrl={HeadImg} alt="Background Image" />
            <AzureAWSCloudSection />
          </>
        );
      case "MicrosoftPowerPlatform":
        return (
          <>
            <HeaderSection pageName='Microsoft Power Platform' imageUrl={HeadImg} alt="Background Image" />
            <MicrosoftPowerPlatformSection />
          </>
        );

      case "MobileSolutions":
        return (
          <>
            <HeaderSection pageName='Mobile Solutions' imageUrl={HeadImg} alt="Background Image" />
            <MobileSolutionsSection />
          </>
        );

      case "CustomApplicationDevelopment":
        return (
          <>
            <HeaderSection pageName='Custom Application Development' imageUrl={HeadImg} alt="Background Image" />
            <CustomApplicationDevelopmentSection />
          </>
        );

      case "ContentManagementSystems":
        return (
          <>
            <HeaderSection pageName='Content Management Systems' imageUrl={HeadImg} alt="Background Image" />
            <ContentManagementSystemsSection />
          </>
        );
    }
  };
  return <>{renderContent()}</>;
};

export default ServicePages;
