import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import ImageSlider from './ImageSlider';
import CaseStudyLetsGetStarted from './CaseStudyLetsGetStarted';
import HeaderSection from '../../sections/HeaderSection';
import workJson from "../../Work.json";
import CustomerStories from '../../sections/CustomerStories';
import { workCaseStudy } from '../../interface/WorkCaseStudy';
import HeadImg from '../../assets/Images/common/career.jpg';
import Footer from '../../components/Footer';
import { caseStudyWorkFilter } from '../../common-functions/caseStudyWorkFilter';
import useMetaTags from '../../common-functions/useMetaTags';

const SharepointConsultingSJHC1: React.FC = () => {
    useMetaTags(
        "SharePoint Solutions | Case Study - Intranet Portal - BPM",
        "",
        ""
      );
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const additionalKeywords = ['sharepoint','microsoft','office','react','angular','ai','machinelearning'];
        const results = caseStudyWorkFilter(workJson.workCaseStudy,additionalKeywords,'healthcare','sharepoint-consulting-nj-usa-sjhc-1');
        setWorks(results);
    }, []);
    const sliderImages = [
        '/assets/Images/CaseStudy/sharepoint-consulting-nj-usa-sjhc-1/1.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-nj-usa-sjhc-1/2.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-nj-usa-sjhc-1/3.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-nj-usa-sjhc-1/4.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-nj-usa-sjhc-1/5.png',
    ];
    return (
        <>
            <HeaderSection pageName='Case Study'  imageUrl={HeadImg} alt="Background Image"  />
            <Box className=" whitebox contentbox" id="Project-Overview" sx={{ padding: 4 }} >
                <Container className="contentpage">
                    <Box className="casestudy_container">
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} className="compnay_detail_div">
                            <Box flex={1} className="company_logo" sx={{ textAlign: 'center', margin: 5 }}>
                                <img src='/assets/Images/work/south-jersey-healthcare.jpg' alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                            </Box>

                            <Box flex={2} p={2} className="company_detail">
                                <Typography variant="h5" className='cmp_dtl_title'>South Jersey Healthcare</Typography>
                                <Box mt={2} className="cmpdtl_fullbox" >
                                <div className="companydetails" >       <ul><li> <b>Location:</b></li><li><span>NJ, USA</span></li></ul>
                                    <ul><li> <b>Industry:</b></li><li><span>Healthcare</span></li></ul>
                                    <ul><li> <b>Environment:</b></li><li><span>SharePoint 2010</span></li></ul>
                                    <ul><li> <b>Skills:</b></li><li><span>State Machine Workflow, Visual Studio 2008, CSS, jQuery </span></li></ul>
                                    <ul><li> <b>Project(s):</b></li><li> <a className='active'><span>Intranet Portal - BPM</span></a></li></ul></div>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                <Container className="innerpagecontainer contentpage prj_overview_fulldiv">
                    <Typography variant="h2" gutterBottom className='prjover-main-title'>
                        Optimizing Financial Operations in South Jersey Healthcare Through Microsoft SharePoint Integration
                    </Typography>
                    {/* : null} */}

                    <Box display="flex" flexDirection={{ xs: 'column', md: "row" }} >
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'column' }} className="prj_overview_div">
                            {/* About the Client */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">About the Client</Typography>
                                <Typography variant='body2' className='prjover-content'>South Jersey Healthcare (SJHC) is a nonprofit healthcare organization dedicated to serving the community of Southern New Jersey. With a legacy dating back to 1898 when Bridgeton Hospital first began its mission, SJHC has been the primary healthcare provider in the region for the past 15 years.</Typography>
                                <Typography variant='body2' className='prjover-content'>SJHC encompasses three hospitals, numerous multi-specialty health centers, and a comprehensive cancer center, totaling over 100 locations across Gloucester, Cumberland, Salem, and Camden counties. Within these facilities, SJHC offers a range of services including rehabilitation, urgent care operations such as outpatient imaging, cardiac testing, sleep disorder diagnosis and treatment, and various other medical practices.</Typography>
                            </Box>
                            {/* The Challenge */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">The Challenge</Typography>
                                <Typography variant='body2' className='prjover-content'>SJHC previously relied on a manual process for managing Capital and Operational Expense Requests, where requests were recorded on paper forms and then manually entered Excel workbooks at different stages of the process.</Typography>
                                <Typography variant='body2' className='prjover-content'>This manual system posed challenges for periodic reporting and accurate forecasting of funds and expenditures.</Typography>
                                <Typography variant='body2' className='prjover-content'>Handling nearly 1,000 requests annually, the paper-based system failed to provide instant access to accurate information about ongoing requests, hindering efficiency and transparency within SJHC's operations.</Typography>
                                <Typography variant='body2' className='prjover-content'>Furthermore, given the advancements and availability of newer versions of SharePoint, such as 2010 and 2013, SJHC sought to upgrade their existing environments.</Typography>
                            </Box>

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">Objectives</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>- The transition involved migrating from MOSS 2007 to SharePoint 2010, followed by an upgrade to SharePoint 2013.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '60px' }}>-Customizing a solution to automate the financial processes for both Capital Expense requests and Operational Expense requests.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '60px' }}>-Implementing a SharePoint workflow to streamline budget approvals at each stage.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '60px' }}>-Developing a user-friendly dashboard for enhanced accessibility.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '60px' }}>-Creating a custom progress bar to track the status of requests at various stages.</Typography>
                            </Box>

                        </Box>
                        <Box display="flex" flexDirection={{ xs: 'column' }} >
                            {/* Image Slider Section */}
                            <Box className="prj_scrnshot" textAlign="center">
                                <ImageSlider imageList={sliderImages || []} />

                            </Box>
                        </Box>
                    </Box>

                    {/* Solution and Benefits */}
                    <Box className="prj_overview_fulldiv">
                        {/* The Solution */}

                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>The Solution</Typography>
                            <Typography variant='body2' className='prjover-content'>SJHC's existing environment was based on the MOSS 2007 platform.</Typography>
                            <Typography variant='body2' className='prjover-content'>Given the need to automate the Capital and Operational Expense Request processes, a decision was made to develop a custom solution using MOSS 2007 and Microsoft Visual Studio to streamline the entire financial process.</Typography>
                            <Typography variant='body2' className='prjover-content'>This custom solution included application pages, event receivers, and branding files such as CSS and jQuery, alongside state-machine workflows tailored to each aspect of the financial process.</Typography>
                            <Typography variant='body2' className='prjover-content'>Considering the multi-stage nature of each financial process and the necessity for iterative feedback, a state-machine workflow was crafted. This workflow orchestrates budget approvals at every stage. Upon creation of a budget approval request, it progresses through subsequent steps based on approvals until it reaches the final approval stage. At each stage, requests can be approved or returned to a previous stage for adjustments. Therefore, distinct workflows were devised for both Capital Expense requests and Operational Expense requests.</Typography>
                            <Typography variant='body2' className='prjover-content'>Additionally, a dashboard was designed to provide users with insights into pending tasks and their respective stages. Furthermore, a custom progress bar was developed to visually represent the progression of workflows.</Typography>
                            <Typography variant='body2' className='prjover-content'>With the introduction of SharePoint 2010, SJHC aimed to migrate their existing MOSS 2007 environment to leverage the latest Microsoft SharePoint technology and its enhanced features. This migration was executed using the content database migration approach. Moreover, several upgrades were implemented in the custom solution, enhancing the functionality of the financial processes with the incorporation of SharePoint 2010's capabilities.</Typography>
                            <Typography variant='body2' className='prjover-content'>Following the release of Microsoft SharePoint 2013, another content database migration was executed to transition the current environment from SharePoint 2010 to SharePoint 2013, accompanied by several enhancements to the custom solution.</Typography>
                            <Typography variant='body2' className='prjover-content'>Leveraging the SharePoint platform, the budgeting processes for Capital and Operational expenses were automated, significantly enhancing efficiency and effectiveness.</Typography>
                        </Box>
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>Benefits</Typography>
                            <Typography variant='body2' className='prjover-content'>Automating processes from endpoints enabled SJHC to save considerable time and costs associated with document management. Additionally, the implementation of a more accurate real-time reporting system for financial data allowed SJHC to streamline their entire process.</Typography>
                            <Typography variant='body2' className='prjover-content'>With the incorporation of dashboards, notifications, and improved search functionality, users can now navigate through requests efficiently and swiftly.</Typography>
                            <Typography variant='body2' className='prjover-content'>The real-time visual representation of data, including the progress bar, facilitated SJHC users in easily and swiftly identifying the stage of each request.</Typography>
                        </Box>

                    </Box>
                </Container >
            </Box >
            <Box className="Case-Study-List"  >
                {works.length > 0 &&
                    <CustomerStories pageName='caseStudy' workList={works} />
                }
            </Box>
            <CaseStudyLetsGetStarted projectName='Microsoft' />
            {works.length > 0 ?
                <Footer hasWorkItem={false}/>:<Footer hasWorkItem={true}/>
            }
        </>
    );
};

export default SharepointConsultingSJHC1;
