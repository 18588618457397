import { AppBar, Box, Grid2, Tab, Tabs, Typography } from '@mui/material';
import TabPanel from '../../components/TabPanel';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TabData } from '../../interface/TabData';



const ServiceTabbingSection: React.FC<{tabData:TabData[]}> = ({tabData}) => {
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
   

    const selectedTechnology = (technology: string) => {
        sessionStorage.setItem("Selected-Vertical", 'All');
        sessionStorage.setItem("Selected-Technology", technology);
    }

    return (
        <Box className=" blackbgcolor">
            <div className="tabWrapper">
                <Box className="BlackbgTab">

                    <AppBar position="static" className="pb_customtab">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="custom tabs"
                            variant="scrollable"
                            sx={{
                                flexWrap: 'wrap',
                                '& .MuiTab-root': {
                                    color: '#FFFFFF',
                                },
                            }}
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                        >
                            {tabData.map((tab, index) => (
                                <Tab
                                    key={index}
                                    label={tab.heading}
                                    id={`section-${index}`}
                                    aria-controls={`tabpanel-${index}`}

                                    sx={{
                                        minWidth: '120px',
                                        flexGrow: 1,
                                    }}
                                />
                            ))}
                        </Tabs>
                    </AppBar>
                    {tabData.map((tab, index) => (
                        <div className="tabsMainContent">
                            <TabPanel key={index} value={value} index={index} bgColor='#000'>
                                <Grid2 container spacing={2} className="tabsInnerContainer">
                                    <Grid2 size={{ sm: 4 }}>
                                        <div dangerouslySetInnerHTML={{ __html: tab.imgSrc }} className="serviceimage" />
                                        {/* <Grid2 size={{xs:4}}>
              <img src={tab.imgSrc} alt={tab.heading} style={{ width: '100%', height: 'auto' }} />
            </Grid2> */}</Grid2>
                                    <Grid2 size={{ sm: 8 }} className="techno_content">
                                        <Typography variant="h6" component="h2" >
                                            {tab.heading}
                                        </Typography>
                                        <div>{tab.content}</div>
                                    </Grid2>
                                </Grid2>
                            </TabPanel>
                        </div>
                    ))}
                </Box>
                {/* <TabComponent
                tabsData={tabData}
                appBarBackgroundColor="#00B7FFEB"
                tabTextColor="#FFFFFF"
                contentBackgroundColor="#00B7FFEB"
            /> */}
            </div></Box>
        // <div className="tabWrapper">
        //     <TabComponent
        //         tabsData={tabData}
        //         appBarBackgroundColor="#000"
        //         tabTextColor="#FFFFFF"
        //         contentBackgroundColor="##000"
        //         hoverColor="#FFFFFF"
        //     />
        // </div>
    );
};

export default ServiceTabbingSection;
