import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import ImageSlider from './ImageSlider';
import CaseStudyLetsGetStarted from './CaseStudyLetsGetStarted';
import HeaderSection from '../../sections/HeaderSection';
import workJson from "../../Work.json";
import CustomerStories from '../../sections/CustomerStories';
import { workCaseStudy } from '../../interface/WorkCaseStudy';
import HeadImg from '../../assets/Images/common/career.jpg';
import Footer from '../../components/Footer';
import { caseStudyWorkFilter } from '../../common-functions/caseStudyWorkFilter';
import useMetaTags from '../../common-functions/useMetaTags';

const SharepointConsultingProgressiveFinancial1: React.FC = () => {
    useMetaTags(
        "Binary Republik | Case Study - Intranet Portal",
        "",
        ""
      );
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const additionalKeywords = ['sharepoint','microsoft','react','angular','ai','machinelearning'];
        const results = caseStudyWorkFilter(workJson.workCaseStudy,additionalKeywords,'bfsi','sharepoint-consulting-az-usa-pfs-1');
       
        setWorks(results);
    }, []);
    const sliderImages = [
        '/assets/Images/CaseStudy/sharepoint-consulting-az-usa-pfs-1/pfs1.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-az-usa-pfs-1/pfs2.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-az-usa-pfs-1/pfs3.png',
    ];
    return (
        <>
           <HeaderSection pageName='Case Study'  imageUrl={HeadImg} alt="Background Image" />
            <Box className=" whitebox contentbox" id="Project-Overview" sx={{ padding: 4 }} >
                <Container className="contentpage">
                    <Box className="casestudy_container">
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} className="compnay_detail_div">
                            <Box flex={1} className="company_logo" sx={{ textAlign: 'center', margin: 5 }}>
                                <img src='/assets/Images/work/progressive-financial-services.jpg' alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                            </Box>

                            <Box flex={2} p={2} className="company_detail">
                                <Typography variant="h5" className='cmp_dtl_title'>Progressive Financial Services</Typography>
                                <Box mt={2} className="cmpdtl_fullbox" >
                                <div className="companydetails" >                            

                               <ul><li> <b>Location:</b></li><li><span>AZ, USA</span></li></ul>
                                    <ul><li> <b>Industry:</b></li><li><span>BFSI</span></li></ul>
                                    <ul><li> <b>Environment:</b></li><li><span>Office 365 - SharePoint Online</span> </li></ul>
                                    <ul><li> <b>Skills:</b></li><li><span>SharePoint Designer 2013, Infopath 2013, Sandbox Solution, JsLink, SharePoint Online</span></li></ul>
                                    <ul><li> <b>Project(s):</b></li><li><a className='active'><span>Intranet Portal</span></a></li> </ul>
                                    </div>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Container>
                <Container className="innerpagecontainer contentpage prj_overview_fulldiv">
                    <Typography variant="h2" gutterBottom className='prjover-main-title'>
                        Progressive Financial Services streamlines its operations by leveraging Office 365 - SharePoint Online Intranet Portal to automate formerly manual processes
                    </Typography>

                    <Box display="flex" flexDirection={{ xs: 'column', md: "row" }} >
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'column' }} className="prj_overview_div">
                            {/* About the Client */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">About the Client</Typography>
                                <Typography variant='body2' className='prjover-content'>Progressive Financial Services, Inc. (PFS) is a reputable national collection agency with branch offices strategically located in Arizona, Pennsylvania, and South Dakota.</Typography>
                            </Box>
                            {/* The Challenge */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">The Challenge</Typography>
                                <Typography variant='body2' className='prjover-content'>PFS sought to establish an intranet portal as the primary hub for collaboration among its employees.</Typography>
                                <Typography variant='body2' className='prjover-content'>Multiple departments within the organization relied on manual management of processes, resulting in inefficiencies and significant time consumption. The absence of a business process management system further compounded these challenges.</Typography>
                                <Typography variant='body2' className='prjover-content'>A central Document Management System (DMS) was missing.</Typography>
                                <Typography variant='body2' className='prjover-content'>Their HR onboarding process was manual, necessitating automation and streamlined workflows for enhanced efficiency.</Typography>
                                <Typography variant='body2' className='prjover-content'>PFS aimed to implement a complaint redressal portal to address the concerns of its employees effectively.</Typography>
                                <Typography variant='body2' className='prjover-content'>The Audit &amp; Compliance department sought to establish a collaboration platform tailored for auditors, audit directors, department directors, and Audit &amp; Compliance Managers.</Typography>
                                <Typography variant='body2' className='prjover-content'>In conclusion, the objective was to automate all manual processes utilizing SharePoint Online and to tailor specific solutions for each internal department's needs.</Typography>
                            </Box>

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">Objectives</Typography>
                                <Typography variant='body2' className='prjover-content'>Enhancing collaboration among its employees through a robust platform.</Typography>
                                <Typography variant='body2' className='prjover-content'>Implementing a centralized Document Management System accessible to all departments</Typography>
                                <Typography variant='body2' className='prjover-content'>Introducing automated solutions for QA scorecards and comprehensive rating calculations.</Typography>
                                <Typography variant='body2' className='prjover-content'>Streamlining HR-related processes through automation, particularly in onboarding.</Typography>
                                <Typography variant='body2' className='prjover-content'>Establishing a dedicated complaint redressal portal for employees.</Typography>
                                <Typography variant='body2' className='prjover-content'>Implementing an approval process for non-conformity reports within the Audit and Compliance department.</Typography>
                            </Box>

                        </Box>
                        <Box display="flex" flexDirection={{ xs: 'column' }} >
                            {/* Image Slider Section */}
                            <Box className="prj_scrnshot" textAlign="center">
                                <ImageSlider imageList={sliderImages || []} />
                            </Box>
                        </Box>
                    </Box>

                    {/* Solution and Benefits */}
                    <Box className="prj_overview_fulldiv">
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>The Solution</Typography>
                            <Typography variant='body2' className='prjover-content'>PFS sought to minimize investment in physical IT infrastructure and expressed a keen interest in cloud hosting for its applications. After careful consideration of its collaboration and DMS needs, SharePoint Online, hosted on Office 365, emerged as the preferred platform for developing a customized solution to address these requirements.</Typography>
                            <Typography variant='body2' className='prjover-content'>Forms and workflows were meticulously crafted to automate all manual processes within PFS. These bespoke solutions were tailored individually for each department to fulfill their distinct needs and requirements effectively.</Typography>
                            <Typography variant='body2' className='prjover-content'>A Document Management System (DMS) was deployed to facilitate efficient management of forms and official documents by PFS employees. Utilizing metadata and content types, tailored to departments and roles, the DMS featured an approval workflow for various templates. Upon approval or rejection by department directors, notification emails were promptly dispatched.</Typography>
                            <Typography variant='body2' className='prjover-content'>To streamline the process, QA department agents manually created scorecards to assess collector quality, commission, and overall ratings. Automating this, a scorecard form was developed in InfoPath. It calculated overall ratings based on input values, providing QA agents with real-time performance insights for collectors.</Typography>
                            <Typography variant='body2' className='prjover-content'>To streamline HR onboarding, various templates, including Personal Change Form (PCF), InfoPath forms, and SharePoint Designer workflows were developed. These templates covered different requests such as new employee hiring, managing employee details, voluntary resignation, termination, status, pay, department, and position changes. Employees would fill in information and submit it, triggering a workflow to notify HR and department directors for necessary action. HR could centrally track and manage all requests' statuses for enhanced efficiency.</Typography>
                            <Typography variant='body2' className='prjover-content'>A custom solution for the complaints redressal portal was created, incorporating an InfoPath form, document templates, and a workflow. Employees fill out the form, triggering the workflow to generate complaint documents with populated information, sending them to relevant departments and issuing notification emails. This automation streamlines manual processes while ensuring accessible records for future reference.</Typography>
                            <Typography variant='body2' className='prjover-content'>To automate non-conformity reporting in the Audit and Compliance Department, an InfoPath-based report was developed. Auditors fill in audit details and attach documents, triggering a workflow. Notifications are sent to the audit director, then to department directors and Audit and Compliance managers upon approval. Rejected reports are sent back to the auditor. Notifications are circulated to all stakeholders upon completion.</Typography>
                            <Typography variant='body2' className='prjover-content'>Accordingly, tailored solutions were developed for each department of PFS to address their unique business needs.</Typography>
                        </Box>
                        {/* Benefits */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>Benefits</Typography>
                            <Typography variant='body2' className='prjover-content'>A collaborative platform implemented across all departments of PFS. </Typography>
                            <Typography variant='body2' className='prjover-content'>Process-driven automation deployed for enhanced efficiency across departments. </Typography>
                            <Typography variant='body2' className='prjover-content'>Establishment of a central repository for streamlined access to all organizational content. </Typography>
                        </Box>
                    </Box>
                </Container >
            </Box >
            <Box id="Case-Study-List">
                {/* Title */}
                {works.length > 0 &&
                    <CustomerStories pageName='caseStudy' workList={works} />
                }
            </Box>
            <CaseStudyLetsGetStarted projectName='Intranet Portal' />
            {works.length > 0 ?
                <Footer hasWorkItem={false}/>:<Footer hasWorkItem={true}/>
            }
        </>
    );
};

export default SharepointConsultingProgressiveFinancial1;
