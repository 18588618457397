// ScrollToTop.tsx
import { useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ScrollToTop: React.FC = () => {
    const { pathname, hash } = useLocation();
    useLayoutEffect(() => {
        // Only scroll to top if it's not an anchor link (i.e., hash is empty)
        if (!hash) {
            window.scrollTo(0,0);
        }
        else{
            window.scrollTo(0,0);
        }
    }, [pathname, hash]); // Add hash as a dependency

    return null;
};

export default ScrollToTop;
