import React, { useEffect, useState } from 'react';
import { Box, Container, List, ListItem, ListItemText, Typography } from '@mui/material';
import sitefinity from '../assets/Images/services/sitefinity.png';
import umraco from '../assets/Images/services/umraco.png';
import sitecore from '../assets/Images/services/sitecore.png';
import CustomerStories from './CustomerStories';
import workJson from "../../src/Work.json";
import { workCaseStudy } from '../interface/WorkCaseStudy';
import Footer from '../components/Footer';
import { serviceWorkFilter } from '../common-functions/serviceWorkFilter';
import { CardData, TabData } from '../interface/TabData';
import ServiceTabbingSection from '../sub-sections/ServiceCommonComponent/ServiceTabbingSection';
import ServiceCardSection from '../sub-sections/ServiceCommonComponent/ServiceCardSection';
import CaseStudyLetsGetStarted from '../sub-sections/CaseStudy/CaseStudyLetsGetStarted';
import useMetaTags from '../common-functions/useMetaTags';

const ContentManagementSystemsSection: React.FC = () => {
    useMetaTags(
        "Enterprise CMS Solutions | Streamline & Optimize Content Management",
        "Optimize your digital content with our custom Content Management System (CMS) solutions. We design scalable, secure, and user-friendly CMS platforms tailored to your business needs.",
        "Content management system, CMS solutions, enterprise CMS, web content management, CMS development services, scalable CMS, CMS integration"
      );
    const tabData: TabData[] = [
        {
            heading:  (<>Umbraco CMS <br></br>Development</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/cms/umbraco.png" alt="umbraco-cms" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;">
            `,
            content: (<>
                <p>Umbraco is a powerful open-source CMS that provides flexibility, scalability, and seamless content management for businesses of all sizes. Our expertise ensures a customized, feature-rich website tailored to your specific needs.</p>
                <p>We offer Umbraco CMS development, integration, and support services to help businesses leverage its full potential for engaging digital experiences.</p>
                <p>Explore our Umbraco solutions by visiting <a href="/work" onClick={() => selectedTechnology('CMS')} className="underlineancor">this link</a>.</p>
            </>),
        },
        {
            heading:  (<>Sitefinity CMS <br></br>Development</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/cms/sitefinity.png" alt="sitefinity-cms" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;">
            `,
            content: (<>
                <p>Sitefinity is a robust enterprise-grade CMS that enables businesses to deliver personalized and scalable digital experiences. Our team specializes in Sitefinity development, customization, and API integration to create dynamic websites.</p>
                <p>With built-in marketing tools and seamless third-party integrations, Sitefinity enhances content management efficiency and digital engagement.</p>
                <p>Discover more about our Sitefinity expertise by visiting <a href="/work" onClick={() => selectedTechnology('CMS')} className="underlineancor">this link</a>.</p>
            </>),
        },
        {
            heading:  (<>WordPress <br></br>Development</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/cms/wordpress.png" alt="wordpress-cms" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;">
            `,
            content: (<>
                <p>WordPress is the world’s most popular CMS, offering extensive customization, plugin support, and ease of use. Our WordPress development services include theme design, custom plugins, and performance optimization.</p>
                <p>We build scalable and SEO-friendly WordPress websites that enhance brand visibility and user engagement.</p>
                <p>Learn more about our WordPress solutions by following <a href="/work" onClick={() => selectedTechnology('CMS')} className="underlineancor">this link</a>.</p>
            </>),
        },
        {
            heading:  (<>Sitecore CMS <br></br>Development</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/cms/sitecore.png" alt="sitecore-cms" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;">
            `,
            content: (<>
                <p>Sitecore is a leading digital experience platform that enables personalized customer journeys. Our Sitecore CMS development services help businesses leverage AI-driven content, omnichannel delivery, and seamless integrations.</p>
                <p>We specialize in Sitecore implementation, optimization, and custom module development to enhance business performance.</p>
                <p>Check out our Sitecore expertise by visiting <a href="/work" onClick={() => selectedTechnology('CMS')} className="underlineancor">this link</a>.</p>
            </>),
        }
    ];
    const cardsData: CardData[] = [
        {
            heading: 'Enterprise CMS Solutions',
            items: [
                'Scalable & Secure CMS Architecture',
                'AI-Driven Content Personalization',
                'Omnichannel Digital Experience',
                'Role-Based Access & Permissions',
                'SEO & Performance Optimization',
                'Cloud & On-Premise Deployment',
                'Enterprise-Grade Security & Compliance'
            ],
        },
        {
            heading: 'E-Commerce & CMS Integration',
            items: [
                'Seamless CMS & E-Commerce Integration',
                'AI-Powered Product Recommendations',
                'Custom Checkout & Payment Gateways',
                'Multi-Vendor & Marketplace Support',
                'Automated Inventory Management',
                'Personalized Customer Experience',
                'Marketing Automation & Analytics'
            ],
        },
        {
            heading: 'Content Management & Publishing',
            items: [
                'Intuitive Content Editing & Workflow',
                'Multi-Language & Localization Support',
                'Media & Digital Asset Management',
                'Version Control & Content History',
                'Automated Content Scheduling',
                'AI-Assisted Content Suggestions',
                'Collaborative Content Workflows'
            ],
        },
        {
            heading: 'CMS Support & Enhancements',
            items: [
                'Custom Module & Plugin Development',
                'Third-Party API & CRM Integration',
                'Ongoing Maintenance & Security Updates',
                'Cloud Migration & Scalability Solutions',
                'Mobile & Cross-Platform Compatibility',
                'Advanced SEO & Digital Strategy',
                '24/7 Technical Support & Assistance'
            ],
        },
    ];

    const selectedTechnology = (technology: string) => {
        sessionStorage.setItem("Selected-Vertical", 'All');
        sessionStorage.setItem("Selected-Technology", technology);
    }
    const [works, setWorks] = useState<workCaseStudy[]>([]);

    useEffect(() => {
        const keywords = ['content-management', 'content', 'content-management-system', 'content management system'];
        const additionalKeywords = ['technology', 'microsoft'];
        const results = serviceWorkFilter(workJson.workCaseStudy, keywords, additionalKeywords);
        setWorks(results);
    }, []);
    return (
        <>
            <Box sx={{ padding: 4, textAlign: 'center' }} className="contentbox whitebox " >
                <Container className="innerpagecontainer contentpage">
                    <Typography variant="h2" gutterBottom className='contentwidth to_build'>
                    Revolutionize Your Digital Presence with Our Intelligent Content Management Solution </Typography>

                    <Typography variant="body1" sx={{ marginBottom: 2 }} className='contentwidth'>
                    Optimize your content effortlessly with our advanced CMS solution. Enjoy seamless updates, strong security, and smart automation—designed to enhance productivity and deliver a superior digital experience</Typography>
                    <div className='servicesLogo'>
                        <ul>
                            <li><span><img src={sitefinity} alt="Sitefinity" /></span></li>
                            <li><span><img src={umraco} alt="Umraco" /></span></li>
                            <li><span><img src={sitecore} alt="Sitecore" /></span></li>
                        </ul>
                    </div>

                </Container>
            </Box>
            <Box className="outerbox powerbitab" sx={{ bgcolor: "#f2c811" }}  >
                <ServiceTabbingSection tabData={tabData} />
            </Box>
            <Box sx={{ padding: 4 }} className="contentbox whitebox contentpage"  >
                <Container className="innerpagecontainer contentpage" >
                    <ServiceCardSection cardsData={cardsData} verticalTitle='Smart & Scalable Content Management System Solutions for Seamless Content Management' />
                </Container>
            </Box>
            {works.length > 0 &&
                <CustomerStories pageName='CMS' workList={works} />
            }
            <CaseStudyLetsGetStarted projectName='Content Managemen System Service' />
            {works.length > 0 ?
                <Footer hasWorkItem={false} /> : <Footer hasWorkItem={true} />
            }
        </>
    );
};

export default ContentManagementSystemsSection;
