// src/pages/HomePage.tsx
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import HeaderSection from '../sections/HeaderSection';
import OpenPosition from '../sections/OpenPosition';
import HeadImg from '../assets/Images/common/career.jpg';
import Footer from '../components/Footer';
import useMetaTags from '../common-functions/useMetaTags';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import theme from '../theme';

const reloadScript = () => {
  const scriptId = "dynamic-script";
  const existingScript = document.getElementById(scriptId);

  if (existingScript) {
    existingScript.remove(); // Remove old script
  }

  const script = document.createElement("script");
  script.src = "/tawkto.js"; // Path to your public JS file
  script.id = scriptId;
  script.async = true;
  document.body.appendChild(script);
};

const Careers: React.FC = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); 
  useMetaTags(
    "Join Our SharePoint Team - Careers | Binary Republik",
    "SharePoint Consulting – Careers | Reach out to us if you aspire to work with the most relevant technologies to stay ahead of the curve.",
    ""
  );

  useEffect(() => {
      // Ensure the React component fully renders before loading the script
      const timeout = setTimeout(() => {
        reloadScript();
      }, 500); // Delay script loading by 100ms
  
      return () => clearTimeout(timeout); // Cleanup to avoid memory leaks
    }, []);
  localStorage.setItem('isShowWidget','false');
  const location = useLocation();
  return (
    <div>
      <HeaderSection pageName='Join Our Team'  imageUrl={HeadImg} alt="Background Image" />
      <OpenPosition />
      <Footer hasWorkItem={false}/>
      {isMobile?
      (<a href="https://api.whatsapp.com/send?phone=919909950592" className="wupicoweb">			
			<img src="\assets\Images\icons\whatico.png" alt="Mailto" /></a>):
			(<a href="https://web.whatsapp.com/send?phone=919909950592" target="_blank"className="wupicoweb">			
      <img src="\assets\Images\icons\whatico.png" alt="whatsapp" /></a>)}
    </div>
  );
};

export default Careers;
