import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import ImageSlider from './ImageSlider';
import CaseStudyLetsGetStarted from './CaseStudyLetsGetStarted';
import HeaderSection from '../../sections/HeaderSection';
import workJson from "../../Work.json";
import { workCaseStudy } from '../../interface/WorkCaseStudy';
import CustomerStories from '../../sections/CustomerStories';
import HeadImg from '../../assets/Images/common/career.jpg';
import Footer from '../../components/Footer';
import { caseStudyWorkFilter } from '../../common-functions/caseStudyWorkFilter';
import useMetaTags from '../../common-functions/useMetaTags';

const SharepointConsultingGlobalSubmit1: React.FC = () => {
    useMetaTags(
        "SharePoint Solutions | Case Study - DMS & BPM with SharePoint",
        "",
        ""
      );
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const additionalKeywords = ['sharepoint','microsoft','office','react','angular','ai','machinelearning'];
        const results = caseStudyWorkFilter(workJson.workCaseStudy,additionalKeywords,'pharma','sharepoint-consulting-pa-usa-globalsubmit-1');
        setWorks(results);
    }, []);
    const sliderImages = [
        '/assets/Images/CaseStudy/sharepoint-consulting-pa-usa-globalsubmit-1/pages-home.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-pa-usa-globalsubmit-1/gs-2.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-pa-usa-globalsubmit-1/electronic-signature.png',
    ];
    return (
        <>
            <HeaderSection pageName='Case Study'  imageUrl={HeadImg} alt="Background Image"  />
            <Box className=" whitebox contentbox" id="Project-Overview" sx={{ padding: 4 }} >
                <Container className="contentpage">
                    <Box className="casestudy_container">
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} className="compnay_detail_div">
                            <Box flex={1} className="company_logo" sx={{ textAlign: 'center', margin: 5 }}>
                                <img src='/assets/Images/work/globalsumit-big-img.jpg' alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                            </Box>

                            <Box flex={2} p={2} className="company_detail">
                                <Typography variant="h5" className='cmp_dtl_title'>GlobalSubmit</Typography>
                                <Box mt={2} className="cmpdtl_fullbox" >
                                <div className="companydetails" >    
                                     <ul><li> <b>Location:</b></li><li><span>PA, USA</span></li></ul>
                                    <ul><li> <b>Industry:</b></li><li><span>Pharma</span></li></ul>
                                    <ul><li> <b>Environment:</b></li><li><span>SharePoint 2013</span></li></ul>
                                    <ul><li> <b>Skills:</b></li><li><span>Nintex Forms, Identity Server, PowerShell Script, Amazon Web Services, Dev Ops</span></li></ul>
                                    <ul><li> <b>Project(s):</b></li><li> <a className='active'><span>DMS & BPM with SharePoint</span></a></li></ul></div>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                <Container className="innerpagecontainer contentpage prj_overview_fulldiv">
                    <Typography variant="h2" gutterBottom className='prjover-main-title'>
                        GlobalSubmit Inc. implements its Document Management System and Business Process Management on the AWS Cloud, leveraging Microsoft SharePoint and Nintex technologies
                    </Typography>
                    {/* : null} */}

                    <Box display="flex" flexDirection={{ xs: 'column', md: "row" }} >
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'column' }} className="prj_overview_div">
                            {/* About the Client */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">About the Client</Typography>
                                <Typography variant='body2' className='prjover-content'>
                                    <p>Based in Philadelphia, GlobalSubmit Inc. is committed to streamlining regulatory submissions for life sciences firms and global regulatory bodies. Since 2005, GlobalSubmit has served as the exclusive eCTD software provider for the U.S. Food &amp; Drug Administration (FDA), with the FDA relying solely on GlobalSubmit platforms to assess the technical accuracy of all electronic submissions it receives.</p>
                                </Typography>
                            </Box>
                            {/* The Challenge */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">The Challenge</Typography>
                                <Typography variant='body2' className='prjover-content' >
                                    <p>GlobalSubmit sought a cloud-based document management system within their environment, utilizing Amazon Web Services (AWS). They aimed for customized features such as PDF conversion upon Check-in (for major or minor versions), comprehensive versioning history, notification capabilities, as well as additional enhancements including one-click folder structure creation, scope-based search functionality, and an approval process with e-signature support.</p>
                                </Typography>
                            </Box>

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">Objectives</Typography>
                                <Typography variant='body2' className='prjover-content'>
                                    <p>The main objective was to establish a document management system within their cloud environment.</p>
                                    <p>Additionally, the following mail functionalities and features were to be incorporated into the custom solution:</p>
                                    <p style={{ paddingLeft: '30px' }}>- Designing an architecture for their systems and configuring it.</p>
                                    <p style={{ paddingLeft: '30px' }}>- Customizing the Login Module.</p>
                                    <p style={{ paddingLeft: '30px' }}>- Implementing a custom Layout for consistency across the root and subsites.</p>
                                    <p style={{ paddingLeft: '30px' }}>- Developing a scope-specific search feature tailored to their needs.</p>
                                    <p style={{ paddingLeft: '30px' }}>- Integrating drag-and-drop functionalities within the file management system.</p>
                                    <p style={{ paddingLeft: '30px' }}>- Enabling versioning on documents.</p>
                                    <p style={{ paddingLeft: '30px' }}>- Incorporating PDF conversion upon Check-ins.</p>
                                    <p style={{ paddingLeft: '30px' }}>- Facilitating the direct creation of CTD documents within the system.</p>
                                    <p style={{ paddingLeft: '30px' }}>- Providing a one-click functionality to replicate the folder structure.</p>
                                    <p style={{ paddingLeft: '30px' }}>- Establishing an approval process with e-signature capability.</p>
                                </Typography>
                            </Box>

                        </Box>
                        <Box display="flex" flexDirection={{ xs: 'column' }} >
                            {/* Image Slider Section */}
                            <Box className="prj_scrnshot" textAlign="center">
                                <ImageSlider imageList={sliderImages || []} />

                            </Box>

                        </Box>
                    </Box>


                    {/* Solution and Benefits */}
                    <Box className="prj_overview_fulldiv">

                        {/* The Solution */}

                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>The Solution</Typography>
                            <Typography variant='body2' className='prjover-content'>
                                <p>GlobalSubmit deployed their system on the cloud with the assistance of Amazon Web Services (AWS). An architecture was meticulously designed, encompassing all application tiers, a search server, and database servers within the SharePoint farm. Following this blueprint, a new SharePoint farm was configured, with all components tailored to fit the design.</p>
                                <p>Subsequently, a custom solution was engineered utilizing Microsoft Visual Studio and SQL Server. This comprehensive solution comprised various elements such as application pages, features, visual web parts, REST APIs, master pages, event receivers, external content types, page layouts, user controls, and branding components like CSS and jQuery. Additionally, PowerShell scripts were employed to streamline and automate the deployment process into a one-click solution.</p>
                                <p>A custom login module was developed using the Thinktecture Identity Server model architecture, seamlessly integrated with the SharePoint farm and the custom solution to handle authentication and authorization.</p>
                                <p>GlobalSubmit's requirements included a dashboard-like page displaying user-assigned tasks and recently modified documents, with consistent layout across the site and multiple subsites tailored to specific product data. Custom master pages were implemented for both the root site and product subsites, featuring branded top and left navigation. Furthermore, a drag-and-drop functionality was introduced for user convenience, along with a custom search capability for scope-specific queries.</p>
                                <p>To facilitate the creation of new product sites, a functionality was provided on the root site to generate new product subsites with identical layouts and preconfigured document libraries, calendars, and task lists.</p>
                                <p>Document libraries were enhanced with functionalities such as Check-in, Check-out, Nintex workflows, version history, and metadata features, alongside a feature enabling conversion of Microsoft Word documents to PDF format on major and minor check-ins.</p>
                                <p>Custom content types, named CTD templates, were developed to streamline the process of filling up details and approval for various forms and CTD documents, conforming to established norms and policies.</p>
                                <p>A "repeat section" functionality was implemented to replicate folder structures for advancing stages, minimizing the effort of recreating them manually.</p>
                                <p>An e-signature feature was integrated into Nintex workflows for approval processes, sending notifications to approvers and appending e-signatures to approved CTD documents. An audit log functionality was also devised for administrative oversight.</p>
                                <p>Through this comprehensive SharePoint custom solution, GlobalSubmit automated and simplified the manual, time-consuming processes involved in product creation, stage management, CTD document handling, e-signature approvals, and versioning history maintenance, leveraging Nintex workflows.</p>
                            </Typography>
                        </Box>
                        {/* Benefits */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>Benefits</Typography>
                            <Typography variant='body2' className='prjover-content'>
                                <p>A streamlined document management system with consistent visual design.</p>
                                <p>Effortless generation of CTD documents coupled with the ability to duplicate folder structures.</p>
                                <p>Swift and automated approval process featuring e-signatures.</p>
                            </Typography>
                        </Box>

                    </Box>
                </Container >
            </Box >
            <Box className="Case-Study-List"  >
                {works.length > 0 &&
                    <CustomerStories pageName='caseStudy' workList={works} />
                }
            </Box>
            <CaseStudyLetsGetStarted projectName='DMS & BPM with SharePoint' />
            {works.length > 0 ?
                <Footer hasWorkItem={false}/>:<Footer hasWorkItem={true}/>
            }
        </>
    );
};

export default SharepointConsultingGlobalSubmit1;
