import React, { useEffect, useRef, useState } from 'react';
import { Box, Card, CardMedia, Typography } from '@mui/material';

const HeroSection: React.FC = () => {

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const handleVideoLoaded = () => {
    setIsVideoLoaded(true);
  };

  return (
    <Box className="hero_landing hero" sx={{ position: 'relative', overflow: 'hidden' }}>
      {/* Background Video */}
      <Box
        id="HeroSection"
        className="video-background"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <Card
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: 0,
            overflow: "hidden",
            opacity: isVideoLoaded ? 1 : 0, // Hide video until it's loaded
            transition: "opacity 0.5s ease-in-out",
          }}
        >
          <CardMedia
            component="video"
            autoPlay
            loop
            muted
            preload="metadata"
            playsInline
            src={'/assets/Video/BrMainBanner.MP4'}
            onCanPlay={handleVideoLoaded} // Fires when the video is ready to play
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Card>
        <Box className="overlay" />
        {!isVideoLoaded && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage: "url('/assets/Images/header/mainbanner.jpg')",
              backgroundSize: "cover",
              backgroundPosition: "center",
              zIndex: 1,
            }}
          />
        )}
      </Box>
      <Box className="hero-content">
        <Typography variant="h1">
          Transform your business with <b>AI Solutions</b>
        </Typography>
        <Typography variant="body1">
          Enhance efficiency, boost productivity, and deliver actionable insights
        </Typography>
      </Box>
    </Box>
  );
};

export default HeroSection;
