import React, { useEffect, useRef, useState } from "react";
import {
    Typography,
    Box,
    Stack,
    InputLabel,
    IconButton,
    Container,
    FormControl,
    Backdrop,
    CircularProgress,
    Select,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";
import OurWorkCaseStudyCards from "../sub-sections/Work/OurWorkCaseStudyCards";
import workJson from "../../src/Work.json";
import { fetchWork } from "../services/ApiService";
import { works } from "../interface/Works";
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';

interface WorkItem {
    value: string;
    label: string;
    disabled?: boolean; // Added optional 'disabled' property
}

const WorkSection: React.FC<{isSalesforce:boolean}> = ({isSalesforce}) => {
    const [technology, setTechnology] = useState<WorkItem[]>(
        workJson.technology.map((item) => ({ ...item, disabled: false }))
    );
    const [vertical, setVertical] = useState<WorkItem[]>(
        workJson.vertacil.map((item) => ({ ...item, disabled: false }))
    );
    const [selectedTechnology, setSelectedTechnology] = useState<string>("All");
    const [selectedVertical, setSelectedVertical] = useState<string>("All");
    const [filteredTechnologies, setFilteredTechnologies] = useState<WorkItem[]>(technology);
    const [filteredVerticals, setFilteredVerticals] = useState<WorkItem[]>(vertical);
    const [visibleWorks, setVisibleWorks] = useState<works[]>([]);
    const [works, setWorks] = useState<works[]>([]);
    const [filterWorks, setFilterWorks] = useState<works[]>([]);
    const [loading, setLoading] = useState(true);
    const [itemsToShow, setItemsToShow] = useState(5);
    const [stackHovered, setStackHovered] = useState(false);

    useEffect(() => {
        document.documentElement.style.overflow = '';
        const loadWorks = async () => {
            const work = await fetchWork();
            setWorks(work);
            setFilterWorks(work);
        };
        loadWorks();
    }, []);

    useEffect(() => {
        if (works.length > 0) {
            if(isSalesforce){
                handleTechnologyChange({
                    target: { value: 'Salesforce' },
                } as SelectChangeEvent<string>);
            }
            else{
            const storedVertical = sessionStorage.getItem("Selected-Vertical");
            const storedTechnology = sessionStorage.getItem("Selected-Technology");
            if (storedVertical && storedVertical !== 'All') {
                handleVerticalChange({
                    target: { value: storedVertical },
                } as SelectChangeEvent<string>);
            } else {
                // If no stored value, set it to "All"
                sessionStorage.removeItem("Selected-Vertical");
                setSelectedVertical('All');
            }

            if (storedTechnology && storedTechnology !== 'All') {
                handleTechnologyChange({
                    target: { value: storedTechnology },
                } as SelectChangeEvent<string>);
            }
            else {
                // If no stored value, set it to "All"
                sessionStorage.removeItem("Selected-Technology")
                setSelectedTechnology('All');
            }
        }
        }
        if (works.length > 0) {
            setLoading(false);
        }
    }, [works]);

    useEffect(() => {
        setVisibleWorks(filterWorks.slice(0, itemsToShow));
    }, [filterWorks, itemsToShow]);

    useEffect(() => {
        const handleScroll = () => {
            if (
                window.scrollY > 500
            ) {
                setItemsToShow((prev) => prev + 5); // Load 5 more items when reaching the bottom of the page
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const filterData = (technology: string, vertical: string) => {
        
        const technologyArray = technology === "All" ? [] : technology.split(",");
        const filteredData = works.filter(
            (item) => {
                const isTechnologyMatch =
                    technology === "All" || technologyArray.some((tech) => item.projectTechnology === tech || item.projectTechnology.includes(tech));
                const isVerticalMatch =
                    vertical === "All" || item.projectVertical === vertical;

                return isTechnologyMatch && isVerticalMatch;
            });
        setFilterWorks(filteredData);
    };

    const handleTechnologyChange = (e: SelectChangeEvent<string>) => {
        const selectedTech = e.target.value;
        setSelectedTechnology(selectedTech);
        const technologyArray = selectedTech === "All" ? [] : selectedTech.split(",");
        if (selectedTech !== "All") {
            if (selectedVertical != "All") {
                const updatedVerticals = vertical.map((item) => ({
                    ...item,
                    disabled: item.value !== selectedVertical && item.value !== "All",
                }));
                setFilteredVerticals(updatedVerticals);
            }
            else {
                const relatedVerticals = vertical.map((item) => ({
                    ...item,
                    disabled: !works.some(
                        (work) => technologyArray.some((tech) => work.projectTechnology.includes(tech)) && work.projectVertical === item.value
                    )&& item.value !== "All",
                }));
                setFilteredVerticals(relatedVerticals);
            }

            const updatedTechnologies = technology.map((item) => ({
                ...item,
                disabled: item.value !== selectedTech && item.value !== "All",
            }));
            setFilteredTechnologies(updatedTechnologies);
        } else if (selectedTech === "All" && selectedVertical != "All") {
            const relatedTechnologies = technology.map((item) => {
                const itemValues = item.value.split(","); // Convert comma-separated string to an array
                return {
                    ...item,
                    disabled: !works.some((work) =>
                        itemValues.some((value) =>
                            work.projectVertical === selectedVertical && work.projectTechnology === value.trim()
                        )
                    ),
                };
            });
            setFilteredTechnologies(relatedTechnologies);
        } else {
            setFilteredVerticals(vertical);
            setFilteredTechnologies(technology);
        }
        filterData(selectedTech, selectedVertical);
    };

    const handleStackMouseEnter = () => {
        setStackHovered(true);
    };

    const handleStackMouseLeave = () => {
        setStackHovered(false);
    };

    const handleVerticalChange = (e: SelectChangeEvent<string>) => {
        const selectedVert = e.target.value;
        setSelectedVertical(selectedVert);
        const technologyArray = selectedTechnology === "All" ? [] : selectedTechnology.split(",");
        if (selectedVert !== "All") {
            if (selectedTechnology !== "All") {
                const updatedTechnologies = technology.map((item) => ({
                    ...item,
                    disabled: technologyArray.some((tech) => item.value !== selectedTechnology) && item.value !== "All",
                }));
                setFilteredTechnologies(updatedTechnologies);
            }
            else {
                const relatedTechnologies = technology.map((item) => {
                    const itemValues = item.value.split(","); // Convert comma-separated string to an array
                    return {
                        ...item,
                        disabled: !works.some((work) =>
                            work.projectVertical === selectedVert && itemValues.some(value => value.includes(work.projectTechnology))
                        )&& item.value !== "All",
                    };
                });

                setFilteredTechnologies(relatedTechnologies);
            }
            const updatedVerticals = vertical.map((item) => ({
                ...item,
                disabled: item.value !== selectedVert && item.value !== "All",
            }));
            setFilteredVerticals(updatedVerticals);
        } else if (selectedVert === "All" && selectedTechnology !== "All") {
            const relatedVerticals = vertical.map((item) => ({
                ...item,
                disabled: !works.some(
                    (work) => work.projectTechnology === selectedTechnology && work.projectVertical === item.value
                ),
            }));
            setFilteredVerticals(relatedVerticals);
        } else {
            setFilteredTechnologies(technology); // Reset technologies 
            setFilteredVerticals(vertical);
        }

        filterData(selectedTechnology, selectedVert);
    };

    const refreshFilter = () => {
        setSelectedTechnology("All");
        setSelectedVertical("All");
        setFilteredVerticals(vertical);
        setFilteredTechnologies(technology);
        setFilterWorks(works);
        isSalesforce=false;
        sessionStorage.removeItem("Selected-Vertical");
        sessionStorage.removeItem("Selected-Technology");
    };
    const handleTechOpen = () => {
        // Lock the scrolling on the <html> element
        
        document.documentElement.classList.add('hide-scrollbar');
        document.querySelector('.headerbox')?.classList.add('hide-scrollbar');
    };

    const handleTechClose = () => {
        // Restore the scrolling on the <html> element
        document.documentElement.classList.remove('hide-scrollbar');
        document.querySelector('.headerbox')?.classList.remove('hide-scrollbar');
    };
    const handleVeticalOpen = () => {
        // Lock the scrolling on the <html> element
        document.documentElement.classList.add('hide-scrollbar');
        document.querySelector('.headerbox')?.classList.add('hide-scrollbar');
    };

    const handleVeticalClose = () => {
        // Restore the scrolling on the <html> element
        document.documentElement.classList.remove('hide-scrollbar');
        document.querySelector('.headerbox')?.classList.remove('hide-scrollbar');
    };

    return (
        <Box
            sx={{ padding: 4, textAlign: "center" }}
            className="contentbox whitebox"

        >
            <Backdrop
                sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Container className="innerpagecontainer contentpage">
                <Box className="page_intro">                <Typography variant="h2" gutterBottom className="contentwidth">
                    Our Clients range from Fortune 500 Companies to SMEs and Governments to NGOs
                </Typography>
                    <Typography variant="body1" className="contentwidth">Binary Republik delivers technology solutions across various industries, geographies, and business verticals. Explore our diverse portfolio and request a showcase of our work below</Typography>
                </Box>
            </Container>

            <Stack className="filterWrapper">
                <Stack className="filterInput">
                    <InputLabel htmlFor="technology">Technology:</InputLabel>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <Select
                            value={selectedTechnology}
                            className="custselcontrol"
                            onChange={handleTechnologyChange}
                            onOpen={handleTechOpen}
                            onClose={handleTechClose}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 250,
                                        overflow: 'auto',
                                    }
                                }
                            }}
                        >
                            {filteredTechnologies.map((item) => (
                                <MenuItem
                                    className="custoption"
                                    key={item.value}
                                    value={item.value}
                                    disabled={item.disabled}
                                >
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>

                    </FormControl>
                </Stack>

                <Stack className="filterInput">
                    <InputLabel htmlFor="vertical">Vertical:</InputLabel>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <Select
                            value={selectedVertical}
                            className="custselcontrol"
                            onChange={handleVerticalChange}
                            onOpen={handleVeticalOpen}
                            onClose={handleVeticalClose}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 250,
                                        overflow: 'auto',
                                    }
                                }
                            }}
                            >
                            {filteredVerticals.map((item) => (
                                <MenuItem
                                    className="custoption"
                                    key={item.value}
                                    value={item.value}
                                    disabled={item.disabled}
                                >
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>

                <IconButton aria-label="refresh" onClick={refreshFilter} className="resetFilterBtn">
                    <AutorenewRoundedIcon fontSize="small" />
                </IconButton>
            </Stack>

            <Stack direction="row" spacing={2} sx={{ flexWrap: "wrap" }} className={`ourworkiteams ${stackHovered ? '' : 'stack-hovered'}`}
            >
                {
                    visibleWorks.map((work, index) => (
                        <Box
                            key={index}
                            sx={{

                                boxSizing: "border-box",
                            }}
                            className="workcard"
                            onMouseEnter={handleStackMouseEnter}
                            onMouseLeave={handleStackMouseLeave}
                        >
                            <OurWorkCaseStudyCards work={work} index={index} />
                        </Box>
                    ))
                }
            </Stack>
        </Box>
    );
};

export default WorkSection;
