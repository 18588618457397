import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { postSiteVisitor } from '../../services/ApiService';
import { SiteVisitor, VisitorLocation } from '../../interface/SiteVisitor';

const SiteVisitorDetail: React.FC = () => {
  const [location, setLocation] = useState<{ lat: number; lon: number } | null>(null);
  const [localIP, setLocalIP] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState('');
  const { pathname } = useLocation();

  // const getLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = position.coords;
  //         setLocation({ lat: latitude, lon: longitude });
  //       },
  //       () => setError('Unable to retrieve your location')
  //     );
  //   } else {
  //     setError('Geolocation is not supported by this browser');
  //   }
  // };


  function getIPs(callback: (ip: string) => void) {
    const ip_dups: { [key: string]: boolean } = {};

    const RTCPeerConnection = window.RTCPeerConnection;
    if (!RTCPeerConnection) {
      console.error("WebRTC is not supported in this browser.");
      return;
    }

    const servers = {
      iceServers: [
        { urls: "stun:stun.l.google.com:19302" },
        { urls: "stun:stun.services.mozilla.com" }
      ]
    };

    const pc = new RTCPeerConnection(servers);

    function handleCandidate(candidate: string) {
      const ip_regex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/;
      const ip_addr = ip_regex.exec(candidate)?.[1];

      if (ip_addr && ip_dups[ip_addr] === undefined) {
        ip_dups[ip_addr] = true;
        callback(ip_addr);
      }
    }

    pc.onicecandidate = (ice: any) => {
      if (ice.candidate) {
        handleCandidate(ice.candidate.candidate);
      }
    };

    pc.createDataChannel("");

    pc.createOffer()
    .then((offer: any) => {
      pc.setLocalDescription(offer);
    });

    setTimeout(() => {
      const lines = pc.localDescription?.sdp.split('\n');
      lines?.forEach((line) => {
        if (line.indexOf('a=candidate:') === 0) {
          handleCandidate(line);
        }
      });
    }, 5000);
  }

  useEffect(() => {
    // getLocation();
    getIPs((ip) => {
      setLocalIP(ip);
    });
    setPage(pathname);
  }, [pathname]);

  useEffect(() => {
    // Ensure `location` has a fallback value
    if(!page.includes('controlpanel')){
    if (!location) {
      setLocation({ lat: 0, lon: 0 });
    }
  
    const visitorLocation: VisitorLocation = {
      lat: location?.lat || 0,
      lon: location?.lon || 0,
    };
  
    if (page && localIP) {
      const siteVisitor: SiteVisitor = {
        siteVisitorID: '', // Replace with a real ID if needed
        pageName: page,
        ipAddress: localIP,
        created: new Date(),
        location: visitorLocation,
      };
  
      postSiteVisitor(siteVisitor);
    }
  }
  }, [location, localIP, page]);
  

  return<></>;
};

export default SiteVisitorDetail;