import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom'; // Correct imports
import Header from './components/Header';
import HomePage from './pages/HomePage';
import Careers from './pages/Careers';
import JoinOurTeam from './inner-page/JoinOurTeam';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import OurWork from './pages/Work';
import CaseStudy from './pages/CaseStudy';
import SharePointTrainings from './inner-page/SharePointTrainings';
import ArchiveSharePointTrainings from './inner-page/ArchiveSharePointTrainings';
import ContactUs from './pages/ContactUs';
import SharePointEvents from './pages/SharePointEvents';
import ArchiveSharePointEvents from './inner-page/ArchiveSharePointEvents';
import ScrollToTop from './components/ScrollToTop' // Import the ScrollToTop component
import ControlPanel from './pages/ControlPanel';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ServicePages from './pages/ServicePages';
import NotFoundPage from './pages/NotFoundPage';
import HomePageCopy from './pages/HomePageCopy';
import SharepointConsultingMicrosoft1 from './sub-sections/CaseStudy/sharepoint-consulting-wa-usa-microsoft-1';
import SharepointConsultingPega1 from './sub-sections/CaseStudy/sharepoint-consulting-ma-usa-pega-1';
import MachineLearningMetriq1 from './sub-sections/CaseStudy/machine-learning-ai-&-react-france-metriq-1';
import ApplicatioDevelopmentPinscriptive1 from './sub-sections/CaseStudy/application-development-ca-usa-pinscriptive-1';
import MobileAppFibronostics1 from './sub-sections/CaseStudy/mobile-app-singapore-fibronostics-1';
import SharepointConsultingPega2 from './sub-sections/CaseStudy/sharepoint-consulting-ma-usa-pega-2';
import SharepointConsultingBlythedale1 from './sub-sections/CaseStudy/sharepoint-consulting-ny-usa-blythedale-1';
import SharepointConsultingBlythedale2 from './sub-sections/CaseStudy/sharepoint-consulting-ny-usa-blythedale-2';
import SharepointConsultingGlobalSubmit1 from './sub-sections/CaseStudy/sharepoint-consulting-pa-usa-globalsubmit-1';
import SharepointConsultingUSAmeribank1 from './sub-sections/CaseStudy/sharepoint-consulting-fl-usa-usameribank-1';
import SharepointConsultingProgressiveFinancial1 from './sub-sections/CaseStudy/sharepoint-consulting-az-usa-pfs-1';
import SharepointConsultingCDMSmith1 from './sub-sections/CaseStudy/sharepoint-consulting-massachusetts-usa-cdm-smith-1';
import SharepointConsultingZSPharma1 from './sub-sections/CaseStudy/sharepoint-consulting-ca-usa-zspharma-1';
import SharepointConsultingAdvancedRadiology1 from './sub-sections/CaseStudy/sharepoint-consulting-ct-usa-advanced-radiology-1';
import SharepointConsultingWRHI1 from './sub-sections/CaseStudy/sharepoint-consulting-Johannesburg-southafrica-wrhi-1';
import SharepointConsultingFlohut1 from './sub-sections/CaseStudy/sharepoint-consulting-fl-usa-flohut-1';
import SharepointConsultingPace1 from './sub-sections/CaseStudy/sharepoint-consulting-fl-usa-pace-1';
import SharepointConsultingSJHC1 from './sub-sections/CaseStudy/sharepoint-consulting-nj-usa-sjhc-1';
import SharepointConsultingMicrosoft2 from './sub-sections/CaseStudy/sharepoint-consulting-wa-usa-microsoft-2';
import ThankYouPage from './pages/ThankYouPage';
// import AddCaseStudy from './pages/AddCaseStudy';
const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  const expiryTime = localStorage.getItem("expiryTime");
  if (!token || !expiryTime) {
    return false; // No token, user is not authenticated
  }

  if (Date.now() > parseInt(expiryTime)) {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("expiryTime");
    window.location.href = "/controlpanel/login"; 
    return false;
  }

  return true;
};

const hasRole = (allowedRoles: string[]) => {
  const userRole = localStorage.getItem("userRole");
  return userRole && allowedRoles.includes(userRole);
};

const ProtectedRoute = ({ element, allowedRoles }: { element: JSX.Element; allowedRoles: string[] }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/controlpanel/login" replace/>; // Redirect to login if not authenticated
  }
  if (!hasRole(allowedRoles)) {
    return <Navigate to="/controlpanel/login" replace />; // Redirect if user lacks the required role
  }
  return element;
};

const reloadScript = () => {
  const scriptId = "dynamic-script";
  const existingScript = document.getElementById(scriptId);

  if (existingScript) {
    existingScript.remove(); // Remove old script
  }

  const script = document.createElement("script");
  script.src = "/tawkto.js"; // Path to your public JS file
  script.id = scriptId;
  script.async = true;
  document.body.appendChild(script);
};

const App: React.FC = () => {
  const location = useLocation();
  const topRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Ensure the React component fully renders before loading the script
    const timeout = setTimeout(() => {
      reloadScript();
    }, 500); // Delay script loading by 100ms

    return () => clearTimeout(timeout); // Cleanup to avoid memory leaks
  }, [location.pathname]);
  useEffect(() => {
          if(!location.hash && location.hash != '#services' && location.hash != '#process'){
              if (!location.hash) {
                const timer = setTimeout(() => {
                    if (topRef.current) {
                        topRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
                }, 100); // Adjust the delay as needed
        
                return () => clearTimeout(timer);
            }
        }
    }, [location.pathname]);
  return (
    <div ref={topRef} id='container-main-id'>
    <ThemeProvider theme={theme}> 
    {/* <Router> */}
      <div className="containerfluid">
        <Header />
        <ScrollToTop /> 
        <main>
          <Routes>

            <Route path="*" element={<NotFoundPage />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePageCopy />} />
            <Route path="/careers/" element={<Careers />} />
            <Route path="/work/" element={<OurWork />} />
            <Route path="/thankyou/" element={<ThankYouPage/>}/>
            <Route path="/case-study/:name" element={<CaseStudy />}/>
            <Route path="/careers/:id" element={<JoinOurTeam />} />
            <Route path="/artificial-intelligence/" element={<ServicePages pageName='ArtificialIntelligence'/>} />
            <Route path="/sharepoint-consulting/" element={<ServicePages pageName='SharepointConsulting' />} />
            <Route path="/salesforce-consulting/" element={<ServicePages pageName='SalesforceConsulting' />} />
            <Route path="/azure-aws-cloud/" element={<ServicePages pageName='AzureAWSCloud' />} />
            <Route path="/microsoft-power-platform/" element={<ServicePages pageName='MicrosoftPowerPlatform' />} />
            <Route path="/mobile-solutions/" element={<ServicePages pageName='MobileSolutions' />} />
            <Route path="/custom-application-development/" element={<ServicePages pageName='CustomApplicationDevelopment' />} />
            <Route path="/content-management-systems/" element={<ServicePages pageName='ContentManagementSystems' />} />
            <Route path="/contact/" element={<ContactUs />} />
            <Route path="/SharePoint-Trainings" element={<SharePointTrainings />} />
            <Route path="/Archive-SharePoint-Trainings" element={<ArchiveSharePointTrainings />} />
            <Route path="/sharepoint-events" element={<SharePointEvents />} />
            <Route path="/Archive-SharePoint-Events" element={<ArchiveSharePointEvents />} />
            <Route
              path="/controlpanel/contactvisitors"
              element={<ProtectedRoute element={<ControlPanel tabName="ContactRequest" />} allowedRoles={["Admin", "HR"]} />}
            />
            <Route
              path="/controlpanel/careervisitors"
              element={<ProtectedRoute element={<ControlPanel tabName="JobRequest" />} allowedRoles={["Admin", "HR"]} />}
            />
            <Route
              path="/controlpanel/showcasevisitors"
              element={<ProtectedRoute element={<ControlPanel tabName="ShowcaseRequest" />} allowedRoles={["Admin", "HR"]} />}
            />
            <Route
              path="/controlpanel/sitevisitors"
              element={<ProtectedRoute element={<ControlPanel tabName="SiteVisitors" />} allowedRoles={["Admin", "HR"]} />}
            />
            <Route path="/controlpanel/login" element={<ControlPanel tabName='Login' />} />
            
            <Route path="/privacy-policy/" element={<PrivacyPolicy/>} />
            {/* case-sudy pages URL Start */}
            <Route path="/case-study/sharepoint-consulting-ma-usa-pega-1" element={<SharepointConsultingPega1 />}/>
            <Route path="/case-study/sharepoint-consulting-ma-usa-pega-2" element={<SharepointConsultingPega2 />}/>
            <Route path="/case-study/sharepoint-consulting-ny-usa-blythedale-1" element={<SharepointConsultingBlythedale1 />}/>
            <Route path="/case-study/sharepoint-consulting-ny-usa-blythedale-2" element={<SharepointConsultingBlythedale2 />}/>
            <Route path="/case-study/sharepoint-consulting-pa-usa-globalsubmit-1" element={<SharepointConsultingGlobalSubmit1 />}/>
            <Route path="/case-study/sharepoint-consulting-fl-usa-usameribank-1" element={<SharepointConsultingUSAmeribank1 />}/>
            <Route path="/case-study/sharepoint-consulting-nj-usa-sjhc-1" element={<SharepointConsultingSJHC1 />}/>
            <Route path="/case-study/sharepoint-consulting-wa-usa-microsoft-1" element={<SharepointConsultingMicrosoft1 />}/>
            <Route path="/case-study/sharepoint-consulting-ca-usa-zspharma-1" element={<SharepointConsultingZSPharma1 />}/>
            <Route path="/case-study/sharepoint-consulting-az-usa-pfs-1" element={<SharepointConsultingProgressiveFinancial1 />}/>
            <Route path="/case-study/sharepoint-consulting-Johannesburg-southafrica-wrhi-1" element={<SharepointConsultingWRHI1/>}/>
            <Route path="/case-study/sharepoint-consulting-fl-usa-flohut-1" element={<SharepointConsultingFlohut1 />}/>
            <Route path="/case-study/sharepoint-consulting-fl-usa-pace-1" element={<SharepointConsultingPace1 />}/>
            <Route path="/case-study/application-development-ca-usa-pinscriptive-1" element={<ApplicatioDevelopmentPinscriptive1 />}/>
            <Route path="/case-study/sharepoint-consulting-ct-usa-advanced-radiology-1" element={<SharepointConsultingAdvancedRadiology1 />}/>
            <Route path="/case-study/machine-learning-ai-react-france-metriq-1" element={<MachineLearningMetriq1 />}/>
            <Route path="/case-study/sharepoint-consulting-massachusetts-usa-cdm-smith-1" element={<SharepointConsultingCDMSmith1 />}/>
            <Route path="/case-study/mobile-app-singapore-fibronostics-1" element={<MobileAppFibronostics1 />}/>
            <Route path="/case-study/sharepoint-consulting-wa-usa-microsoft-2" element={<SharepointConsultingMicrosoft2 />}/>
            {/* case-sudy pages URL end */}
          </Routes>
        </main>
      </div>
    {/* </Router> */}
    </ThemeProvider>
    </div>
  );
};

export default App;
