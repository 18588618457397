export const caseStudyWorkFilter = (
    workCaseStudy: any[],
    additionalKeywords: string[],
    industry : string,
    demourl : string
    ): any[] => {
    let results = workCaseStudy.filter((item) =>
        item.Industry.toLowerCase().includes(industry)  && 
        !item.demoUrl.includes(demourl)
    );

    if (results.length < 4) {
        let extraResults = workCaseStudy.filter((item) =>
            additionalKeywords.some((keyword) =>
                item.Environment.toLowerCase().includes(keyword) ||
                item.demoUrl.toLowerCase().includes(keyword) ||
                item.Title.toLowerCase().includes(keyword) ||
                item.Industry.toLowerCase().includes(keyword)
            ) && !results.includes(item) && 
            !item.demoUrl.includes(demourl) // Avoid duplicates
        );
        extraResults = extraResults.sort(() => Math.random() - 5);
        results = [...results, ...extraResults.slice(0, 4 - results.length)];
    }

    return results;
};
