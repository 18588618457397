// src/pages/PositionChild.tsx
import { Box, Typography } from '@mui/material';
import React from 'react';

interface PositionContent {
    TrainingDate: string;
    TrainingTitle: string;
    TrainingDescription: string;
}

const TrainingCard: React.FC<PositionContent> = ({ TrainingDate, TrainingTitle, TrainingDescription }) => {
    const formatDate = (dateString: string | Date): string => {
        const date = new Date(dateString);
        const months = [
            "JAN", "FEB", "MAR", "APR", "MAY", "JUN", 
            "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
        ];
        const month = months[date.getMonth()]; // Months are 0-based
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${month} ${day}, ${year}`;
    };
    return (
        <Box sx={{ backgroundColor: 'white', py: 3, px: 3, mt: 2, boxShadow: 0 }}>
            {/* Training Date */}
            <Typography variant='body2' className="event_date">
                {formatDate(TrainingDate)}
            </Typography>

         
            {/* Training Title */}
            <Typography variant='h6' className="event_title">
                {TrainingTitle}
            </Typography>

            {/* Training Description */}
            <Typography variant='body1' className="event_content">
                {TrainingDescription}
            </Typography>
        </Box>
    );
};

export default TrainingCard;
