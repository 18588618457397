import React, { useEffect, useState } from 'react';
import { Backdrop, Box, CircularProgress, Container, Typography } from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import PositionChild from '../sub-sections/Careers/PositionChild';
import Carousel from 'react-multi-carousel';
import img1 from '../assets/Images/media/1.jpg';
import img2 from '../assets/Images/media/2.jpg';
import img3 from '../assets/Images/media/3.jpg';
import img4 from '../assets/Images/media/4.jpg';
import { Link } from 'react-router-dom';
import { fetchJobOpenings } from '../services/ApiService';
import data from '../Testimonial.json';
import { CarouselLeftArrow, CarouselRightArrow } from '../components/CustomArrows';

interface Testimonial {
    id: number;
    title: string;
    content: string[];
    author: string;
}

const OpenPosition: React.FC = () => {

    const [jobOpenings, setJobOpenings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [randomTestimonial, setRandomTestimonial] = useState<Testimonial | null>(null);
    useEffect(() => {
        // Select a random testimonial when the component mounts
        const randomIndex = Math.floor(Math.random() * data.length);
        setRandomTestimonial(data[randomIndex]);
    }, []);
    useEffect(() => {
        const loadJobOpenings = async () => {
            const openings = await fetchJobOpenings();
            setJobOpenings(openings); // Store the job openings in state
        };

        loadJobOpenings();
    }, []);
    useEffect(() => {
        // Hide the loader when the works are loaded
        if (jobOpenings.length > 0) {
            setLoading(false);
        }
    }, [jobOpenings]);

    if (!randomTestimonial) return null;
    const images = [img1, img2, img3, img4];

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <>
            <Box sx={{ padding: 4, textAlign: 'center' }} className="contentbox whitebox " width='calc(100% - 66px) !important' >
                <Container className="innerpagecontainer contentpage page_intro">
                    <Typography variant="h2" gutterBottom className='contentwidth'>
                        It’s all about people and we are no exception - What helps is that we have managed to attract the best ones
                    </Typography>
                    <Typography variant="body1" className='contentwidth'>
                        With the help of great work, excellent infrastructure, minimal hierarchies, and a ton of opportunities for growth, we provide our people with a platform to realize their true potential <br />
                        <br />Reach out to us if you aspire to work with the most relevant technologies to stay ahead of the curve
                    </Typography>
                </Container>

                <Container>
                    <Grid2 container spacing={2} sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
                        <Grid2
                            size={{ xs: 12, sm: 12, md: 8 }}
                        >
                            <Typography variant="h3" className="Title2">Open Positions</Typography>
                            <Box >
                                {
                                    jobOpenings.map((job) => (
                                        <PositionChild
                                            job={job} // Pass the entire job object
                                        />
                                    ))
                                }
                            </Box>
                        </Grid2>

                        <Grid2
                            size={{ xs: 12, sm: 12, md: 4 }}
                        >
                            <Typography variant="h3" className="Title2">Be a BRite</Typography>
                            <Box >
                                {/* Image carousel */}
                                <Carousel
                                    responsive={responsive}
                                    infinite={true}
                                    autoPlay={true}
                                    autoPlaySpeed={5000}
                                    arrows={true} // Enable arrows
                                    customLeftArrow={<CarouselLeftArrow />}
                                    customRightArrow={<CarouselRightArrow />}
                                >
                                    {images.map((imageSrc, index) => (
                                        <Box sx={{ position: 'relative', height: '300px' }} key={index}>
                                            <img src={imageSrc} alt={`Item ${index + 1}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                        </Box>
                                    ))}
                                </Carousel>

                                <Box sx={{ backgroundColor: '#ececec', py: 5, mt: 2, padding: 4 }} className="position_righ_box">
                                    <Typography variant="h5" className="Title2">
                                        {randomTestimonial.title}
                                    </Typography>
                                    {randomTestimonial.content.map((paragraph, index) => (
                                        <Typography
                                            key={index}
                                            variant="body2"
                                            sx={{ mt: 3 }}
                                            dangerouslySetInnerHTML={{ __html: paragraph }}
                                        />
                                    ))}
                                    <Typography variant="body2" sx={{ textAlign: 'right' }} className='testimonial-author'>
                                        - {randomTestimonial.author}
                                    </Typography>
                                </Box>
                                <Box>
                                    <a href='/SharePoint-Trainings' className='alltraining'>
                                        View All Trainings
                                    </a>
                                </Box>
                            </Box>
                        </Grid2>
                    </Grid2>
                </Container>
            </Box>
        </>
    );
};

export default OpenPosition;
