// src/services/apiService.ts

import { SiteVisitor } from "../interface/SiteVisitor";

//const API_URL = 'https://localhost:7162/api/';
//const API_URL = 'https://api.binaryrepublik.com/api/';
const API_URL = '/api/';

export const fetchJobOpenings = async () => {
  try {
    const response = await fetch(API_URL + 'Career/GetOpenings/0');
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch job openings:', error);
    return [];
  }
};

export const fetchJobOpeningsByUrlName = async (jobName:string) => {
  try {
    const response = await fetch(API_URL + `Career/GetOpeningsByUrlName?jobName=${jobName}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch job openings:', error);
    return [];
  }
};

export const postApplyNow = async (ApplyNowform: any) => {
  try {
    const data = new FormData();
    data.append('Name', ApplyNowform.name);
    data.append('ApplyFrom', "Apply");
    data.append('Email', ApplyNowform.email);
    data.append('Phone', ApplyNowform.contact);
    data.append('Message', ApplyNowform.message);
    data.append('Profile', ApplyNowform.profile);
    if (ApplyNowform.file) {
      data.append('File', ApplyNowform.file);
    }
    const response = await fetch(`${API_URL}Career/ApplyNow`, {
      method: 'POST',
      body: data, // Convert your data to JSON
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Failed to post Apply Now:', error);
    return [];
  }
};
export const fetchJobVisitor = async (month: number, year: number) => {
  var token=localStorage.getItem('token');
  try {
    const response = await fetch(API_URL + `Career/GetCareerRequest?month=${month}&year=${year}`,{
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include token in the request
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch Job Request:', error);
    return [];
  }
};

export const fetchContact = async (month: number, year: number) => {
  var token=localStorage.getItem('token');
  try {
    const response = await fetch(API_URL + `Contact/GetContact?month=${month}&year=${year}`,{
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include token in the request
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch Contact:', error);
    return [];
  }
};
export const postContact = async (contactform: any) => {
  try {
    const data = {
      name: contactform.name,
      email: contactform.email,
      message: contactform.message,
      companyName: contactform.company,
      phone: contactform.phone
    };
    const response = await fetch(`${API_URL}Contact/submit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data), // Convert your data to JSON
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Failed to post Contact:', error);
    return [];
  }
};
export const handleOfflineMessage = async (message: any) => {
  try {
    const data = {
      name: message.name,
      email: message.email,
      message: message.questions.find((q: any) => q.label === "Message").answer,
      company: message.questions.find((q: any) => q.label === "Company Name").answer
    };
    const response = await fetch(API_URL + 'Contact/createLeads', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data), // Convert your data to JSON
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Failed to fetch job openings:', error);
    return [];
  }
};
export const postShowCase = async (showcaseform: any, projectName: string, pageName:string) => {
  try {
    const data = {
      name: showcaseform.name,
      companyName: showcaseform.companyName,
      email: showcaseform.email,
      phone: showcaseform.phone,
      projectName: projectName,
      pageName:pageName,
      note:showcaseform.note!=''?showcaseform.note:null,
    };
    const response = await fetch(`${API_URL}Work/PostCaseStudy`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data), // Convert your data to JSON
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Failed to post Showcase:', error);
    return [];
  }
};
export const fetchShowCase = async (month: number, year: number) => {
  var token=localStorage.getItem('token');
  try {
    const response = await fetch(API_URL + `Work/GetShowCase?month=${month}&year=${year}`,{
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include token in the request
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch Showcase:', error);
    return [];
  }
};
export const exportToExcel = async (month: number, year: number, type: string) => {
  try {
    const data = {
      type,
      month,
      year
    };
    let url = '';
    if (type == 'career') {
      url = `${API_URL}Career/GetCareerExcel`;
    }
    else if (type == 'contact') {
      url = `${API_URL}Contact/GetContactExcel`;
    }
    else if (type == 'showcase') {
      url = `${API_URL}Work/GetShowcaseExcel`;
    }
    // Making a POST request to the backend API
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${token}`, // Uncomment if authentication is needed
      },
      body: JSON.stringify(data) // Sending data as JSON
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Convert the response to a blob (Excel file)
    const blob = await response.blob();

    // Create a URL for the blob and trigger a download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${type}Request_${month}_${year}.xlsx`;
    link.click();

  } catch (error) {
    console.error('Failed to export data:', error);
    alert('Failed to export data. Please try again.');
  }
};
export const fetchWork = async () => {
  try {
    const response = await fetch(API_URL + `Work/GetWorks`);
    if (!response.ok) {
      throw new Error('Get Work response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch Work:', error);
    return [];
  }
}

export const fetchArchivedEvent = async () => {
  try {
    const response = await fetch(API_URL + `Event/GetOlderEvents`);
    if (!response.ok) {
      throw new Error('GetEvents response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch Event:', error);
    return [];
  }
}
export const fetchUpcomingEvent = async () => {
  try {
    const response = await fetch(API_URL + `Event/GetUpcomingEvents`);
    if (!response.ok) {
      throw new Error('GetEvents response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch Event:', error);
    return [];
  }
}

export const fatchTrainingData = async () => {
  try {
    const response = await fetch(API_URL + `Training/GetTraining`);
    if (!response.ok) {
      throw new Error('GetTraining response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch Training:', error);
    return [];
  }
}

export const fatchUpcomingTrainingData = async () => {
  try {
    const response = await fetch(API_URL + `Training/GetUpcomingTrainings`);
    if (!response.ok) {
      throw new Error('GetUpcomingTrainings response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch GetUpcomingTrainings:', error);
    return [];
  }
}

export const fetchSiteVisitor = async () => {
  var auth=localStorage.getItem('token');
  try {
    const response = await fetch(API_URL + `Common/GetSiteVisitor`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${auth}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error('Site Visitor response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch Site Visitor:', error);
    return [];
  }
};

export const postSiteVisitor = async (siteVisitor:SiteVisitor) => {
  try {
    const data = {
      PageName: siteVisitor.pageName,
      location: siteVisitor.location.lat+" , "+siteVisitor.location.lon,
      ipAddress: siteVisitor.ipAddress,
    };
    const response = await fetch(`${API_URL}Common/PostSitevisitor`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${token}`, // Uncomment if needed
      },
      body: JSON.stringify(data), // Convert your data to JSON
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Failed to post Showcase:', error);
    return [];
  }
};

export const postLogin = async (username:string,password:string)=>{
  try {
    const data = {
      Username:username,
      Password:password
    };
    const response = await fetch(`${API_URL}Login/Login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data), // Convert your data to JSON
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    const expiryTime = Date.now() + 5 * 60 * 1000;
    localStorage.setItem("token",responseData.token);
    localStorage.setItem("userRole",responseData.userRole);
    localStorage.setItem("expiryTime", expiryTime.toString());
    return responseData.token;
  } catch (error) {
    console.error('Failed to post Showcase:', error);
    return [];
  }
};