// src/sections/Process.tsx
import React from 'react';
import { Box, Typography } from '@mui/material';
import processImage from '../assets/gif/process_new.gif'

const Process: React.FC<{topProcessRef:any}> = ({topProcessRef}) => {
  return (
    <Box id="process" ref={topProcessRef} className="outerbox">
      <Box className="OurProcess">
        <Box className="boxcontent">
          <Typography variant="h5" gutterBottom className="heading whitecolor">
            Process
          </Typography>
          <Typography variant="body1" className='content whitecolor'>
          We bring all stakeholders together to align objectives, ensuring clarity and a shared focus on achieving the desired outcome. As part of our development methodology, our teams adhere to industry best practices, maintaining the highest standards of quality and code integrity. </Typography>
        </Box>

        {/* Adding the moving image */}
        <Box className="processImage">
          <img
            src={processImage}
            alt="Process Illustration"
            style={{ maxWidth: '100%', borderRadius: '10px' }}
            className="flashing-image"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Process;
