import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import ImageSlider from './ImageSlider';
import CaseStudyLetsGetStarted from './CaseStudyLetsGetStarted';
import HeaderSection from '../../sections/HeaderSection';
import workJson from "../../Work.json";
import { workCaseStudy } from '../../interface/WorkCaseStudy';
import CustomerStories from '../../sections/CustomerStories';
import HeadImg from '../../assets/Images/common/career.jpg';
import Footer from '../../components/Footer';
import { caseStudyWorkFilter } from '../../common-functions/caseStudyWorkFilter';
import useMetaTags from '../../common-functions/useMetaTags';

const SharepointConsultingFlohut1: React.FC = () => {
    useMetaTags(
        "Binary Republik | Case Study - Collaboration Portal - BPM",
        "",
        ""
      );
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const additionalKeywords = ['sharepoint','microsoft','office','react','angular','ai','machinelearning'];
        const results = caseStudyWorkFilter(workJson.workCaseStudy,additionalKeywords,'infrastructure','sharepoint-consulting-fl-usa-flohut-1');
        setWorks(results);
    }, []);
    const sliderImages = [
        '/assets/Images/CaseStudy/sharepoint-consulting-fl-usa-flohut-1/flohut-1.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-fl-usa-flohut-1/flohut-2.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-fl-usa-flohut-1/flohut-3.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-fl-usa-flohut-1/flohut-4.png',
    ];
    return (
        <>
        <HeaderSection pageName='Case Study'  imageUrl={HeadImg} alt="Background Image"  />
            <Box className=" whitebox contentbox" id="Project-Overview" sx={{ padding: 4}} >
                <Container className="contentpage">
                    <Box className="casestudy_container">
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} className="compnay_detail_div">
                            <Box flex={1} className="company_logo" sx={{ textAlign: 'center', margin: 5 }}>
                                <img src='/assets/Images/work/ica-big.jpg' alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                            </Box>

                            <Box flex={2} p={2} className="company_detail">
                                <Typography variant="h5" className='cmp_dtl_title'>Florence & Hutcheson</Typography>
                                <Box mt={2} className="cmpdtl_fullbox" >
                                <div className="companydetails" >    <ul><li> <b>Location:</b></li><li><span>FL, USA</span></li></ul>
                                    <ul><li> <b>Industry:</b></li><li><span>Infrastructure</span></li></ul>
                                    <ul><li> <b>Environment:</b></li><li><span>SharePoint 2013</span></li></ul>
                                    <ul><li> <b>Skills:</b></li><li><span>Project Site Template, Search Service Application</span></li></ul>
                                    <ul><li> <b>Project(s):</b></li><li> <a className='active'><span>Collaboration Portal - BPM</span></a></li></ul></div>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Container>
                <Container className="innerpagecontainer contentpage prj_overview_fulldiv">
                    <Typography variant="h2" gutterBottom className='prjover-main-title'>
                        Florence and Hutcheson streamline their business processes using 
                        SharePoint 2013
                    </Typography>

                    <Box display="flex" flexDirection={{ xs: 'column', md: "row" }} >
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'column' }} className="prj_overview_div">
                            {/* About the Client */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">About the Client</Typography>
                                <Typography variant='body2' className='prjover-content'>Florence & Hutcheson, Inc., acquired by Infrastructure Corporation of America (ICA) in 2010, is an award-winning, multi-disciplinary civil and transportation engineering firm. ICA Engineering boasts over 300 professionals in 13 offices across the Southeast.</Typography>
                            </Box>
                            {/* The Challenge */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">The Challenge</Typography>
                                <Typography variant='body2' className='prjover-content' >Due to the growing demand for project sites, the IT Team found themselves repeatedly designing and developing sites for each project, a laborious process. Additionally, they had to ensure consistency in appearance while accommodating customization requests from project managers. Consequently, there arose a need for a centralized collaboration portal equipped with project management functionalities.</Typography>
                            </Box>

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">Objectives</Typography>
                                <Typography variant='body2' className='prjover-content'>The challenges to be addressed were as follows:</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>-Implementing a Collaboration Portal with custom branding.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>-Streamlining the creation of project sites as per request.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>-Developing a project management application, integrating a Helpdesk for site creation requests.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>-Implementing a custom search feature for records.</Typography>
                            </Box>

                        </Box>
                        <Box display="flex" flexDirection={{ xs: 'column' }} >
                            {/* Image Slider Section */}
                            <Box className="prj_scrnshot" textAlign="center">
                                <ImageSlider imageList={sliderImages || []} />

                            </Box>
                        </Box>
                    </Box>


                    <Box className="prj_overview_fulldiv">

                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>The Solution</Typography>
                            <Typography variant='body2' className='prjover-content'>
                                <Typography variant='body2' className='prjover-content'>A SharePoint 2013-based on-premise solution was developed to fulfill the aforementioned objectives.</Typography>
                                <Typography variant='body2' className='prjover-content'>The network architecture was meticulously crafted to facilitate the installation and configuration of SharePoint 2013. Adhering to best practices, SharePoint, along with its SQL Servers, App-Tiers, WFEs, SSRS, and other essential components, was configured with future scalability in mind. Additionally, User Profile Services and Search Services were tailored to meet the organization's search functionality requirements.</Typography>
                                <Typography variant='body2' className='prjover-content'>To maintain a uniform appearance throughout the intranet and align it with their Public Facing Site, a bespoke site template was crafted. This template encompassed all the out-of-the-box (OOTB) applications such as lists, document libraries, etc., alongside additional pages and components. Within the template, application pages were configured with visual web parts, and links were organized in both top-navigation and side-navigation menus.</Typography>
                                <Typography variant='body2' className='prjover-content'>A help desk was established for project managers to submit site creation requests along with necessary details. Upon approval, IT utilized the custom site template to create the requested site.</Typography>
                                <Typography variant='body2' className='prjover-content'>Once the site is created, the project manager would be promptly notified, enabling them to commence the management of their respective projects.</Typography>
                                <Typography variant='body2' className='prjover-content'>As a result, a fully functional intranet portal with collaboration and project management capabilities was established on SharePoint 2013.</Typography>
                            </Typography>
                        </Box>
                        {/* Benefits */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>Benefits</Typography>
                            <Typography variant='body2' className='prjover-content'>
                                <Typography variant='body2' className='prjover-content'>A comprehensive collaboration platform serving the entire organization.</Typography>
                                <Typography variant='body2' className='prjover-content'>Streamlined project management for managers through dedicated project management tools.</Typography>
                                <Typography variant='body2' className='prjover-content'>Enterprise search functionality facilitating convenient access to information across the organization.</Typography>
                            </Typography>
                        </Box>

                    </Box>
                </Container >
            </Box >
            <Box className="Case-Study-List">
            {works.length > 0 &&
                    <CustomerStories pageName='caseStudy' workList={works} />
                }
            </Box>
            <CaseStudyLetsGetStarted projectName='Microsoft' />
            {works.length > 0 ?
                <Footer hasWorkItem={false}/>:<Footer hasWorkItem={true}/>
            }
        </>
    );
};

export default SharepointConsultingFlohut1;
