import React from "react";
import exportExcelImage from "../../assets/Images/ControlPanel/Export-Excel.png"; // Adjust the path
import { exportToExcel } from "../../services/ApiService";

interface ExportButtonProps {
    month: number;
    year: number;
    type: string; // "career", "contact", or "showcase"
}
const ExportExcel: React.FC<ExportButtonProps> = ({ month, year,type }) => {
    const handleExport = async () => {
        exportToExcel(month,year,type);
      };
    
  return (
    <button onClick={handleExport} className="exportExcelButton">
      <img
        src={exportExcelImage}
        alt="Export to Excel"
        className="excelButtonImage"
      />
    </button>
  );
};

export default ExportExcel;
