import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import ImageSlider from './ImageSlider';
import CaseStudyLetsGetStarted from './CaseStudyLetsGetStarted';
import HeaderSection from '../../sections/HeaderSection';
import { Link } from 'react-router-dom';
import workJson from "../../Work.json";
import CustomerStories from '../../sections/CustomerStories';
import { workCaseStudy } from '../../interface/WorkCaseStudy';
import HeadImg from '../../assets/Images/common/career.jpg';
import Footer from '../../components/Footer';
import { caseStudyWorkFilter } from '../../common-functions/caseStudyWorkFilter';
import useMetaTags from '../../common-functions/useMetaTags';

const SharepointConsultingPega2: React.FC = () => {
    useMetaTags(
        "SharePoint Solutions | Case Study - HR Onboarding Process",
        "",
        ""
      );
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const additionalKeywords = ['sharepoint','microsoft','office','react','angular','ai','machinelearning'];
        const results = caseStudyWorkFilter(workJson.workCaseStudy,additionalKeywords,'it','sharepoint-consulting-ma-usa-pega-2');
        setWorks(results);
    }, []);
    const sliderImages = [
        '/assets/Images/CaseStudy/sharepoint-consulting-ma-usa-pega-2/pega-screenshot.jpg',
    ];
    return (
        <>
            <HeaderSection pageName='Case Study'  imageUrl={HeadImg} alt="Background Image"  />
            <Box className=" whitebox contentbox" id="Project-Overview" sx={{ padding: 4}} >
                <Container className="contentpage">
                    <Box className="casestudy_container">
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} className="compnay_detail_div">
                            <Box flex={1} className="company_logo" sx={{ textAlign: 'center', margin: 5 }}>
                                <img src='/assets/Images/work/case-study-pega.jpg' alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                            </Box>

                            <Box flex={2} p={2} className="company_detail">
                                <Typography variant="h5" className='cmp_dtl_title'>PEGASYSTEMS INC.</Typography>
                                <Box mt={2} className="cmpdtl_fullbox" >
                                <div className="companydetails" >    <ul><li> <b>Location:</b></li><li><span>MA, USA</span></li></ul>
                                    <ul><li> <b>Industry:</b></li><li><span>IT</span></li></ul>
                                    <ul><li> <b>Environment:</b></li><li><span>Office 365 - SharePoint Online</span></li></ul>
                                    <ul><li> <b>Skills:</b></li><li><span>JWPlayer, jQuery Map, JSPlumb Zoom plugin, Layer2, SQL Server</span></li></ul>
                                    <ul><li> <b>Project(s):</b></li><li>  <a className='active'><span>Career MapHR Onboarding Process</span></a> <i className='orangseperator'>|</i> <a href='/case-study/sharepoint-consulting-ma-usa-pega-1'><span>Career Map</span></a></li></ul></div>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Container>
                <Container className="innerpagecontainer contentpage prj_overview_fulldiv">
                    {/* {caseStudy?.clientSlogan != null ?  */}
                    <Typography variant="h2" gutterBottom className='prjover-main-title'>
                        PEGA Redefines HR Onboarding Process for New hires, using Microsoft SharePoint Platform
                    </Typography>
                    {/* : null} */}

                    <Box display="flex" flexDirection={{ xs: 'column', md: "row" }} >
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'column' }} className="prj_overview_div">
                            {/* About the Client */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">About the Client</Typography>
                                    <Typography variant='body2' className='prjover-content'><a href="https://www.pega.com/">PEGA Systems </a>is a Software Company based out of Cambridge, MA. They specialize in developing CRM and BPM solutions.</Typography>
                                    <Typography variant='body2' className='prjover-content'>Their applications streamline critical business operations, connect enterprises to their customers seamlessly in real-time across channels, and adapt to meet rapidly changing requirements. Pega’s Global 2000 customers include many of world’s most sophisticated and successful enterprises.</Typography>
                            </Box>
                            {/* The Challenge */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">The Challenge</Typography>
                                    <Typography variant='body2' className='prjover-content'>Their Human Resource department (HRD) conducts a training procedure of 3 months for every new joining employee and schedule an orientation for them by assigning a set of learning assignments to be executed in a stipulated time.</Typography>
                                    <Typography variant='body2' className='prjover-content'>A central repository for training material and a delivery mechanism was missing which made the entire procedure lengthy and tiresome for the managers.</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection={{ xs: 'column' }} >
                            {/* Image Slider Section */}
                            <Box className="prj_scrnshot" textAlign="center">
                                <ImageSlider imageList={sliderImages || []} />
                            </Box>
                        </Box>
                    </Box>
                    {/* Solution and Benefits */}
                    <Box className="prj_overview_fulldiv">

                        {/* The Solution */}

                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>The Solution</Typography>
                                <Typography variant='body2' className='prjover-content'>
                                    During the fact-finding sessions conducted by our consulting team, we were informed that they had an intranet built on SharePoint 2010 hosted on-premise. Thus, it was decided to leverage SharePoint to meet this specific requirement.
                                </Typography>
                                <Typography variant='body2' className='prjover-content'>
                                    A landing page was designed for new joining employees, providing them with all the information and instructions required for their orientation. This included all the training material – documents, videos, useful links, and the orientation schedule.
                                </Typography>
                                <Typography variant='body2' className='prjover-content'>This landing page was a SharePoint custom page layout with SharePoint web parts.</Typography>
                                <Typography variant='body2' className='prjover-content'>The one-click SharePoint deployment solution included:</Typography>

                                <div className="prjover-title">HR Process:</div>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>- This shows a timeline process for a new employee.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>- This displays the whole orientation schedule, from Day 1 to Month 3.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>- Any changes made on the home page would reflect globally.</Typography>

                                <div className="prjover-title">Video Playlist:</div>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>
                                    - Video titles and their respective URLs are added in a SharePoint custom list, as per the schedule.
                                </Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>
                                    - It includes videos from the video library, YouTube videos, and videos from the Brightcove cloud environment, using the JW Player tool to play the videos.
                                </Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>
                                    - This player comes with a playlist displayed below the player.
                                </Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>
                                    - Any changes made by the HR department are reflected globally.
                                </Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>
                                    - JW Player is a reusable video tool that can be configured anywhere on the site.
                                </Typography>

                                <div className="prjover-title">Displays Useful Links</div>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>
                                    - Useful links for the employees to help them access resources with a single click.
                                </Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>
                                    - Includes links to the payroll system, benefits, personal profile resources, and other useful links.
                                </Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>
                                    - The SharePoint custom list can be edited to directly reflect changes on the home page without altering the HTML.
                                </Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>
                                    - A SharePoint visual web part with a JS link was used to fetch the links from the custom list and display them with applied custom CSS.
                                </Typography>

                                <div className="prjover-title">Better Communication for Questions and Answers</div>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>
                                    - New employees had quick access to an FAQ section and the capability to email the HR department for instant resolution of concerns/queries.
                                </Typography>

                        </Box>
                        {/* Benefits */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>Benefits</Typography>
                            <Typography variant='body2' className='prjover-content'>
                                <ul>
                                    <li>- Centrally Managed, Efficient System</li>
                                    <li style={{ paddingLeft: '30px' }}>- Saves time for the employee as well as HRD</li>
                                    <li style={{ paddingLeft: '30px' }}>
                                        - Ease of access for new employees to understand the procedures
                                    </li>
                                    <li style={{ paddingLeft: '30px' }}>
                                        - Very helpful for the HR Department, as it eliminates repetitive processes for every new hire.
                                    </li>
                                    <li>- Instant resolution to queries and concerns</li>
                                    <li>- One place for all the essential links and self-service for employees</li>
                                    <li>
                                        - Change content from a central location, making it effective for all new employees globally.
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                    </Box>
                </Container >
            </Box >
              <Box id="Case-Study-List">
                    {works.length > 0 &&
                        <CustomerStories pageName='caseStudy' workList={works} />
                    }
                </Box>
            <CaseStudyLetsGetStarted projectName='Microsoft' />
            {works.length > 0 ?
                <Footer hasWorkItem={false}/>:<Footer hasWorkItem={true}/>
            }
        </>
    );
};

export default SharepointConsultingPega2;
