import React, { useState } from 'react';
import { Box, Button, LinearProgress, Modal, TextField } from '@mui/material';
import closeImage from '../../assets/Images/common/Close-Button.png';
import CheckIcon from '../../assets/Images/common/check.svg';
import { postShowCase } from '../../services/ApiService';

// Styles for the modal
const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface ContactFormModelProps {
  closeContactForm: () => void;
  projectName: string;
  pageName: string;
}

const ContactFormModel: React.FC<ContactFormModelProps> = ({ closeContactForm, projectName, pageName }) => {
  const [open, setOpen] = useState(true);
  const [status, setStatus] = useState<"submit" | "progress" | "sent" | "sentbutton">("submit");
  const [progress, setProgress] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    companyName: '',
    email: '',
    phone: '',
    note:''
  });
  const [errors, setErrors] = useState({
    name: false,
    companyName: false,
    email: false,
    phone: false,
  });

  const handleClose = () => setOpen(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validate the field on change
    if (name === 'phone') {
      // Regex allows only digits, plus, minus, and parentheses
      const phoneRegex = /^[\d\+\-\()\s]*$/;
      if (!phoneRegex.test(value) || value.length < 7 || value.length > 16) {
        setErrors((prev) => ({ ...prev, phone: true }));
      } else {
        setErrors((prev) => ({ ...prev, phone: false }));
      }
    }
    else if (name === 'name' && value.trim() === '') {
      setErrors((prev) => ({ ...prev, name: true }));
    }else if (name === 'companyName' && value.trim() === '') {
      setErrors((prev) => ({ ...prev, companyName: true }));
    } else if (name === 'email' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      setErrors((prev) => ({ ...prev, email: true }));
    }
    // else if (name === 'phone' && !/^[0-9]+$/.test(value) &&  value.length <= 16 && value.length >= 5) {
    // setErrors((prev) => ({ ...prev, phone: true }));
    // } 
    else {
      setErrors((prev) => ({ ...prev, [name]: false }));
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      // Check if phone number is empty or does not meet validation
      const phoneRegex = /^[\d\+\-\(\)\s]*$/;
      if (!phoneRegex.test(value) || value.length < 7 || value.length > 16) {
        setErrors((prev) => ({ ...prev, phone: true }));
      } else {
        setErrors((prev) => ({ ...prev, phone: false }));
      }
    }
    else if (name === 'name' && value.trim() === '') {
      setErrors((prev) => ({ ...prev, name: true }));
    } else if (name === 'companyName' && value.trim() === '') {
      setErrors((prev) => ({ ...prev, companyName: true }));
    } else if (name === 'email' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      setErrors((prev) => ({ ...prev, email: true }));
    }
    // else if (name === 'phone' && !/^[0-9]+$/.test(value)) {
    // setErrors((prev) => ({ ...prev, phone: true }));
    // }
    else {
      setErrors((prev) => ({ ...prev, [name]: false }));
    }
  };

  const validateForm = () => {
    let hasErrors = false;
    const newErrors = { name: false, companyName: false, email: false, phone: false };

    if (formData.name.trim() === '') {
      newErrors.name = true;
      hasErrors = true;
    }
    if (formData.companyName.trim() === '') {
      newErrors.companyName = true;
      hasErrors = true;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = true;
      hasErrors = true;
    }

    const phoneRegex = /^[0-9]+$/;
    if (!phoneRegex.test(formData.phone)) {
      newErrors.phone = true;
      hasErrors = true;
    }

    setErrors(newErrors);
    return !hasErrors;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setStatus("progress");
      setProgress(0);
      // Form submission logic here
      const interval = setInterval(() => {
        setProgress((oldProgress) => {
          const newProgress = Math.min(oldProgress + Math.random() * 15, 100);
          if (newProgress === 100) {
            clearInterval(interval);
            setTimeout(() => {
            }, 2000);
          }
          return newProgress;
        });
      }, 200);
      const response = await postShowCase(formData, projectName, pageName);
      if (response === 1) {
        setStatus("sent");
        setTimeout(() => {
          setStatus("sentbutton");
        }, 2000)
        setTimeout(() => {
          closeContactForm();
        }, 3000)
      }
      else {
        alert('There was an issue submitting your application. Please try again.');
        setStatus("submit");
      }
    }
  };

  return (
    <div>
      <Box sx={modalStyle} className="formstyles contactform">
        <Button
          onClick={closeContactForm}
          className='closebtn'
        >
          <img src={closeImage} />
        </Button>
        <TextField
          fullWidth
          margin="normal"
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          onBlur={handleBlur}
          error={errors.name}
          helperText={errors.name ? 'Required Field' : ''}
          className="formInput"
          onKeyDown={(e) => {
            if (!/^[A-Za-z\s]$/.test(e.key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
              e.preventDefault(); // Block numbers and special characters
            }
          }}
          inputProps={{
            pattern: "^[A-Za-z]+(?: [A-Za-z]+)*$", // Allows only letters and spaces
            title: "Only letters are allowed (no numbers or special characters)"
          }}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Company Name"
          name="companyName"
          value={formData.companyName}
          onChange={handleInputChange}
          onBlur={handleBlur}
          error={errors.companyName}
          helperText={errors.companyName ? 'Required Field' : ''}
          className="formInput"
        />
        <TextField
          fullWidth
          margin="normal"
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          onBlur={handleBlur}
          error={errors.email}
          helperText={errors.email ? (formData.email === "" ? "Required Field" : "Invalid email") : ""}
          className="formInput"
        />
        <TextField
          fullWidth
          margin="normal"
          label="Contact No"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          onBlur={handleBlur}
          error={errors.phone}
          className="formInput"
          helperText={errors.phone ? (formData.phone === "" ? "Required Field" : "Invalid Contact") : ''}
          onKeyDown={(e) => {
            if (!/[0-9]/.test(e.key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
              e.preventDefault(); // Block everything except numbers and control keys
            }
          }}
          inputProps={{
            maxLength: 16,  // Limit to 16 characters
            pattern: "[\\d\\+\\-\\(\\)]*" // Allow only numeric characters
          }}
        />
        {pageName=='work'?<></>:<TextField
          id="note"
          label="Note"
          name="note"
          variant="standard"
          fullWidth
          multiline
          rows={3}
          value={formData.note}
             className="formInput"
          onChange={handleInputChange}
          // onBlur={handleBlur}
          // onFocus={handleFocus}
          // error={!!formErrors.message}
          // helperText={formErrors.message}
        />}
        <Button fullWidth onClick={handleSubmit} className="modalQBtn">
          {status === "submit" && "SUBMIT"}
          {status === "sent" && <img src={CheckIcon} />}
          {status === "sentbutton" && "SENT"}
          {/* Centered Progress Line */}
          {status === "progress" && (
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                position: "absolute",
                top: "50%", // Centered vertically
                left: 0,
                transform: "translateY(-50%)", // Adjust to center properly
                width: "100%",
                height: 10, // Adjust thickness
                backgroundColor: "rgba(255, 0, 0, 0.3)", // Light red background
                "& .MuiLinearProgress-bar": {
                  background: "linear-gradient(90deg, #ff0000, #b30000)", // Red shades
                },
              }}
            />
          )}
        </Button>
      </Box>
    </div>
  );
};

export default ContactFormModel;
