export const serviceWorkFilter = (
    workCaseStudy: any[],
    keywords: string[],
    additionalKeywords: string[],
    ): any[] => {
    let results = workCaseStudy.filter((item) =>
        keywords.some((keyword) =>
            item.Environment.toLowerCase().includes(keyword) ||
            item.demoUrl.toLowerCase().includes(keyword) ||
            item.Title.toLowerCase().includes(keyword) ||
            item.Industry.toLowerCase().includes(keyword)
        )
    );

    if (results.length < 4) {
        const extraResults = workCaseStudy.filter((item) =>
            additionalKeywords.some((keyword) =>
                item.Environment.toLowerCase().includes(keyword) ||
                item.demoUrl.toLowerCase().includes(keyword) ||
                item.Title.toLowerCase().includes(keyword) ||
                item.Industry.toLowerCase().includes(keyword)
            ) && !results.includes(item) // Avoid duplicates
        );

        results = [...results, ...extraResults.slice(0, 4 - results.length)];
    }

    return results;
};
