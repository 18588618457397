import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import ImageSlider from './ImageSlider';
import CaseStudyLetsGetStarted from './CaseStudyLetsGetStarted';
import HeaderSection from '../../sections/HeaderSection';
import { Link } from 'react-router-dom';
import CustomerStories from '../../sections/CustomerStories';
import workJson from "../../Work.json";
import { workCaseStudy } from '../../interface/WorkCaseStudy';
import HeadImg from '../../assets/Images/common/career.jpg';
import Footer from '../../components/Footer';
import { caseStudyWorkFilter } from '../../common-functions/caseStudyWorkFilter';
import useMetaTags from '../../common-functions/useMetaTags';

const MachineLearningMetriq1: React.FC = () => {
    useMetaTags(
        "ChatGPT Next Web | Case Study - metriQ",
        "",
        ""
    );
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const additionalKeywords = ['application development', 'microsoft', 'ai', 'machinelearning'];
        const results = caseStudyWorkFilter(workJson.workCaseStudy, additionalKeywords, 'it', 'machine-learning-ai-react-france-metriq-1');
        setWorks(results);
    }, []);
    const sliderImages = [
        '/assets/Images/CaseStudy/machine-learning-ai-react-france-metriq-1/metriq1.png',
        '/assets/Images/CaseStudy/machine-learning-ai-react-france-metriq-1/metriq2.png',
        '/assets/Images/CaseStudy/machine-learning-ai-react-france-metriq-1/metriq3.png',
        '/assets/Images/CaseStudy/machine-learning-ai-react-france-metriq-1/metriq4.png',
    ];
    return (
        <>
            <HeaderSection pageName='Case Study' imageUrl={HeadImg} alt="Background Image" />
            <Box className=" whitebox contentbox" id="Project-Overview" sx={{ padding: 4 }} >
                <Container className="contentpage">
                    <Box className="casestudy_container">
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} className="compnay_detail_div">
                            <Box flex={1} className="company_logo" sx={{ textAlign: 'center', margin: 5 }}>
                                <img src='/assets/Images/work/metriq_01.png' alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                            </Box>
                            <Box flex={2} p={2} className="company_detail">
                                <Typography variant="h5" className='cmp_dtl_title'>metriQ</Typography>
                                <Box mt={2} className="cmpdtl_fullbox" >
                                    <div className="companydetails" >
                                        <ul><li> <b>Location:</b></li><li><span>France</span></li></ul>
                                        <ul><li> <b>Industry:</b></li><li><span>IT</span></li></ul>
                                        <ul><li> <b>Environment:</b></li><li><span>Application Development</span> </li></ul>
                                        <ul><li> <b>Skills:</b></li><li><span>API, React, Node.js, Next.js , ML, NLP, Open AI </span></li></ul>
                                        <ul><li> <b>Project(s):</b> </li><li><a className='active'><span>ChatGPT Next Web</span></a></li></ul></div>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                <Container className="innerpagecontainer contentpage prj_overview_fulldiv">
                    <Typography variant="h2" gutterBottom className='prjover-main-title'>
                        Revolutionizing Document Management and Chat Interactions for metriQ with Secure, Intelligent, and Multilingual AI-Powered Solutions
                    </Typography>
                    <Box display="flex" flexDirection={{ xs: 'column', md: "row" }} >
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'column' }} className="prj_overview_div">
                            {/* About the Client */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">About the Client</Typography>
                                <Typography variant='body2' className='prjover-content'>The metriQ, founded in 2017 is a IT Services and IT Consulting company based in France. metriQ specializes in designing intelligent process automations with a human-centric approach, leveraging AI and machine learning.</Typography>
                            </Box>
                            {/* The Challenge */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">The Challenge</Typography>
                                <Typography variant='body2' className='prjover-content' ><p>The client encountered several key challenges, such as inefficient document retrieval, complex user interfaces, and weak security with insufficient authentication. They also faced issues with inaccurate chatbot responses, labor-intensive manual document handling, and lack of multilingual support. Furthermore, managing fragmented chat histories and adapting to evolving needs were significant difficulties.</p></Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection={{ xs: 'column' }} >
                            {/* Image Slider Section */}
                            <Box className="prj_scrnshot" textAlign="center">
                                <ImageSlider imageList={sliderImages || []} />
                            </Box>
                        </Box>
                    </Box>
                    {/* Solution and Benefits */}
                    <Box className="prj_overview_fulldiv">
                        {/* The Solution */}

                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>The Solution</Typography>
                            <Typography variant='body2' className='prjover-content'><strong>Secure Authentication and Access Control</strong></Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}> Users authenticate through a secure login page using email and password.</Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}> Authenticated users are directed to a central home page for chat interactions.</Typography>
                            <Typography variant='body2' className='prjover-content'><strong>Intuitive Home Page Design</strong></Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}> Chat History Section:</Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '60px' }}> Users can add, view, and delete entries from their chat history.</Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '60px' }}> A secure logout option is available.</Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}> Chat Request and Response Section:</Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '60px' }}> Displays the ongoing conversation's name and allows users to view questions and responses.</Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '60px' }}> The chatbot processes queries based on selected documents for context-aware answers.</Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}> Document Section:</Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '60px' }}> Users can upload, select, and delete documents.</Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '60px' }}> Provides options for document management, including adding new documents and removing outdated ones.</Typography>
                            <Typography variant='body2' className='prjover-content'><strong>Document-Driven Chat Functionality</strong></Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}> Users must select a document before starting a chat, ensuring responses are tailored to the document's content.</Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}> The chatbot adapts its responses based on the selected document, providing accurate and contextually relevant answers.</Typography>
                            <Typography variant='body2' className='prjover-content'><strong>Advanced Chat Functionality</strong></Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}><strong>Natural Language Processing (NLP):</strong> The chatbot uses advanced NLP algorithms to understand and respond to user queries in natural language.</Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}><strong>Multilingual Support:</strong> The chatbot can comprehend and respond in multiple languages, catering to a diverse user base.</Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}><strong>Contextual Awareness:</strong> Maintains conversation context, allowing the chatbot to understand and respond coherently to follow-up questions.</Typography>
                            <Typography variant='body2' className='prjover-content'><strong>Comprehensive Chat History Management</strong></Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}> Users can create new chat sessions, review past conversations, and delete specific chat histories.</Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}> Each chat history is associated with a specific document, ensuring personalized and organized interactions.</Typography>
                            <Typography variant='body2' className='prjover-content'><strong>Robust Document Management</strong></Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}> Documents are integral to chat conversations, with support for multiple formats, including PDFs, Word documents, and text files.</Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}> Users can upload single or multiple documents in one action, with progress indicators for uploads.</Typography>
                            <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}> Deleting documents involves a confirmation step to prevent accidental loss, ensuring careful management of important documents.</Typography>

                        </Box>
                        {/* Benefits */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>Benefits</Typography>
                            <Typography variant='body2' className='prjover-content'>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}> Enhanced User Experience: Intuitive interface for easy navigation and document management.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}> Accurate Information Retrieval: Context-aware responses based on selected documents.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}> Increased Efficiency: Streamlined workflows with quick access to chat histories and document management.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}> Robust Security: Secure authentication and data protection.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}> Multilingual Support: Serves a diverse user base with multiple language options.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}> Customizable and Scalable: Adapts to changing needs and integrates new features easily.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}> Centralized Document Management: Simplified document handling and organization.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}> Ongoing Improvements: Supports future updates and enhancements</Typography>
                            </Typography>
                        </Box>

                    </Box>
                </Container >
            </Box >
            <Box id="Case-Study-List">
                {/* Title */}
                {works.length > 0 &&
                    <CustomerStories pageName='caseStudy' workList={works} />
                }
            </Box>
            {/* </Box> */}
            <CaseStudyLetsGetStarted projectName='metriQ' />
            {works.length > 0 ?
                <Footer hasWorkItem={false} /> : <Footer hasWorkItem={true} />
            }
        </>
    );
};

export default MachineLearningMetriq1;
