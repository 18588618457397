import { Box, Button, Container, Modal, Typography } from "@mui/material";
import { useState } from "react";
import ContactFormModel from "../Work/ContactFormModel";

interface project {
    projectName: string;
}
const CaseStudyLetsGetStarted: React.FC<project> = ({ projectName }) => {
    const [showContactForm, setShowContactForm] = useState(false);
    const popupOpen = () => {
        setShowContactForm(true);
    };
    const closeContactForm = () => {
        setShowContactForm(false);
    };
    return (
        <>
            <Box className=" whitebox " >
                <Container className="cs_row2">
                    <Typography variant="h5" textAlign="center" className='csrow2_title'>Let's Get Started
                    </Typography>
                    <Box className="cs_contact_container">
                        <Box className="csc-content">
                            <Typography variant="body2" textAlign="center">Backed by years of experience in enterprise-level technologies, our proven methodologies ensure exceptional service delivery, a high success rate, and a superior user experience.</Typography>
                            <Typography variant="body2" textAlign="center" style={{ marginTop: "10px" }}> Let us know what you are looking for and get a quick quote from our Team! </Typography>
                            <Box textAlign="center" mt={2}> <br></br>
                                <Button variant="contained" className='letstalk-btn' onClick={popupOpen}>Get a Quote</Button>
                            </Box>
                        </Box>
                    </Box>
                </Container></Box>

            {showContactForm &&
                <Modal
                    open={showContactForm}
                >
                    <Box>
                        <ContactFormModel closeContactForm={closeContactForm} projectName={projectName} pageName="caseStudy" />
                    </Box>
                </Modal>}
        </>
    );
}

export default CaseStudyLetsGetStarted;