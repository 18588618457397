// CustomerStories.tsx
import React, { useEffect, useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Box, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EastIcon from '@mui/icons-material/East';
import { CustomLeftArrow, CustomRightArrow, DisabledLeftArrow, DisabledRightArrow } from '../components/CustomArrows';// Adjust import path
import { Link, useNavigate } from 'react-router-dom';
import workJson from "../../src/Work.json";

interface workCaseStudy {
    Id: number;
    Image:string;
    Industry:string;
    Environment:string;
    Title:string;
    demoUrl: string;
}

interface TextTitle {
    pageName: string;
    workList:workCaseStudy[]; 
}
const CustomerStories: React.FC<TextTitle> = ({pageName,workList}) => {
    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(1);
    const [items, setItems] = useState<workCaseStudy[]>([]);
    const [titleName,setTitleName]=useState('Customer Success Stories');
    useEffect(() => {
        setTimeout(() => {
            const items = document.querySelectorAll(".react-multi-carousel-item");
            items.forEach((item) => {
                item.removeAttribute("aria-hidden");
            });
        },100);
        if(pageName == 'home'){
        
        const workRecords: workCaseStudy[] = workJson.customerStoriesArray
            .map(id => workJson.workCaseStudy.find(item => item.Id === id))
            .filter((item): item is workCaseStudy => item !== undefined); 
        setItems(workRecords);
        }
        else if (pageName == 'caseStudy' || pageName=='service' || pageName=='AI & React' || pageName=='salesforce' || pageName=='CMS'||pageName=='Application Development'||pageName=='Power Apps'||pageName=='Mobile App'||pageName=='SharePoint 2019')
        {
              setTitleName('Related Stories');
              setItems(workList);
        }
    }, []);
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
            slidesToSlide:1,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1,
            partialVisible:true,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1,
        }
    };
    const handleWork = () => {
        if(pageName=='salesforce'){
            sessionStorage.setItem("Selected-Vertical", "All");
            sessionStorage.setItem("Selected-Technology", "Salesforce");
        }
        else if(pageName=='AI & React'){
            sessionStorage.setItem("Selected-Vertical", "All");
            sessionStorage.setItem("Selected-Technology", "Machine Learning, AI & React");
        }
        else if(pageName=='CMS'){
            sessionStorage.setItem("Selected-Vertical", "All");
            sessionStorage.setItem("Selected-Technology", "CMS");
        }
        else if(pageName=='Application Development'){
            sessionStorage.setItem("Selected-Vertical", "All");
            sessionStorage.setItem("Selected-Technology", "Application Development,ASP.NET,Angular,React,Web app");
        }
        else if(pageName=='Power Apps'){
            sessionStorage.setItem("Selected-Vertical", "All");
            sessionStorage.setItem("Selected-Technology", "Power BI,Power Apps,Power Automate");
        }
        else if(pageName=='Mobile App'){
            sessionStorage.setItem("Selected-Vertical", "All");
            sessionStorage.setItem("Selected-Technology", "Mobile App");
        }
        else if(pageName=='SharePoint 2019'){
            sessionStorage.setItem("Selected-Vertical", "All");
            sessionStorage.setItem("Selected-Technology", "SharePoint 2016,MOSS 2007,SharePoint 2010,SharePoint 2013,SharePoint 2019");
        }      
        else{
            sessionStorage.setItem("Selected-Vertical", "All");
            sessionStorage.setItem("Selected-Technology", "All");
        }
      };
    const handleAfterChange = (index: number) => {
        setCurrentIndex(index);
    };
    return (
        <Box className="outerbox " id="work">
            <Box className="CustomerStories">
                <Box className="boxcontent ">
                    <Typography variant="h5" gutterBottom className="heading whitecolor">
                        {/* Customer Stories */}{titleName}
                        <a href="/work"  title="View All Stories" className="viewallcustomerstories" onClick={handleWork}>View All <KeyboardArrowRightIcon/></a>
                    </Typography>
                    <Typography variant="body1" className='content whitecolor content-responsive'>
                    Explore how we’ve helped our clients achieve success through innovation, collaboration, and exceptional service. <a href="/work" className='link' onClick={handleWork}>View All<KeyboardArrowRightIcon/></a>  </Typography>
                </Box>

                <div className="carousel-arrows">
                    {currentIndex > 0 && <DisabledLeftArrow onClick={() => setCurrentIndex(currentIndex)} />}
                    {currentIndex < 10 && <DisabledRightArrow onClick={() => setCurrentIndex(currentIndex)} />}
                </div>
                <div className="my-own-custom-container" style={{ position: 'relative' }}>
                    <Carousel
                        containerClass="carousel-container"
                        arrows={true}
                        renderButtonGroupOutside={true}
                        responsive={responsive}
                        infinite={false}
                        customLeftArrow={<CustomLeftArrow />}
                        customRightArrow={<CustomRightArrow />}
                        afterChange={handleAfterChange}
                    >
                        {items.map((item, index) => (
                            <Link key={index} to={`/case-study/${item.demoUrl.toLowerCase()}`} className="csiteam">
                                <div className="linear-bg">
                                    <div className='csImage'>
                                        <img
                                            src={item.Image}
                                            alt={`Item ${index + 1}`}
                                            loading="lazy"
                                        />
                                    </div></div>
                                <div className='csDescription'>
                                    <Typography variant="body2" className='csTitle'>
                                        {item.Title}
                                    </Typography>

                                    <Typography
                                        variant="body2"
                                        color="primary"
                                    >
                                        <span className="csreadmore">  <EastIcon /> Read More</span>
                                    </Typography>

                                </div>
                            </Link>
                        ))}
                    </Carousel>
                </div>
            </Box>
        </Box>
    );
};

export default CustomerStories;
