// src/pages/HomePage.tsx
import {  Typography } from '@mui/material';
import React from 'react';
import Grid2 from '@mui/material/Grid2';
import CustomCard from '../../components/CustomCard';
import { CardData } from '../../interface/TabData';

const ServiceCardSection: React.FC<{cardsData:CardData[], verticalTitle:string}> = ({cardsData,verticalTitle}) => {
    // Data for each card
    
    // Sample data for the cards

    return (
        <>
            <Typography variant="h2" gutterBottom className="industries_title">
                {verticalTitle}
            </Typography>
            <Grid2 container spacing={4} className="gridContainergrp">
                {/* Map through cardsData to dynamically create CustomCard components */}
                {cardsData.map((card, index) => (
                    <CustomCard
                        key={index}
                        heading={card.heading}
                        items={card.items}
                    />
                ))}
            </Grid2>
        </>
    );
};

export default ServiceCardSection;
