import React, { useEffect, useState } from 'react';
import { Box, Container, List, ListItem, ListItemText, Typography } from '@mui/material';
import awsAzure from '../assets/Images/technologies/awsazure.png';
import Azure from '../assets/Images/services/azure.png';
import aws from '../assets/Images/services/aws.png';
import CustomerStories from './CustomerStories';
import { workCaseStudy } from '../interface/WorkCaseStudy';
import workJson from "../Work.json";
import Footer from '../components/Footer';
import { serviceWorkFilter } from '../common-functions/serviceWorkFilter';
import { CardData, TabData } from '../interface/TabData';
import ServiceTabbingSection from '../sub-sections/ServiceCommonComponent/ServiceTabbingSection';
import ServiceCardSection from '../sub-sections/ServiceCommonComponent/ServiceCardSection';
import CaseStudyLetsGetStarted from '../sub-sections/CaseStudy/CaseStudyLetsGetStarted';
import useMetaTags from '../common-functions/useMetaTags';

const AzureAWSCloudSection: React.FC = () => {
    useMetaTags(
        "Azure & AWS Cloud Services | Cloud Consulting, Migration & Management",
        "Maximize efficiency with Azure & AWS Cloud solutions. We specialize in cloud strategy, deployment, and optimization, ensuring seamless scalability, security, and cost savings for your business.",
        "Azure cloud consulting, AWS cloud deployment, cloud security best practices, cloud scalability solutions, azure hybrid cloud, AWS managed services, cloud performance optimization, secure cloud solutions"
    );
    const tabData: TabData[] = [
        {
            heading: (<>Compute <br></br>Services</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" 
            src="/assets/Images/cloud/compute.png" alt="compute-services" data-wow-duration="1500ms" data-wow-delay="300ms" 
            style="display: block;">`,
            content: (<>
                <p>Our cloud compute solutions empower businesses with scalable and high-performance infrastructure, enabling seamless application deployment, virtualization, and serverless computing to enhance efficiency and reduce operational costs.</p>
                <p>We offer powerful cloud compute services, ensuring seamless scaling, cost efficiency, and reliable performance for your applications, whether using virtual machines, containers, or serverless computing.</p>
                <p>Explore how our cloud compute solutions have helped businesses optimize performance and scalability. <a href="/work" onClick={() => selectedTechnology()} className="underlineancor">Discover our case studies.</a></p>
            </>),
        },
        {
            heading: (<>Storage <br></br>Services</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" 
            src="/assets/Images/cloud/storage.png" alt="storage-services" data-wow-duration="1500ms" data-wow-delay="300ms" 
            style="display: block;">`,
            content: (<>
                <p>Our cloud storage solutions ensure secure, scalable, and highly available data storage for businesses, allowing seamless access, backup, and disaster recovery with industry-leading reliability and encryption.</p>
                <p>We provide secure, cost-effective cloud storage to manage and protect your critical business data, ensuring high availability, seamless access, and effortless scalability.</p>
                <p>Learn how our cloud storage services have enhanced data security and accessibility for enterprises. <a href="/work" onClick={() => selectedTechnology()} className="underlineancor">View our success stories.</a></p>
            </>),
        },
        {
            heading: (<>Database <br></br>Services</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" 
            src="/assets/Images/cloud/database.png" alt="database-services" data-wow-duration="1500ms" data-wow-delay="300ms" 
            style="display: block;">`,
            content: (<>
                <p>Our managed database services offer high-performance, fully scalable SQL and NoSQL databases, ensuring optimal security, automated maintenance, and seamless integration with your applications.</p>
                <p>We provide fully managed database solutions, eliminating maintenance worries while ensuring high availability, security, and performance for your business-critical applications.</p>
                <p>See how our database solutions have helped businesses streamline operations and enhance scalability. <a href="/work" onClick={() => selectedTechnology()} className="underlineancor">Read our client stories.</a></p>
            </>),
        },
        {
            heading: (<>Networking & Security <br></br>Services</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" 
            src="/assets/Images/cloud/network-security.png" alt="network-security" data-wow-duration="1500ms" data-wow-delay="300ms" 
            style="display: block;">`,
            content: (<>
                <p>We design secure and high-speed cloud networks, providing firewall protection, encrypted communication, and access controls to safeguard your business applications against cyber threats and ensure seamless connectivity.</p>
                <p>Our networking and security services protect your cloud environment with firewalls, VPNs, and encryption, ensuring secure data transfer and compliance with industry standards.</p>
                <p>Find out how our networking and security solutions have strengthened data protection for organizations. <a href="/work" onClick={() => selectedTechnology()} className="underlineancor">Explore our case studies.</a></p>
            </>),
        },
        {
            heading: (<>Identity & Access <br></br>Management (IAM)</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" 
            src="/assets/Images/cloud/iam.png" alt="identity-access-management" data-wow-duration="1500ms" data-wow-delay="300ms" 
            style="display: block;">`,
            content: (<>
                <p>We offer enterprise-grade Identity & Access Management solutions, ensuring secure authentication, role-based access control, and compliance, allowing only authorized users to access critical business resources.</p>
                <p>Our IAM services provide robust authentication, access control, and security compliance, ensuring that only authorized users and systems can access your cloud applications.</p>
                <p>Discover how our IAM solutions have helped businesses enhance security and compliance. <a href="/work" onClick={() => selectedTechnology()} className="underlineancor">Learn more through our success stories.</a></p>
            </>),
        }
    ];

    const cardsData: CardData[] = [
        {
            heading: 'Cloud-Powered Healthcare Evolution',
            items: [
                'AI-Assisted Medical Imaging',
                'Scalable Health Data Warehousing',
                'Predictive Patient Risk Analytics',
                'Cloud-Based Clinical Trial Management',
                'IoT-Enabled Remote Patient Monitoring',
                'Automated Healthcare Compliance Audits',
                'Secure Data Exchange with FHIR & HL7',
            ],
        },
        {
            heading: 'Next-Gen Financial Cloud Solutions',
            items: [
                'Intelligent Fraud Detection & Mitigation',
                'Cloud-Native High-Speed Trading Systems',
                'Blockchain-Powered Secure Transactions',
                'AI-Based Personalized Wealth Advisory',
                'Real-Time Credit Risk Assessment',
                'Automated Regulatory Compliance Monitoring',
                'Serverless Payment Processing & Reconciliation',
            ],
        },
        {
            heading: 'Retail & E-Commerce Transformation',
            items: [
                'Cloud-Based Dynamic Pricing Models',
                'AI-Driven Consumer Sentiment Analysis',
                'Serverless Checkout & Payment Automation',
                'Personalized Shopping with Recommendation Engines',
                'Real-Time Stock Level & Supply Chain Forecasting',
                'Omnichannel Customer Experience with Cloud CRM',
                'Automated Returns & Exchange Processing',
            ],
        },
        {
            heading: 'Intelligent Manufacturing & Smart Factories',
            items: [
                'AI-Powered Predictive Equipment Maintenance',
                'Cloud-Integrated Digital Twin Technology',
                'IoT-Driven Production Line Optimization',
                'Automated Defect Detection using Computer Vision',
                'Scalable Cloud-Based MES & ERP Integration',
                'Energy Consumption Analytics for Sustainability',
                'Robotics & Machine Learning for Process Automation',
            ],
        },
        {
            heading: 'AI & Data-Driven Business Intelligence',
            items: [
                'Cloud-Based Machine Learning Pipelines',
                'Automated Data Classification & Compliance',
                'Serverless Event-Driven Data Processing',
                'AI-Enabled Decision Intelligence Dashboards',
                'Multi-Cloud Data Federation & Governance',
                'Real-Time Streaming Analytics with Kafka & Kinesis',
                'Data Lakehouse for Unified Business Insights',
            ],
        },
    ];

    const selectedTechnology = () => {
        sessionStorage.setItem("Selected-Vertical", 'All');
        sessionStorage.setItem("Selected-Technology", 'All');
    }
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const keywords = ['azure', 'aws', 'cloud'];
        const additionalKeywords = ['ai', 'artificialintelligence', 'machinelearning', 'automation', 'deeplearning', 'datascience', 'technology', 'microsoft'];
        const results = serviceWorkFilter(workJson.workCaseStudy, keywords, additionalKeywords);
        setWorks(results);
    }, []);
    return (
        <>
            <Box sx={{ padding: 4, textAlign: 'center' }} className="contentbox whitebox ">
                <Container className="innerpagecontainer contentpage">
                    <Typography variant="h2" gutterBottom className='contentwidth to_build'>
                        Architecting the Future with Azure & AWS – Precision-Engineered Cloud Solutions for Ultimate Agility and Innovation</Typography>

                    <Typography variant="body1" sx={{ marginBottom: 2 }} className='contentwidth'>
                        Unleash next-gen cloud power with Azure & AWS. Our tailored solutions drive intelligent automation, unmatched scalability, and enterprise-grade security, transforming the way businesses innovate and operate </Typography>

                    <div className='servicesLogo'>
                        <ul>
                            <li><span>
                                <img
                                    src={aws}
                                    alt="sharepoint-consulting"
                                /></span></li>

                            <li><span>
                                <img
                                    src={Azure}
                                    alt="Azure"
                                /></span></li>

                        </ul>
                    </div>


                </Container>
            </Box>

            <Box className="outerbox powerbitab" sx={{ bgcolor: "#f2c811" }}  >
                <ServiceTabbingSection tabData={tabData} />
            </Box>
            <Box sx={{ padding: 4 }} className="contentbox whitebox contentpage"  >
                <Container className="innerpagecontainer contentpage" >
                    <ServiceCardSection cardsData={cardsData} verticalTitle='Industry-Focused Cloud Excellence – Transforming Key Verticals with Azure & AWS-Powered Innovation' />
                </Container>
            </Box>

            {/* <ServiceCarousel category="azureAWSCloud" /> */}
            {works.length > 0 &&
                <CustomerStories pageName='service' workList={works} />
            }
            <CaseStudyLetsGetStarted projectName='Azure AWS Service' />
            {works.length > 0 ?
                <Footer hasWorkItem={false} /> : <Footer hasWorkItem={true} />
            }
        </>
    );
};

export default AzureAWSCloudSection;