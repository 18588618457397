import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import CaseStudyLetsGetStarted from './CaseStudyLetsGetStarted';
import HeaderSection from '../../sections/HeaderSection';
import workJson from "../../Work.json";
import { workCaseStudy } from '../../interface/WorkCaseStudy';
import CustomerStories from '../../sections/CustomerStories';
import HeadImg from '../../assets/Images/common/career.jpg';
import Footer from '../../components/Footer';
import { caseStudyWorkFilter } from '../../common-functions/caseStudyWorkFilter';
import useMetaTags from '../../common-functions/useMetaTags';

const SharepointConsultingAdvancedRadiology1: React.FC = () => {
    useMetaTags(
        "SharePoint Solutions | Case Study - Adavanced Radiology",
        "",
        ""
      );
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const additionalKeywords = ['sharepoint','microsoft','office','react','angular','ai','machinelearning'];
        const results = caseStudyWorkFilter(workJson.workCaseStudy,additionalKeywords,'healthcare','sharepoint-consulting-ct-usa-advanced-radiology-1');
        setWorks(results);
    }, []);
    return (
        <>
            <HeaderSection pageName='Case Study' imageUrl={HeadImg} alt="Background Image" />
            <Box className=" whitebox contentbox" id="Project-Overview" sx={{ padding: 4 }} >
                <Container className="contentpage">
                    <Box className="casestudy_container">
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} className="compnay_detail_div">
                            <Box flex={1} className="company_logo" sx={{ textAlign: 'center', margin: 5 }}>
                                <img src='/assets/Images/work/advance-radiology.jpg' alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                            </Box>

                            <Box flex={2} p={2} className="company_detail">
                                <Typography variant="h5" className='cmp_dtl_title'>Advanced Radiology Consultants</Typography>
                                <Box mt={2} className="cmpdtl_fullbox" >
                                <div className="companydetails" >                            
                                    <ul><li> <b>Location:</b></li><li><span>CT, USA</span></li></ul>
                                    <ul><li> <b>Industry:</b></li><li><span>Healthcare</span></li></ul>
                                    <ul><li> <b>Environment:</b></li><li><span>Office 365 - SharePoint Online</span></li></ul>
                                    <ul><li> <b>Skills:</b></li><li><span>SharePoint Online, Office 365, SharePoint Framework, MS Flow, MS PowerApps, MS Forms, MS Sway, MS Yammer, MS Staff, Exchange migration to Sharepoint Online</span></li></ul>
                                    <ul><li> <b>Project(s):</b></li><li> <a className='active'><span>Advanced Radiology</span></a></li></ul></div>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Container>
                <Container className="innerpagecontainer contentpage prj_overview_fulldiv">

                    <Typography variant="h2" gutterBottom className='prjover-main-title'>
                        Transforming Internal Collaboration and Automation for Advanced Radiology Consultants with SharePoint Intranet Solutions
                    </Typography>

                    <Box display="flex" flexDirection={{ xs: 'column', md: "row" }} >
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'column' }} className="prj_overview_div">
                            {/* About the Client */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">About the Client</Typography>
                                <Typography variant='body2' className='prjover-content'>Established in 1905 at Bridgeport Hospital, Connecticut, Advanced Radiology Consultants LLC was founded by a small group of doctors aiming to streamline radiology services. Today, with over a century of experience, it stands as one of the largest independent physician-owned imaging radiology practices. Offering a wide range of services including general radiology, fluoroscopy, mammography, ultrasound, MRI, CT, nuclear medicine, DEXA, and interventional radiology, Advanced Radiology serves patients at its outpatient facilities, Bridgeport Hospital, and St. Vincent’s Medical Center. Additionally, it provides innovative 24/7 access to patient records and appointment scheduling through its patient portal.</Typography>
                            </Box>
                            {/* The Challenge */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">The Challenge</Typography>
                                <Typography variant='body2' className='prjover-content' >Advanced Radiology sought to implement an intranet utilizing SharePoint capabilities for their dynamic internal portal. The primary challenge throughout the initiative was to minimize customization and maximize the use of out-of-the-box (OOTB) features for optimal results. Additionally, they aimed to automate manual business approval processes to enhance efficiency and reduce time and costs.</Typography>
                            </Box>

                        </Box>
                        <Box display="flex" flexDirection={{ xs: 'column' }} >
                        </Box>
                    </Box>
                    {/* Solution and Benefits */}
                    <Box className="prj_overview_fulldiv">
                        {/* The Solution */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>The Solution</Typography>
                            <Typography variant='body2' className='prjover-content'>Advanced Radiology aimed to transition their intranet portal to the cloud using Microsoft Office 365. Consequently, they opted to utilize SharePoint to fulfill their needs. The process commenced with a thorough analysis of their requirements for the intranet portal, determining the appropriate web parts to be employed. Given their needs, we advised maximizing the use of modern site architecture and out-of-the-box (OOTB) web parts wherever feasible.</Typography>
                            <Typography variant='body2' className='prjover-content'>They sought to begin their day with an uplifting quote, achieved by utilizing a full-width section to display a quote image. Additionally, we integrated out-of-the-box (OOTB) News web parts to showcase the latest news for all users. With several event posts stored in documents, they desired to migrate them to SharePoint and enable commenting. Thus, we created dedicated pages allowing comments using OOTB comment functionality. These posts were then featured on the homepage using the OOTB Quick Links web part, presenting them as News Posts.</Typography>
                            <Typography variant='body2' className='prjover-content'>Regarding the weather widget, we implemented a solution enabling users to view the temperature of Bridgeport, Connecticut, in their desired area.</Typography>
                            <Typography variant='body2' className='prjover-content'>For managing Upcoming Events, we utilized Out-of-the-Box (OOTB) Events lists, organizing numerous events into categories and displaying them through separate event web parts tailored to each category.</Typography>
                            <Typography variant='body2' className='prjover-content'>To enhance user navigation, we implemented a custom modern script editor, utilizing injected JavaScript code to ensure easy access to desired headers as per their request.</Typography>
                            <Typography variant='body2' className='prjover-content'>Furthermore, we developed a custom web part for the reimbursement form, replacing a manual submission process. Previously, users physically submitted forms with attachments, which were then transferred to the accounting team. This custom form was digitalized to streamline the process. Additionally, we integrated functionality to manage fare prices and control the number of records permitted in each form.</Typography>
                            <Typography variant='body2' className='prjover-content'>For the reimbursement process, we ensured that the form submitter was automatically populated based on the logged-in user. Subsequently, the form was assigned to the respective supervisor using information from the User Profile. In cases where a user lacked a supervisor, tasks were automatically assigned back to the submitter.</Typography>
                            <Typography variant='body2' className='prjover-content'>We utilized MS Flow to orchestrate this approval process and send notification emails to managers. Upon approval, the reimbursement form was forwarded to the accounting team. In case of rejection by the manager, the form reverted to the submitter.</Typography>
                            <Typography variant='body2' className='prjover-content'>Users and supervisors could access a dashboard to view forms they created or supervised. Similarly, the accounting team had their own dashboard to monitor approved tickets.</Typography>
                        </Box>
                        {/* Benefits */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>Benefits</Typography>
                            <Typography variant='body2' className='prjover-content'>Their reimbursement approval process has been streamlined through automation.</Typography>
                            <Typography variant='body2' className='prjover-content'>They now possess a collaborative platform tailored for internal staff.</Typography>
                            <Typography variant='body2' className='prjover-content'>An efficiently organized document management system has been implemented.</Typography>
                            <Typography variant='body2' className='prjover-content'>Their interactive intranet portal enables users to stay updated on the latest events, view event photographs, and engage by posting comments.</Typography>
                        </Box>
                    </Box>
                </Container >
            </Box >
            <Box className="Case-Study-List"  >
                {works.length > 0 &&
                    <CustomerStories pageName='caseStudy' workList={works} />
                }
            </Box>
            <CaseStudyLetsGetStarted projectName='Microsoft' />
            {works.length > 0 ?
                <Footer hasWorkItem={false} /> : <Footer hasWorkItem={true} />
            }
        </>
    );
};

export default SharepointConsultingAdvancedRadiology1;
