import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import ImageSlider from './ImageSlider';
import CaseStudyLetsGetStarted from './CaseStudyLetsGetStarted';
import HeaderSection from '../../sections/HeaderSection';
import { Link } from 'react-router-dom';
import CustomerStories from '../../sections/CustomerStories';
import { workCaseStudy } from '../../interface/WorkCaseStudy';
import workJson from "../../Work.json";
import HeadImg from '../../assets/Images/common/career.jpg';
import Footer from '../../components/Footer';
import { caseStudyWorkFilter } from '../../common-functions/caseStudyWorkFilter';
import useMetaTags from '../../common-functions/useMetaTags';

const SharepointConsultingBlythedale1: React.FC = () => {
    useMetaTags(
        "SharePoint Solutions | Case Study - Business Intelligence Dashboards",
        "",
        ""
      );
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const additionalKeywords = ['sharepoint','microsoft','office','react','angular','ai','machinelearning'];
        const results = caseStudyWorkFilter(workJson.workCaseStudy,additionalKeywords,'healthcare','sharepoint-consulting-ny-usa-blythedale-1');
        setWorks(results);
    }, []);
    const sliderImages = [
        '/assets/Images/CaseStudy/sharepoint-consulting-ny-usa-blythedale-1/1.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-ny-usa-blythedale-1/2.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-ny-usa-blythedale-1/3.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-ny-usa-blythedale-1/4.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-ny-usa-blythedale-1/blythedale-5.png',
    ];
    return (
        <>
            <HeaderSection pageName='Case Study'  imageUrl={HeadImg} alt="Background Image"  />
            <Box className=" whitebox contentbox" id="Project-Overview" sx={{ padding: 4}} >
                <Container className="contentpage">
                    <Box className="casestudy_container">
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} className="compnay_detail_div">
                            <Box flex={1} className="company_logo" sx={{ textAlign: 'center', margin: 5 }}>
                                <img src='/assets/Images/work/blythedale.jpg' alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                            </Box>

                            <Box flex={2} p={2} className="company_detail">
                                <Typography variant="h5" className='cmp_dtl_title'>Blythedale Children’s Hospital</Typography>
                                <Box mt={2} className="cmpdtl_fullbox" >
                                <div className="companydetails" >       <ul><li> <b>Location:</b></li><li><span>NY, USA</span></li></ul>
                                    <ul><li> <b>Industry:</b></li><li><span>Healthcare</span></li></ul>
                                    <ul><li> <b>Environment:</b></li><li><span>SharePoint 2010</span></li></ul>
                                    <ul><li> <b>Skills:</b></li><li><span>Kendo Controls, SSRS, Visual Studio 2012, SQL Server 2012</span></li></ul>
                                    <ul><li> <b>Project(s):</b></li><li> <a className='active'><span>Business Intelligence Dashboards</span></a> <i className='orangseperator'>|</i> <a href='/case-study/sharepoint-consulting-ny-usa-blythedale-2'><span>Patient Tracking System</span></a></li></ul></div>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                <Container className="innerpagecontainer contentpage prj_overview_fulldiv">
                    {/* {caseStudy?.clientSlogan != null ?  */}
                    <Typography variant="h2" gutterBottom className='prjover-main-title'>
                        Blythedale utilizes the Microsoft SharePoint platform and Kendo UI to construct Business Intelligence Dashboards within their Intranet Portal
                    </Typography>
                    {/* : null} */}
                    <Box display="flex" flexDirection={{ xs: 'column', md: "row" }} >
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'column' }} className="prj_overview_div">
                            {/* About the Client */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">About the Client</Typography>
                                    <Typography variant='body2' className='prjover-content'>Established in 1891, Blythedale Children’s Hospital has dedicated the past 120 years to enhancing the quality of life for children.</Typography>
                                    <Typography variant='body2' className='prjover-content'>Blythedale stands as the sole independent specialty children’s hospital in New York, renowned for pioneering innovative inpatient programs. Leveraging their expertise and access to cutting-edge resources, Blythedale boasts one of the largest pediatric therapy departments in New York State. Their unwavering commitment to providing comprehensive care for medically complex children, alongside robust support for their families, is unmatched.</Typography>
                            </Box>
                            {/* The Challenge */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">The Challenge</Typography>
                                    <Typography variant='body2' className='prjover-content'>Blythedale sought to implement an intranet portal to serve its diverse departments, encompassing Medicine, Accounts, Finance, Health Information, and other administrative sectors.</Typography>
                                    <Typography variant='body2' className='prjover-content'>They sought Key Performance Indicators and visual data representation to monitor their advancement toward organizational objectives.</Typography>
                                    <Typography variant='body2' className='prjover-content'>A system for eLearning and onboarding new doctors entering the hospital.</Typography>
                                    <Typography variant='body2' className='prjover-content'>A document management system.</Typography>
                            </Box>

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">Objectives</Typography>
                                    <Typography variant='body2' className='prjover-content'><span></span>Following discussions between our consulting team and the hospital, the following key pointers were identified to develop a solution.</Typography>
                                    <Typography variant='body2' className='prjover-content'>-Managing hospital bedding necessitated the organization of both inbound and outbound patient records.</Typography>
                                    <Typography variant='body2' className='prjover-content'>-Timely nursing care according to triage was essential for attending to the children.</Typography>
                                    <Typography variant='body2' className='prjover-content'>Separately:</Typography>
                                    <Typography variant='body2' className='prjover-content'>-Doctors and nurses required a platform for ongoing training and staying abreast of new research developments.</Typography>
                                    <Typography variant='body2' className='prjover-content'>- Requests for transporting children needed to be addressed promptly and efficiently.</Typography>
                                    <Typography variant='body2' className='prjover-content'>-Clear oversight of accounts, billing, and finances was necessary for decision-makers to ensure the organization's profitability.</Typography>
                            </Box>

                        </Box>
                        <Box display="flex" flexDirection={{ xs: 'column' }} >
                            {/* Image Slider Section */}
                            <Box className="prj_scrnshot" textAlign="center">
                                <ImageSlider imageList={sliderImages || []} />

                            </Box>
                            {/* Client Quote */}
                            <Box className="prj_client_quote" textAlign="center" mt={4}>
                                <Typography variant="body2" component="blockquote" className="client_quote_w quote_rounded" >
                                    <span className="left">“</span><Typography variant='body2' className='prjover-content'><Typography variant='body2' className='prjover-content'>They offer high quality software solutions and services, their agile approach to development and resources make them valuable in a market that is changing by the minute. The team is excellent and very customer focused!</Typography></Typography><span className="right">”</span>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>


                    {/* Solution and Benefits */}
                    <Box className="prj_overview_fulldiv">

                        {/* The Solution */}

                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>The Solution</Typography>
                                <Typography variant='body2' className='prjover-content'>Blythedale established an on-premise intranet portal using the Microsoft SharePoint 2010 platform, leading to the decision to utilize SharePoint to fulfill this particular requirement.</Typography>
                                <Typography variant='body2' className='prjover-content'>Initially, an in-depth analysis of the data within their ERP system was conducted. Subsequently, this data was integrated into the SharePoint platform by establishing connectivity through Application Pages on the SQL server backend.</Typography>
                                <Typography variant='body2' className='prjover-content'>Utilizing this data, various dashboards were created using Performance Point Services with Dashboard Designer and the Kendo UI framework.</Typography>
                                <Typography variant='body2' className='prjover-content'>A Census dashboard was devised to capture patient details such as admission time, discharge time, length of stay, etc., on a daily basis. This aids the staff in monitoring patient aging and hospital occupancy effectively.</Typography>
                                <Typography variant='body2' className='prjover-content'>Similarly, a Post-Discharge Follow-Up Call dashboard was created. After a patient is discharged, follow-up calls are made to inquire about their health status and other details. This facilitates easy monitoring and accessibility of patient data.</Typography>
                                <Typography variant='body2' className='prjover-content'>Using Kendo UI, a self-learning module (SLM) was developed for doctors and nurses to stay informed about the latest research and advancements in medical science and technology.</Typography>
                                <Typography variant='body2' className='prjover-content'>Blythedale provided transport services for patient pickups and drop-offs. To monitor this aspect, a separate dashboard was developed. This allowed staff to access transport service details and track their status.</Typography>
                                <Typography variant='body2' className='prjover-content'>Additionally, a dedicated dashboard was created to facilitate nurse call follow-ups. This dashboard enables nurses to monitor calls, both attended and unattended, on a daily, weekly, and monthly basis.</Typography>
                                <Typography variant='body2' className='prjover-content'>We developed various other dashboards, including Impact Dashboards, Quality Dashboards, Census Dashboards, Vent Weaning Dashboard, Clinical Indicators Charts, Length of Stay Reports, Referral Dashboard, Vent Census, Post-Discharge Follow-Up Dashboard, and more, using Kendo UI.</Typography>
                                <Typography variant='body2' className='prjover-content'>SQL Server Reporting Services (SSRS) was configured to generate various financial and accounting reports for the finance department. With this SharePoint custom solution, Kendo UI, and SSRS, Binary Republik assisted Blythedale in implementing various dashboards to enhance staff productivity and efficiently manage their institution.</Typography>
                        </Box>
                        {/* Benefits */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>Benefits</Typography>
                                <Typography variant='body2' className='prjover-content'>Census dashboards provided the hospital management with insights for patient intake and discharge, ensuring accuracy and efficiency in patient management.</Typography>
                                <Typography variant='body2' className='prjover-content'>Nurses diligently cared for all the children in the hospital, prioritizing the maintenance of their quality of life.</Typography>
                                <Typography variant='body2' className='prjover-content'>The visual data provided doctors and staff with a clear perspective, aiding in decision-making processes.</Typography>
                                <Typography variant='body2' className='prjover-content'>The dashboard for the accounts and finance department provided a transparent overview of the available cash flow, along with other pertinent account-related information.</Typography>
                                <Typography variant='body2' className='prjover-content'>Charts and graphs facilitated the establishment of goals for improved results across all departments effortlessly.</Typography>
                            
                        </Box>

                    </Box>
                </Container >
            </Box >
                <Box id="Case-Study-List">
                    {/* Title */}
                   {works.length > 0 &&
                    <CustomerStories pageName='caseStudy' workList={works} />
                    }
                </Box>
            <CaseStudyLetsGetStarted projectName='Patient Tracking System' />
            {works.length > 0 ?
                <Footer hasWorkItem={false}/>:<Footer hasWorkItem={true}/>
            }
        </>
    );
};

export default SharepointConsultingBlythedale1;
