import React from 'react';
import { Typography, Container, Box, Link, List, ListItem, ListItemText } from '@mui/material';
import HeaderSection from '../sections/HeaderSection';
import PrivacyPolicySection from '../sections/PrivacyPolicySections';
import HeadImg from '../assets/Images/common/career.jpg';
import Footer from '../components/Footer';
import useMetaTags from '../common-functions/useMetaTags';

const PrivacyPolicy: React.FC = () => {
    useMetaTags(
        "Privacy Policy | BinaryRepublik",
        "",
        ""
      );
    return (
        <>
        <HeaderSection pageName='Privacy Policy'  imageUrl={HeadImg} alt="Background Image" />
        <PrivacyPolicySection/>
        <Footer hasWorkItem={false}/>
        </>
    );
};

export default PrivacyPolicy;
