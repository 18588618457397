import React from 'react';
import { Typography, Container, Box, Link, List, ListItem, ListItemText } from '@mui/material';

const PrivacyPolicySection: React.FC = () => {
    return (
        <Box sx={{ padding: 4}} className="contentbox whitebox">
        <Container className="innerpagecontainer contentpage pppage">
          <Typography variant="h2" gutterBottom className='contentwidth pppage_ttl'>
                    This privacy notice discloses the privacy practices for{' '}
                    <Link href="/" underline="none" sx={{ color: '#e2593a' }}>
                        https://www.binaryrepublik.com/
                    </Link>. This privacy notice applies solely to information collected by this website. It will notify you of the following:
                </Typography>
                <ul>
                       <li>What personally identifiable information is collected from you through the website, how it is used and with whom it may be shared. </li>
                       <li>
                       What choices are available to you regarding the use of your data.
                    </li>
                  <li>
                       The security procedures in place to protect the misuse of your information." 
                   </li>
                 <li>
                        How you can correct any inaccuracies in the information."
                 </li>
                </ul>
            
            <Box sx={{ marginBottom: 4 }}>
                <Typography
                    variant="h5"
                    component="h2"
                    sx={{ color: '#e2593a',  fontWeight: 'normal', marginBottom: 2 }}
                >
                    Information Collection, Use, and Sharing
                </Typography>
                <Typography sx={{ marginBottom: 2 }}>
                    We are the sole owners of the information collected on this site. We only have access to/collect
                    information that you voluntarily give us via email or another direct contact from you. We will not
                    sell or rent this information to anyone.
                </Typography>
                <Typography sx={{ marginBottom: 2 }}>
                    We will use your information to respond to you, regarding the reason you contacted us. We will not
                    share your information with any third party outside of our organization, other than as necessary to
                    fulfill your request, e.g., to ship an order.
                </Typography>
                <Typography sx={{ marginBottom: 2 }}>
                    Unless you ask us not to, we may contact you via email in the future to tell you about specials, new
                    products or services, or changes to this privacy policy.
                </Typography>
            </Box>

            <Box sx={{ marginBottom: 4 }}>
                <Typography
                    variant="h5"
                    component="h2"
                    sx={{ color: '#e2593a',  fontWeight: 'normal', marginBottom: 2 }}
                >
                    Your Access to and Control Over Information
                </Typography>
                <Typography sx={{ marginBottom: 2 }}>
                    You may opt-out of any future contacts from us at any time. You can do the following at any time by
                    contacting us via the email address or phone number given on our website:
                </Typography>
                <ul>
                    <li>
                        See what data we have about you, if any.
                    </li>
                    <li>
                        Change/correct any data we have about you.
                    </li>
                    <li>
                        Have us delete any data we have about you.
                    </li>
                    <li>
                        Express any concern you have about our use of your data.
                    </li>
                </ul>
            </Box>

            <Box sx={{ marginBottom: 4 }}>
                <Typography
                    variant="h5"
                    component="h2"
                    sx={{ color: '#e2593a', fontWeight: 'normal', marginBottom: 2 }}
                >
                    Security
                </Typography>
                <Typography sx={{ marginBottom: 2 }}>
                    We take precautions to protect your information. When you submit sensitive information via the
                    website, your information is protected both online and offline.
                </Typography>
                <Typography sx={{ marginBottom: 2 }}>
                    Wherever we collect sensitive information (such as credit card data), that information is encrypted
                    and transmitted to us in a secure way. You can verify this by looking for a lock icon in the address
                    bar and looking for "https" at the beginning of the address of the Web page.
                </Typography>
                <Typography sx={{ marginBottom: 2 }}>
                    While we use encryption to protect sensitive information transmitted online, we also protect your
                    information offline. Only employees who need the information to perform a specific job (for example,
                    billing or customer service) are granted access to personally identifiable information. The
                    computers/servers in which we store personally identifiable information are kept in a secure
                    environment.
                </Typography>
            </Box>

            <Typography className="pp_btmline">
                If you feel that we are not abiding by this privacy policy, you should contact us immediately via
                telephone at Desk: +1-678-960-8003 or email at{' '}
                <Link href="mailto:info@binaryrepublik.com" underline="none">
                    info@binaryrepublik.com
                </Link>.
            </Typography>
        </Container>
        </Box>
    );
};

export default PrivacyPolicySection;
