import React, { useCallback, useEffect, useState } from 'react';
import {
    FormControl,
    InputLabel,
    Typography,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Stack,
    TableSortLabel,
    Button,
    IconButton,
} from '@mui/material';
import { fetchSiteVisitor } from '../services/ApiService';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { RefreshRounded } from '@mui/icons-material';

interface RowData {
    siteVisitorID: number;
    pageName: string;
    ipAddress: string;
    created: Date;
    location: string;
}

const SiteVisitor: React.FC = () => {
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<RowData[]>([]);
    const [filteredData, setFilteredData] = useState<RowData[]>([]);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [sortColumn, setSortColumn] = useState<keyof RowData | null>(null);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const response = await fetchSiteVisitor(); // Assume this fetches all data
            setData(response);
            setFilteredData(response);
        } catch (error) {
            console.error('Error fetching Site Visitor data:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    const applyFilter = useCallback(() => {
        if (startDate && endDate) {
            const filtered = data.filter((row) => {
                const rowDate = new Date(row.created).setHours(0, 0, 0, 0);
                const start = startDate.toDate().setHours(0, 0, 0, 0);
                const end = endDate.toDate().setHours(0, 0, 0, 0);
                return rowDate >= start && rowDate <= end;
            });
            setFilteredData(filtered);
        } else {
            setFilteredData(data);
        }
    }, [data, startDate, endDate]);

    const handleReset = () => {
        setStartDate(null);
        setEndDate(null);
        setFilteredData(data);
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const formatDate = (date: Date): string => {
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };

    const handleSort = (column: keyof RowData) => {
        const isAsc = sortColumn === column && sortOrder === 'asc';
        setSortOrder(isAsc ? 'desc' : 'asc');
        setSortColumn(column);

        const sortedData = [...filteredData].sort((a, b) => {
            if (a[column] < b[column]) return isAsc ? 1 : -1;
            if (a[column] > b[column]) return isAsc ? -1 : 1;
            return 0;
        });
        setFilteredData(sortedData);
    };
    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, marginBottom: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack direction="row" alignItems="center">
                        <InputLabel htmlFor="StartDate">Start Date</InputLabel>
                        <FormControl variant="outlined" sx={{ m: 1, minWidth: 220 }}>
                            <DatePicker
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                            />
                        </FormControl>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                        <InputLabel htmlFor="EndDate">End Date</InputLabel>
                        <FormControl variant="outlined" sx={{ m: 1, minWidth: 220 }}>
                            <DatePicker
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                            />
                        </FormControl>
                    </Stack>
                </LocalizationProvider>

                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button variant="contained" sx={{ backgroundColor: '#df4b32', '&:hover': { backgroundColor: '#c63c2a' } }} onClick={applyFilter}>
                        Go
                    </Button>
                    <IconButton aria-label="refresh" onClick={handleReset}>
                        <RefreshRounded fontSize="small" />
                    </IconButton>
                </Box>
            </Box>

            {loading ? (
                <CircularProgress />
            ) : filteredData.length > 0 ? (
                <TableContainer component={Paper} className="gridtable">
                    <Table>
                        <TableHead>
                            <TableRow>
                                {['created', 'pageName', 'ipAddress', 'location'].map((column) => (
                                    <TableCell key={column}>
                                        <TableSortLabel
                                            active={sortColumn === column}
                                            direction={sortColumn === column ? sortOrder : 'asc'}
                                            onClick={() => handleSort(column as keyof RowData)}
                                        >
                                            {column.charAt(0).toUpperCase() + column.slice(1)}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.map((row) => (
                                <TableRow key={row.siteVisitorID}>
                                    <TableCell>{formatDate(new Date(row.created))}</TableCell>
                                    <TableCell>{row.pageName}</TableCell>
                                    <TableCell>{row.ipAddress}</TableCell>
                                    <TableCell>{row.location}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography align="center" variant="h6" color="textSecondary">
                    No Records Found
                </Typography>
            )}
        </div>
    );
};

export default SiteVisitor;