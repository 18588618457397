import React, { useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import powerplatform from '../assets/Images/services/power-platform.png';
import CustomerStories from './CustomerStories';
import { workCaseStudy } from '../interface/WorkCaseStudy';
import workJson from "../Work.json";
import Footer from '../components/Footer';
import { serviceWorkFilter } from '../common-functions/serviceWorkFilter';
import ServiceTabbingSection from '../sub-sections/ServiceCommonComponent/ServiceTabbingSection';
import ServiceCardSection from '../sub-sections/ServiceCommonComponent/ServiceCardSection';
import { CardData, TabData } from '../interface/TabData';
import CaseStudyLetsGetStarted from '../sub-sections/CaseStudy/CaseStudyLetsGetStarted';
import useMetaTags from '../common-functions/useMetaTags';

const MicrosoftPowerPlatformSection: React.FC = () => {
    useMetaTags(
        "Power Platform Services | Build, Automate & Analyze with Microsoft Power Platform",
        "Empower your business with Microsoft Power Platform! From custom Power Apps to data-driven insights with Power BI and workflow automation with Power Automate, we deliver scalable solutions for digital transformation.",
        "Microsoft Power Apps, Power Automate integration, Power BI dashboard development, low-code solutions, enterprise automation, business intelligence, Power Platform experts"
    );
    const tabData: TabData[] = [
        {
            heading: (<>Power BI</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300  disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/powerbi/powerBi.png" alt="sales-cloud" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;"> 
           `,
            content: (<>
                <p>Our Power BI solutions are intuitive and user-friendly, allowing businesses to explore data effortlessly through interactive visualizations and drill-down capabilities. We support seamless data integration from SQL databases, cloud platforms, Excel, and third-party applications, ensuring a unified view of your business performance. With a focus on security, scalability, and automation, we empower organizations with AI-driven analytics and embedded reporting to enhance efficiency.</p>
                <p>At <strong>Binary Republik</strong>, we specialize in delivering powerful and insightful Power BI solutions that transform complex data into visually stunning and interactive reports. Our expertise lies in integrating data from multiple sources, streamlining reporting processes, and developing dynamic dashboards that provide real-time insights. Whether you need customized reports or advanced data modeling, we ensure that your data works for you—helping you identify trends, uncover opportunities, and make data-driven decisions with confidence.</p>
                <p>Demo Power BI reports for various verticals following <a href="/work" onClick={() => selectedTechnology('Power BI,Power Apps,Power Automate')} className="underlineancor">this link</a>.</p>
            </>),
        },
        {
            heading: (<>Power Apps</>),
            imgSrc: `
             <img class="animation-img-class animation-delay300  disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/powerbi/PowerApps.png" alt="sales-cloud" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;">
           `,
            content: (<>
                <p>At <strong>Binary Republik</strong>, we help businesses transform their operations by leveraging the full power of Power Apps. With the growing demand for agility and efficiency, Power Apps enables organizations to create custom applications that meet unique needs without the complexity of traditional development. Our team specializes in designing and developing intuitive applications that solve real-world business challenges, automate workflows, and optimize everyday processes.</p>
                <p>We focus on building no-code/low-code solutions that allow businesses to quickly develop apps, improve productivity, and streamline operations without relying on extensive IT resources. From automating tasks and creating custom forms to developing integrated solutions that pull data from systems like SharePoint, Dynamics 365, and SQL Server, we ensure that each app aligns with your specific business goals and enhances user experience.</p>
                <p>Our solutions are flexible, scalable, and easily adaptable to suit the needs of various industries, including manufacturing, healthcare, education, and more. By harnessing the power of Power Apps, we enable businesses to not only improve internal processes but also deliver enhanced service to customers. We empower your team to create, manage, and deploy applications that drive efficiency and innovation.</p>
                <p>Demo Power Apps for various verticals following <a href="/work" onClick={() => selectedTechnology('Power BI,Power Apps,Power Automate')} className="underlineancor">this link</a>.</p>
            </>),
        },
        {
            heading: (<>Power Automate</>),
            imgSrc: `
             <img class="animation-img-class animation-delay300  disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/powerbi/powerAutomate.png" alt="sales-cloud" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;">
            `,
            content: (<>
                <p>We help businesses unlock efficiency through seamless automation with Power Automate by automating repetitive tasks, streamlining workflows, reducing manual effort, and improving productivity.</p>
                <p>Our solutions automate everything from notifications and approvals to connecting various platforms like Office 365, Microsoft Teams, and Salesforce. We focus on creating workflows that save time, reduce errors, and enable faster decision-making.</p>
                <p>With Power Automate, businesses can integrate and automate processes across systems, creating a more agile and responsive organization. We ensure each solution is tailored to your needs, enhancing both internal operations and customer experiences.</p>
                <p>Demo Power Automate workflows for various verticals following <a href="/work" onClick={() => selectedTechnology('Power BI,Power Apps,Power Automate')} className="underlineancor">this link</a>.</p>
            </>),
        },
        {
            heading: (<>Power Pages</>),
            imgSrc: `
             <img class="animation-img-class animation-delay300  disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/powerbi/PowerPage.png" alt="sales-cloud" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;">
            `,
            content: (<>
                <p>Let us help you unlock the potential of your online presence with Power Pages, a versatile low-code platform that makes web development fast, secure, and scalable, allowing businesses to quickly create professional websites fully integrated with your data to engage customers and enhance brand visibility.</p>
                <p>Unlike traditional web development, Power Pages empowers organizations to build responsive and visually appealing websites with minimal coding effort. Whether you're aiming to create a customer-facing portal, internal dashboards, or information hubs, Power Pages provides all the tools needed for rapid deployment, all while ensuring seamless connectivity with Microsoft Dataverse, SharePoint, SQL Server, and more.</p>
                <p>Our team specializes in designing tailored web solutions that meet your specific business needs. We ensure your website is user-friendly, secure, and optimized for performance, enabling you to manage and scale your business online with ease.</p>
                <p>Demo Power Pages websites for various verticals following <a href="/work" onClick={() => selectedTechnology('Power BI,Power Apps,Power Automate')} className="underlineancor">this link</a>.</p>
            </>),
        }
    ];
    const cardsData: CardData[] = [
        {
            heading: 'Healthcare Transformation',
            items: ['Automated Patient Workflows', 'AI-Driven Healthcare Insights', 'Predictive Disease Analytics', 'Intelligent Appointment Scheduling', 'Healthcare Compliance Automation', 'Remote Patient Engagement', 'Power BI for Healthcare Analytics'],
        },
        {
            heading: 'Financial Empowerment',
            items: ['Streamlined Financial Operations', 'Automated Risk & Compliance Monitoring', 'Cash Flow Optimization Strategies', 'Data-Driven Wealth Management', 'Smart Expense & Budget Control', 'Real-Time Profitability Tracking', 'AI-Driven Investment Insights'],
        },
        {
            heading: 'Retail Excellence',
            items: ['Digital Shelf Intelligence', 'Consumer Behavior Mapping', 'Retail Process Automation', 'Smart Merchandising Strategies', 'Dynamic Pricing Optimization', 'AI-Powered Purchase Trends', 'Effortless Loyalty Program Management'],
        },
        {
            heading: 'Manufacturing Efficiency',
            items: ['Low-Code Production Monitoring', 'Automated Manufacturing Workflows', 'Power BI for Factory Analytics', 'Digital Twin Process Optimization', 'Predictive Maintenance with Power Automate', 'Real-Time Supply Chain Dashboards'],
        },
    ];
    const selectedTechnology = (technology: string) => {
        sessionStorage.setItem("Selected-Vertical", 'All');
        sessionStorage.setItem("Selected-Technology", technology);
    }

    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const keywords = ['power', 'platform', 'microsoftpowerplatform'];
        const additionalKeywords = ['powerbi', 'powerautomet', 'sharepoint', 'technology', 'microsoft'];
        const results = serviceWorkFilter(workJson.workCaseStudy, keywords, additionalKeywords);
        setWorks(results);
    }, []);
    return (
        <>
            <Box sx={{ padding: 4, textAlign: 'center' }} className="contentbox whitebox "  >
                <Container className="innerpagecontainer contentpage">

                    <Typography variant="h2" gutterBottom className='contentwidth to_build'>
                        Transform Your Business with Microsoft Power Platform Solutions: Scalable, Efficient, and Tailored to Your Needs </Typography>

                    <Typography variant="body1" sx={{ marginBottom: 2 }} className='contentwidth'>
                        Unlock your business potential with Binary Republik’s Microsoft Power Platform solutions by streamlining workflows, enhancing productivity, and driving innovation for a future-ready business</Typography>
                    <div className='servicesLogo'>
                        <ul>
                            <li><span>
                                <img
                                    src={powerplatform}
                                    alt="Microsoft Power Platform"
                                /></span></li>

                        </ul>
                    </div>
                </Container>
            </Box>
            <Box className="outerbox powerbitab" sx={{ bgcolor: "#f2c811" }}  >
                <ServiceTabbingSection tabData={tabData} />
            </Box>
            <Box sx={{ padding: 4 }} className="contentbox whitebox contentpage"  >
                <Container className="innerpagecontainer contentpage" >
                    <ServiceCardSection cardsData={cardsData} verticalTitle='Power of Digital Transformation Across Verticals with Microsoft Power Platform' />
                </Container>
            </Box>
            {works.length > 0 &&
                <CustomerStories pageName='Power Apps' workList={works} />
            }
            <CaseStudyLetsGetStarted projectName='Microsoft Power Platform Service' />
            {works.length > 0 ?
                <Footer hasWorkItem={false} /> : <Footer hasWorkItem={true} />
            }
        </>
    );
};

export default MicrosoftPowerPlatformSection;
