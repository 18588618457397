import React from 'react';
import HeaderSection from '../sections/HeaderSection';
import microsoft from "../assets/Images/work/microsoft.jpg";
import { useLocation } from 'react-router-dom';
import { works } from '../interface/Works';
import HeadImg from '../assets/Images/common/career.jpg';
import useMetaTags from '../common-functions/useMetaTags';

const CaseStudy: React.FC = () => {
  const location = useLocation(); // Access the current location object
  useMetaTags(
    "Delivering SharePoint Solutions | Case Study - Binary Republik",
    "Our Clients range from Fortune 500 Companies to SMEs and Governments to NGOs. Binary Republik has provided SharePoint services to a diverse set of industries, across geographies and verticals. You can request a Showcase from our wide array of work displayed below.",
    "Case Study"
  );
    // Get the job data from the state, if available
    const work: works = location.state?.work;
    return (
      <div>
        <HeaderSection pageName='Case Study'  imageUrl={HeadImg} alt="Background Image" />
      </div>
    );
  };
  
  export default CaseStudy;