import { Card, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight'; 
import Grid2 from '@mui/material/Grid2';

interface CustomCardProps {
    heading: string;
    items: string[];
}

const CustomCard: React.FC<CustomCardProps> = ({ heading, items }) => {
    return (
        <Grid2 size={{ xs: 12, sm: 6, md: 3 }} >
            <Card
              className='productcart'
                sx={{
                    bgcolor: '#f2f2f2',       // Set default background color
                    transition: 'background-color 0.3s, color 0.3s', // Smooth transition for both bg and text color
                    '&:hover': {
                        bgcolor: '#091d23',   // Background color on hover
                        '& .MuiTypography-root, & .MuiListItemText-root': {
                            color: '#fff',    // Change text color to white on hover
                        },
                        '& .MuiListItemIcon-root': {
                            color: '#fff',    // Change icon color to white on hover
                        },
                    },
                  boxShadow:'none',   
                  borderRadius:'8px',       // Set minimum height for all cards
                    display: 'flex',
                    flexDirection: 'column',   // Ensure card content is stacked vertically
                    justifyContent: 'space-between',  // Ensure equal space distribution
                    height:"100%",
                }}
            >
                <CardContent sx={{ flexGrow: 1 }} className="chatbotprod" > {/* Make content flexible to fill card */}
                    <Typography variant="h3" className="cardtitle">
                        {heading}  {/* Display dynamic heading */}
                    </Typography>

                    <List>
                        {/* Dynamically render list items */}
                        {items.map((item, index) => (
                            <ListItem key={index} sx={{ py: 0, mb: 0 }}>
                                
                                <ListItemText primary={item} sx={{ color: '#000' }} />
                            </ListItem>
                        ))}
                    </List>
                </CardContent>
            </Card>
        </Grid2>
    );
};

export default CustomCard;
