import { Box, Button, CardContent, Container, CssBaseline, Link, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import webImage from '../assets/Images/ContactUs/World-Map-new.png';
import { postContact } from '../services/ApiService';
import '../styles/sections/InnerPages.css';
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';

const LetsTalk: React.FC = () => {
  const navigate=useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    company: false,
    message: false,
  });

  const handleFocus = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setIsDisabled(false);
    const { name, value } = e.target;
    
    const allowedChars = /^[\d\+\-\()\s]*$/;
    const nameAllowedChars=  /^[A-Za-z ]*$/;
    if (name === "phone" && allowedChars.test(value) && value.length <= 16) {
      setErrors((prev) => ({ ...prev, name: false }));
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
    else if (name === "name") {
      // Check if the name field matches the allowed characters
      if (nameAllowedChars.test(value)) {
        // If valid, set error to false
        setErrors((prev) => ({ ...prev, name: false }));
        setFormData((prev) => ({ ...prev, [name]: value }));
      } else {
        // If invalid, set error to true
        setErrors((prev) => ({ ...prev, name: true }));
      }
    }
    else if (name !== "phone") {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: !value || (name === "email" && !/\S+@\S+\.\S+/.test(value)),
    }));
  };
  const validateForm = () => {
    let hasError = false;
    const newErrors = {
      name: !formData.name,
      email: !formData.email || !/\S+@\S+\.\S+/.test(formData.email),
      company: !formData.company,
      message: !formData.message,
    };
    setErrors(newErrors);
    hasError = Object.values(newErrors).some((err) => err === true);
    return !hasError;
  };

  const handleSubmit = async () => {
    setIsDisabled(true)
    if (validateForm()) {
      try {
        const response = await postContact(formData);
        if (response === 1) {
          localStorage.setItem('username',formData.name)
          setFormData({
            name: "",
            email: "",
            phone: "",
            company: "",
            message: "",
          });
          navigate('/thankyou/');
          setIsDisabled(false);
        } else {
          setIsDisabled(false);
          alert('There was an issue submitting your application. Please try again.');
        }
      } catch (error) {
        setIsDisabled(false);
        alert('An unexpected error occurred. Please try again later.');
      } finally {
        setIsDisabled(false);
        setIsSubmitting(false);
      }
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Box sx={{ padding: 4, textAlign: 'center' }} className="contentbox whitebox w100" width='100%'>
        <Container className="innerpagecontainer contentpage">
          <Typography variant="h2" gutterBottom className='contentwidth'>
            Binary Republik is amongst the best Technology Consulting companies globally
          </Typography>
          <Typography variant="body1" gutterBottom className='contentwidth'>
            With a strong subject matter expertise and vast hands-on experience, we have been helping our clients spread across United States, Canada, United Kingdom, Norway, South Korea, Singapore, South Africa, Middle East and India
          </Typography>
          <Typography variant="body1" gutterBottom className='contentwidth'>
            Drop us a line, we are always happy to talk about Technology and will quickly get back to you
          </Typography>
          <Box sx={{ flexGrow: 1 }} className='contentwidth'>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, sm: 12, md: 8 }}>
                <Box component="form" noValidate autoComplete="off" sx={{ flex: 1, textAlign: 'left' }} className="formstyles">
                  <TextField
                    label="Name"
                    name="name"
                    fullWidth
                    margin="normal"
                    value={formData.name}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    error={!!errors.name}
                    helperText={errors.name && "Required Field"}
                    variant="standard"
                    className="formInput"
                    inputProps={{
                      pattern: "^[A-Za-z]+(?: [A-Za-z]+)*$", // Allows only letters and spaces
                      title: "Only letters are allowed (no numbers or special characters)"
                    }}
                  />

                  <TextField
                    label="Email"
                    name="email"
                    fullWidth
                    margin="normal"
                    value={formData.email}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    error={errors.email}
                    helperText={errors.email ? (formData.email === "" ? "Required Field" : "Invalid Email") : ""}
                    variant="standard"
                    className='formInput'
                  />
                  <TextField
                    label="Contact No"
                    name="phone"
                    fullWidth
                    margin="normal"
                    value={formData.phone}
                    onChange={handleInputChange}
                    inputProps={{
                      maxLength: 16,
                      pattern: "[\\d\\+\\-\\(\\)]*"
                    }}
                    variant="standard"
                    className='formInput'
                  />
                  <TextField
                    label="Company Name"
                    name="company"
                    fullWidth
                    margin="normal"
                    value={formData.company}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    error={errors.company}
                    helperText={errors.company && "Required Field"}
                    variant="standard"
                    className='formInput'
                  />
                  <TextField
                    label="Message"
                    name="message"
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    value={formData.message}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    error={errors.message}
                    helperText={errors.message && "Required Field"}
                    variant="standard"
                    className='formInput'
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className='sendbtn'
                    onClick={handleSubmit}
                    disabled={isDisabled}
                    sx={{ marginTop: 2, backgroundColor: '#E4573D', width: 'auto' }}
                    size="large"
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
              <Grid size={{ xs: 12, sm: 12, md: 4 }}>
                <Box sx={{ flex: 1, textAlign: 'left' }}>
                  <CardContent className='life_br'>
                    <Typography gutterBottom className='conuntryName'>
                      In India
                    </Typography>
                    <Typography component="div" className='address' >
                      202 Gala Mart, <br />
                      Nr Gala Swing, South Bopal, <br />
                      Ahmedabad-380 058, <br />
                      Gujarat, India<br></br>
                      <ul>
                        <li><span>Phone:</span></li> <li>M - <Link href="tel:+91 9909950592">+91 9909950592</Link><br></br><span> </span> O -  <Link href="tel:+91 (2717) 298100">+91 (2717) 298100</Link></li>
                      </ul>
                      <Link href="mailto:info@binaryrepublik.com">info@binaryrepublik.com</Link>
                    </Typography>
                    <Typography component="div" className='address' >
                      <p className='content-address-header'>SEZ Office:</p>
                      Office No. 4, 1st Floor, Incubation Centre,
                      <br />
                      Calica – IT/ITES – SEZ, Ognaj,
                      <br />
                      Ahmedabad-380 060,
                      <br />
                      Gujarat, India
                    </Typography>
                  </CardContent>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="locationWrapper">
              <Grid size={{ xs: 12, sm: 12, md: 4 }}> <CardContent className='life_br'>
                <Typography gutterBottom className='conuntryName'>
                  In the US
                </Typography>
                <Typography component="div" className='address' >
                  Atlanta:
                  <br />
                  5900 Windward Pkwy
                  <br />
                  Suite 410B
                  <br />
                  Alpharetta, GA 30005
                  <br />
                  Phone: <Link href="tel:678-960-8003">678-960-8003</Link>
                  <br />
                  <a href="mailto:info@binaryrepublik.com" className="linkMailinfoM">Email</a>
                </Typography>
              </CardContent>
              </Grid>
              <Grid size={{ xs: 12, sm: 12, md: 4 }}> <CardContent className='life_br'>
                <Typography gutterBottom className='conuntryName'>
                  In the UK
                </Typography>
                <Typography component="div" className='address' >
                  London:
                  <br />
                  146 Northumberland Rd,
                  <br />
                  Harrow, HA2 7RG
                  <br />
                  Phone: <Link href="tel:++44 2071 128165">+44 2071 128165</Link>
                  <br />
                  <a href="mailto:info@binaryrepublik.com" className="linkMailinfoM">Email</a>
                  <br />
                  <br />
                </Typography>
              </CardContent>
              </Grid>
              <Grid size={{ xs: 12, sm: 12, md: 4 }}>  <CardContent className='life_br'>
                <Typography gutterBottom className='conuntryName'>
                  In Australia
                </Typography>
                <Typography component="div" className='address' >
                  Melbourne:
                  <br />
                  KY Global PTY LTD
                  <br />
                  Level 3, 480 Collins Street,
                  <br />
                  Melbourne VIC 3000
                  <br />
                  Phone: <Link href="tel:(03) 8610 6340">(03) 8610 6340</Link>
                  <br />
                  <br />
                </Typography>
              </CardContent></Grid>
            </Grid>
          </Box>
        </Container>
        <Box className="google_map" sx={{ textAlign: 'center', marginTop: 4 }}>
          <a
            href="https://www.google.co.in/maps/place/Binary+Republik/@23.0169379,72.4703813,17z/data=!3m1!4b1!4m5!3m4!1s0x395e848ac61df2f7:0xe67bf333ebca64ef!8m2!3d23.016933!4d72.47257"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={webImage}
              alt="Google Map"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </a>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default LetsTalk;