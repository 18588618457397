// src/theme.ts (or theme.js)
import { createTheme } from '@mui/material/styles';

// Add @font-face rule to include the Ubuntu font
const theme = createTheme({
  typography: {
    fontFamily: 'ubunturegular', // Set Ubuntu as the default font
  },
});

export default theme;
