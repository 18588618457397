import React, { useEffect, useState } from "react";
import { Container, Typography, Box } from "@mui/material";
import HeaderSection from "../sections/HeaderSection";
import HeadImg from '../assets/Images/common/contact.jpg';
import Footer from "../components/Footer";

const ThankYouPage: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      setUsername(`${storedUsername}, `);
    }
  }, []);

  return (
    <>
    <HeaderSection pageName='Thank you'  imageUrl={HeadImg} alt="Background Image" />
     <Box sx={{ padding: 4, textAlign: 'center' }} className="contentbox whitebox " width='calc(100% - 66px) !important' >
                    <Container className="innerpagecontainer contentpage page_intro">
     
          <Box className="thank_msg">
            
            <Typography variant="h5" component="h1">
            {username} thank you for your information.<br></br>
              We will get back to you shortly.
            </Typography>
          </Box>
       
      </Container></Box>
      <Footer hasWorkItem={false}/>
    </>
  );
};

export default ThankYouPage;
