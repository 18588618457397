import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import OgMetaInfo from '../OgMetaInfo.json';

const useMetaTags = (title: string, description: string, keywords: string) => {
  const location = useLocation();
  // const [seoTags, setSeoTags] = useState({ path: '', ogTitle: '' ,ogDescription:'',twitterTitle:'',twitterDescription:''});
    useEffect(() => {
      document.title = title;
      const updateMetaTag = (selector: string, attr: string, value: string) => {
        let metaTag = document.querySelector(selector);
        
        if (!metaTag) {
          // Create a new meta tag if it doesn't exist
          metaTag = document.createElement("meta");
          
          // Determine if it's a "name" or "property" meta tag
          if (selector.includes('property')) {
            metaTag.setAttribute("property", attr);
          } else {
            metaTag.setAttribute("name", attr);
          }
      
          document.head.appendChild(metaTag);
        }
      
        // Set or update the meta tag's content
        metaTag.setAttribute("content", value);
      };
      updateMetaTag('meta[name="description"]', "description", description);
      updateMetaTag('meta[name="keywords"]', "keywords", keywords);

        const matchedMeta = OgMetaInfo.find(route => {
          return location.pathname === route.path;
        });
    
        if (matchedMeta) {
          updateMetaTag('meta[property="og:title"]', "og:title", matchedMeta.ogTitle);
          updateMetaTag('meta[property="og:description"]', "og:description", matchedMeta.ogDescription);
          // If a match is found, update the OG and Twitter meta tags
          //document.querySelector('meta[property="og:title"]')?.setAttribute("content", matchedMeta.ogTitle);
          //document.querySelector('meta[property="og:description"]')?.setAttribute("content", matchedMeta.ogDescription);
          updateMetaTag('meta[name="twitter:title"]', "twitter:title", matchedMeta.twitterTitle);
          updateMetaTag('meta[name="twitter:description"]', "twitter:description", matchedMeta.twitterDescription);
          // document.querySelector('meta[name="twitter:title"]')?.setAttribute("content", matchedMeta.twitterTitle);
          // document.querySelector('meta[name="twitter:description"]')?.setAttribute("content", matchedMeta.twitterDescription);
        }
    }, [title, description, keywords]);
    // useEffect(() => {
    //   // Find the SEO data based on the current path
    //   const pageSeo = OgMetaInfo.find((page) => page.path === location.pathname);
    //   if (pageSeo) {
    //     setSeoTags(pageSeo);
    //   }
    // }, [location.pathname]); 

    // return seoTags;
  };
  
  export default useMetaTags;

  