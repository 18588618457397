import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import ImageSlider from './ImageSlider';
import CaseStudyLetsGetStarted from './CaseStudyLetsGetStarted';
import HeaderSection from '../../sections/HeaderSection';
import workJson from "../../Work.json";
import { workCaseStudy } from '../../interface/WorkCaseStudy';
import CustomerStories from '../../sections/CustomerStories';
import HeadImg from '../../assets/Images/common/career.jpg';
import Footer from '../../components/Footer';
import { caseStudyWorkFilter } from '../../common-functions/caseStudyWorkFilter';
import useMetaTags from '../../common-functions/useMetaTags';

const SharepointConsultingPace1: React.FC = () => {
    useMetaTags(
        "Binary Republik | Case Study - Intranet Portal - PACE",
        "",
        ""
      );
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const additionalKeywords = ['sharepoint','microsoft','office','react','angular','ai','machinelearning'];
        const results = caseStudyWorkFilter(workJson.workCaseStudy,additionalKeywords,'ngo','sharepoint-consulting-fl-usa-pace-1');
        setWorks(results);
    }, []);
    const sliderImages = [
        '/assets/Images/CaseStudy/sharepoint-consulting-fl-usa-pace-1/pace1.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-fl-usa-pace-1/pace3.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-fl-usa-pace-1/pace4.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-fl-usa-pace-1/pace6.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-fl-usa-pace-1/pace7.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-fl-usa-pace-1/pace8.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-fl-usa-pace-1/pace9.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-fl-usa-pace-1/pace10.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-fl-usa-pace-1/pace11.png',
    ];
    return (
        <>
        <HeaderSection pageName='Case Study'  imageUrl={HeadImg} alt="Background Image"  />
            <Box className=" whitebox contentbox" id="Project-Overview" sx={{ padding: 4}} >
                <Container className="contentpage">
                    <Box className="casestudy_container">
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} className="compnay_detail_div">
                            <Box flex={1} className="company_logo" sx={{ textAlign: 'center', margin: 5 }}>
                                <img src='/assets/Images/work/pace.jpg' alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                            </Box>

                            <Box flex={2} p={2} className="company_detail">
                                <Typography variant="h5" className='cmp_dtl_title'>PACE Center for Girls</Typography>
                                <Box mt={2} className="cmpdtl_fullbox" >
                                <div className="companydetails" >   
                                    <ul><li> <b>Location:</b></li><li><span>FL, USA</span></li></ul>
                                    <ul><li> <b>Industry:</b></li><li><span>NGO</span></li></ul>
                                    <ul><li> <b>Environment:</b></li><li><span>Office 365 - SharePoint Online</span></li></ul>
                                    <ul><li> <b>Skills:</b></li><li><span>jQuery, JSOM, Rest API, Yahoo Weather API, Sandbox Solution, SharePoint Online </span></li></ul>
                                    <ul><li> <b>Project(s):</b></li><li> <a className='active'><span>Intranet Portal</span></a></li></ul></div>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Container>
                <Container className="innerpagecontainer contentpage prj_overview_fulldiv">
                    <Typography variant="h2" gutterBottom className='prjover-main-title'>
                        Intranet Portal Constructed on SharePoint Online – Office 365 for PACE 
                        Center for Girls
                    </Typography>

                    <Box display="flex" flexDirection={{ xs: 'column', md: "row" }} >
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'column' }} className="prj_overview_div">
                            {/* About the Client */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">About the Client</Typography>
                                <Typography variant='body2' className='prjover-content'>PACE Center for Girls was established in 1985 with its inaugural center in Jacksonville, Florida, and has since expanded to encompass 19 centers throughout the state. PACE dedicates itself to offering girls and young women pathways to a brighter future via education, counselling, training, and advocacy. Annually, PACE extends its services to over 2,000 girls, impacting the life trajectories of more than 37,000 girls since its inception.</Typography>
                            </Box>
                            {/* The Challenge */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">The Challenge</Typography>
                                <Typography variant='body2' className='prjover-content' >Pace provides girls and young women an opportunity for a better future through education, counseling, training, and advocacy.</Typography>
                            </Box>

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">Objectives</Typography>
                                <Typography variant='body2' className='prjover-content'>Deployment of an intranet portal on a cloud-based platform.</Typography>
                                <Typography variant='body2' className='prjover-content'>Implementation of custom branding to ensure a uniform appearance and experience across all sites.</Typography>
                                <Typography variant='body2' className='prjover-content'>Creation of distinct departmental sites, including Training, HR, IT, Finance, External Affairs, and others.</Typography>
                                <Typography variant='body2' className='prjover-content'>Development of a comprehensive landing page featuring CEO Announcements, Event Calendar, essential links, Weather updates, and other pertinent announcements.</Typography>
                                <Typography variant='body2' className='prjover-content'>Integration of robust document management functionalities across all departments.</Typography>
                            </Box>

                        </Box>
                        <Box display="flex" flexDirection={{ xs: 'column' }} >
                            {/* Image Slider Section */}
                            <Box className="prj_scrnshot" textAlign="center">
                                <ImageSlider imageList={sliderImages || []} />

                            </Box>
                            {/* Client Quote */}
                            <Box className="prj_client_quote" textAlign="center" mt={4}>
                                <Typography variant="body2" component="blockquote" className="client_quote_w quote_rounded" >
                                    <span className="left">“</span><Typography variant='body2' className='prjover-content'><Typography variant='body2' className='prjover-content'>The team has great subject matter expertise on O365 platform which helps us to understand the pros and cons of approaches they take for building the solution.</Typography></Typography><span className="right">”</span>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    {/* Solution and Benefits */}
                    <Box className="prj_overview_fulldiv">

                        {/* The Solution */}

                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>The Solution</Typography>
                                <Typography variant='body2' className='prjover-content'>Based on their requirements and objectives, our consulting team recommended implementing SharePoint Online (Office 365 platform) for their Intranet.</Typography>
                                <Typography variant='body2' className='prjover-content'>Following their design guidelines, a master page was designed and implemented. The landing page featured CEO announcements, the Board’s Vision Statement, Quick links, a picture gallery, and a weather widget.</Typography>
                                <Typography variant='body2' className='prjover-content'>For collaboration, Out of the Box (OOTB) Yammer groups were configured within the web parts on the Home page of each department.</Typography>
                                <Typography variant='body2' className='prjover-content'>For the HR Department, customized OOTB forms were developed using JavaScript to handle employee requests such as onboarding, personal information updates, and credit limit changes.</Typography>
                                <Typography variant='body2' className='prjover-content'>In the Finance Department, a term store was set up to tag location centers for managing financial reports. Uploading a report to the central repository triggered automatic duplication in the HQ document library.</Typography>
                                <Typography variant='body2' className='prjover-content'>Various OOTB approval workflows were established for forms including Fleet Management, Building Maintenance, Contract Request, Invoice Approval, and Credit Limit Change, allowing image uploads as needed.</Typography>
                                <Typography variant='body2' className='prjover-content'>For the IT Department, an InfoPath form was created to collect equipment details for physical asset management and audits.</Typography>
                        </Box>
                        {/* Benefits */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>Benefits</Typography>
                                <Typography variant='body2' className='prjover-content'>A streamlined intranet facilitating seamless information flow throughout the organization.</Typography>
                                <Typography variant='body2' className='prjover-content'>Enhanced collaboration platform featuring robust document management functionalities.</Typography>
                                <Typography variant='body2' className='prjover-content'>Elimination of hardware maintenance burdens due to cloud hosting.</Typography>
                        </Box>

                    </Box>
                </Container >
            </Box >
            <Box className="Case-Study-List"  >
            {works.length > 0 &&
                    <CustomerStories pageName='caseStudy' workList={works} />
                }
            </Box>
            <CaseStudyLetsGetStarted projectName='Microsoft' />
            {works.length > 0 ?
                <Footer hasWorkItem={false}/>:<Footer hasWorkItem={true}/>
            }
        </>
    );
};

export default SharepointConsultingPace1;
