import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import ImageSlider from './ImageSlider';
import CaseStudyLetsGetStarted from './CaseStudyLetsGetStarted';
import HeaderSection from '../../sections/HeaderSection';
import { Link } from 'react-router-dom';
import workJson from "../../Work.json";
import CustomerStories from '../../sections/CustomerStories';
import { workCaseStudy } from '../../interface/WorkCaseStudy';
import HeadImg from '../../assets/Images/common/career.jpg';
import Footer from '../../components/Footer';
import { caseStudyWorkFilter } from '../../common-functions/caseStudyWorkFilter';
import useMetaTags from '../../common-functions/useMetaTags';

const SharepointConsultingZSPharma1: React.FC = () => {
    useMetaTags(
        "SharePoint Solutions | Case Study - Intranet Portal",
        "",
        ""
      );
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const additionalKeywords = ['microsoft','office','sharepoint','react','angular','ai','machinelearning'];
        const results = caseStudyWorkFilter(workJson.workCaseStudy,additionalKeywords,'pharma','sharepoint-consulting-ca-usa-zspharma-1');
        setWorks(results);
    }, []);
    const sliderImages = [
        '/assets/Images/CaseStudy/sharepoint-consulting-ca-usa-zspharma-1/zspharma1.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-ca-usa-zspharma-1/zs-pharma2.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-ca-usa-zspharma-1/zs-pharma3.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-ca-usa-zspharma-1/zs-pharma4.png',
    ];
    return (
        <>
           <HeaderSection pageName='Case Study'  imageUrl={HeadImg} alt="Background Image" />
            <Box className=" whitebox contentbox" id="Project-Overview" sx={{ padding: 4}} >
                <Container className="contentpage">
                    <Box className="casestudy_container">
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} className="compnay_detail_div">
                            <Box flex={1} className="company_logo" sx={{ textAlign: 'center', margin: 5 }}>
                                <img src='/assets/Images/work/zspharma.jpg' alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                            </Box>

                            <Box flex={2} p={2} className="company_detail">
                                <Typography variant="h5" className='cmp_dtl_title'>ZSPharma</Typography>
                                <Box mt={2} className="cmpdtl_fullbox" >
                                <div className="companydetails" >                            
                                    <ul><li> <b>Location:</b></li><li><span>CA, USA</span></li></ul>
                                    <ul><li> <b>Industry:</b></li><li><span>Pharma</span></li></ul>
                                    <ul><li> <b>Environment:</b></li><li><span>Office 365 - SharePoint Online</span> </li></ul>
                                    <ul><li> <b>Skills:</b></li><li><span>JSLink, JSOM, Survey Charts, Yahoo Finance API, SharePoint Online</span></li></ul>
                                    <ul><li> <b>Project(s):</b></li><li> <a className='active'><span>Intranet Portal</span></a></li></ul></div>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                <Container className="innerpagecontainer contentpage prj_overview_fulldiv">
                    <Typography variant="h2" gutterBottom className='prjover-main-title'>
                        Enhancing Performance of ZS Pharma’s Intranet Portal on Office 365 – SharePoint Online
                    </Typography>
                    {/* : null} */}

                    <Box display="flex" flexDirection={{ xs: 'column', md: "row" }} >
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'column' }} className="prj_overview_div">
                            {/* About the Client */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">About the Client</Typography>
                                <Typography variant='body2' className='prjover-content'>The ZS Pharma, founded in 2008, is a biopharmaceutical company based in San Mateo, California. Specializing in innovative therapies for challenging medical conditions, ZS Pharma joined the AstraZeneca Group in 2015 after going public in 2014."</Typography>
                            </Box>
                            {/* The Challenge */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">The Challenge</Typography>
                                <Typography variant='body2' className='prjover-content' >ZS Pharma’s intranet portal on Office365 had performance issues with unreasonable load time and frequent “request timed out” errors.</Typography>
                            </Box>

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">Objectives</Typography>
                                    <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>- Enhance overall performance.</Typography>
                                    <Typography variant='body2' className='prjover-content'  style={{ paddingLeft: '30px' }}>- Enable real-time data updates for Survey Charts and Stocks web parts.</Typography>
                            </Box>

                        </Box>
                        <Box display="flex" flexDirection={{ xs: 'column' }} >
                            {/* Image Slider Section */}
                            <Box className="prj_scrnshot" textAlign="center">
                                <ImageSlider imageList={sliderImages || []} />

                            </Box>
                            {/* Client Quote */}
                            <Box className="prj_client_quote" textAlign="center" mt={4}>
                                <Typography variant="body2" component="blockquote" className="client_quote_w quote_rounded" >
                                    <span className="left">“</span><Typography variant='body2' className='prjover-content'>The team has great subject matter expertise on O365 platform which helps us to understand the pros and cons of approaches they take for building the solution.</Typography><span className="right">”</span>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    {/* Solution and Benefits */}
                    <Box className="prj_overview_fulldiv">

                        {/* The Solution */}

                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>The Solution</Typography>
                                <Typography variant='body2' className='prjover-content'>In response to performance concerns, a thorough health check uncovered the need for optimization across scripts and custom solutions. Key measures included:</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>- Refactoring code for efficiency.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>- Minification of JavaScript (JS) and Cascading Style Sheets (CSS) files.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>- Implementation of diverse compression techniques.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>- Reconstruction of select solutions to streamline performance.</Typography>
                                <Typography variant='body2' className='prjover-content'>The homepage hosted various web parts spanning Alerts, News, Videos, RSS Feeds, Stocks, Survey Charts, My Links, and Countdown, contributing to prolonged load times. Remedial actions comprised:</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>- Image and thumbnail compression in sections like Alerts, News, and Videos.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>- Replacement of the stockticker.js script with the Yahoo! Finance REST API for the Stocks web part.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>- Redevelopment of custom web parts (e.g., My Links, Countdown, and Survey Charts) to optimize performance.</Typography>
                                <Typography variant='body2' className='prjover-content'>For real-time data updates in Survey Charts, a data storage form was devised, enabling retrieval and presentation through JavaScript Object Model (JSOM). Additionally, users could visualize charts in bar or pie form via the jqPlot jQuery plugin.</Typography>
                                <Typography variant='body2' className='prjover-content'>To expedite page loading, the Countdown timer for new events was segregated from the homepage using a jQuery plugin.</Typography>
                                <Typography variant='body2' className='prjover-content'>Adherence to coding best practices and effective compression techniques led to significant reductions in page load times, culminating in a lighter and swifter portal for user interaction.</Typography>
                        </Box>
                        {/* Benefits */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>Benefits</Typography>
                            <Typography variant='body2' className='prjover-content'>
                            The improvements in performance and load times led to enhanced user engagement and increased utilization of ZS Pharma’s Intranet Portal, thereby validating the return on investment (ROI) in SharePoint Online and Office 365.
                            </Typography>
                        </Box>

                    </Box>
                </Container >
            </Box >
               <Box id="Case-Study-List">
                    {/* Title */}
                    {works.length > 0 &&
                    <CustomerStories pageName='caseStudy' workList={works} />
                }
                </Box>
            <CaseStudyLetsGetStarted projectName='Intranet Portal' />
            {works.length > 0 ?
                <Footer hasWorkItem={false}/>:<Footer hasWorkItem={true}/>
            }
        </>
    );
};

export default SharepointConsultingZSPharma1;
