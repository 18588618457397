// src/pages/HomePage.tsx
import React, { useEffect, useRef, useState } from 'react';
import Services from '../sections/Services';
import Process from '../sections/Process';
import Technologies from '../sections/Technologies';
import CustomerStories from '../sections/CustomerStories'
import HeroSection from '../sections/HeroSection';
import Footer from '../components/Footer';
import useMetaTags from '../common-functions/useMetaTags';
import { useLocation } from 'react-router-dom';

const HomePage: React.FC = () => {

  useMetaTags(
    "Microsoft technologies | AI solutions | Mobile applications | Salesforce",
    "Binary Republik is a leading Technology Consulting and Development Company specializing in Microsoft stack – SharePoint, Office 365, Power Platform solutions, and Azure services, Salesforce, AI Implementation, Mobile Apps.",
    "Microsoft Technology partners, Digital Transformation Experts"
  );
  const location = useLocation();
  const topHomeRef = useRef<HTMLDivElement>(null);
  const topProcessRef = useRef<HTMLDivElement>(null);
  // const [isWidgetShown, setIsWidgetShown] = useState(false);
  useEffect(() => {
     if (location.hash === '#process') {
      const timer = setTimeout(() => {
        if (topProcessRef.current) {
          topProcessRef.current.scrollIntoView({ behavior: 'smooth' });
          //window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }, 100); // Adjust the delay as needed

      return () => clearTimeout(timer);
    }
    else if (location.hash === '#services') {
      if (localStorage.getItem('isServiceClick') == 'true') {
        localStorage.setItem('isServiceClick', 'false');
      }
      else {

        const timer = setTimeout(() => {
          const element = document.getElementById('services');
          if (element) {
            const yOffset = -85; // Adjust this value as needed
            const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            // element.scrollIntoView({ behavior: 'smooth', block: 'start'  });
          }
        }, 50); // Adjust the delay as needed

        return () => clearTimeout(timer);

      }
    }
  }, [location]);
  return (
    <div ref={topHomeRef}>
      <HeroSection />
      <CustomerStories pageName='home' workList={[]} />
      <Services />
      <Process topProcessRef={topProcessRef} />
      <Technologies />
      <Footer hasWorkItem={false} />
    </div>
  );
};

export default HomePage;
