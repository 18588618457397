import React, { useState } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import { postLogin } from '../services/ApiService';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [failureMessage, setFailureMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const token = await postLogin(username, password);
    const userRole = localStorage.getItem('userRole');
    // Replace this with your actual login logic (API call, etc.)
    if (token === null && userRole === null) {
      setFailureMessage('Invalid username or password');
    } else if (token !== null && userRole === 'HR') {
      navigate('/controlpanel/careervisitors');
    }
    else if (token !== null && userRole === 'Admin') {
      navigate('/controlpanel/contactvisitors');
    }
  };

  return (
    <Box
      sx={{ padding: 4, textAlign: "center" }}
      className="contentbox whitebox"
      width="calc(100% - 66px) !important"
    >

      {/* Display failure message if any */}
      {failureMessage && (
        <Typography color="error" variant="body1" className="redColorFont">
          {failureMessage}
        </Typography>
      )}

      <form onSubmit={handleSubmit} className=" formstyles loginform">
        <Box mb={2}>
          <TextField
            fullWidth
            label="User Name"
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </Box>

        <Box mb={2}>
          <TextField
            fullWidth
            label="Password"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Box>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className="progress-button"
        >
          Login
        </Button>
      </form>
    </Box>
  );
};

export default Login;
