import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import ImageSlider from './ImageSlider';
import CaseStudyLetsGetStarted from './CaseStudyLetsGetStarted';
import HeaderSection from '../../sections/HeaderSection';
import { Link } from 'react-router-dom';
import workJson from "../../Work.json";
import CustomerStories from '../../sections/CustomerStories';
import { workCaseStudy } from '../../interface/WorkCaseStudy';
import HeadImg from '../../assets/Images/common/career.jpg';
import Footer from '../../components/Footer';
import { caseStudyWorkFilter } from '../../common-functions/caseStudyWorkFilter';
import useMetaTags from '../../common-functions/useMetaTags';

const SharepointConsultingCDMSmith1: React.FC = () => {
    useMetaTags(
        "SharePoint Solutions | Case Study - Career Map",
        "",
        ""
      );
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const additionalKeywords = ['sharepoint','microsoft','office','react','angular','ai','machinelearning'];
        const results = caseStudyWorkFilter(workJson.workCaseStudy,additionalKeywords,'engineering','sharepoint-consulting-massachusetts-usa-cdm-smith-1');
        setWorks(results);
    }, []);
    const sliderImages = [
        '/assets/Images/CaseStudy/sharepoint-consulting-massachusetts-usa-cdm-smith-1/cdm1.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-massachusetts-usa-cdm-smith-1/cdm2.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-massachusetts-usa-cdm-smith-1/cdm3.png',
    ];
    return (
        <>
            <HeaderSection pageName='Case Study'  imageUrl={HeadImg} alt="Background Image" />
            <Box className=" whitebox contentbox" id="Project-Overview" sx={{ padding: 4}} >
                <Container className="contentpage">
                    <Box className="casestudy_container">
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} className="compnay_detail_div">
                            <Box flex={1} className="company_logo" sx={{ textAlign: 'center', margin: 5 }}>
                                <img src='/assets/Images/work/cdmsmith.jpeg' alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                            </Box>

                            <Box flex={2} p={2} className="company_detail">
                                <Typography variant="h5" className='cmp_dtl_title'>CDM Smith</Typography>
                                <Box mt={2} className="cmpdtl_fullbox" >
                                <div className="companydetails" >   
                                    <ul><li> <b>Location:</b></li><li><span>Massachusetts, USA</span></li></ul>
                                    <ul><li> <b>Industry:</b></li><li><span>Engineering</span></li></ul>
                                    <ul><li> <b>Environment:</b></li><li><span>Office 365 - SharePoint Online</span></li></ul>
                                    <ul><li> <b>Skills:</b></li><li><span>SQL Server , .net Core API, Type Script, React</span></li></ul>
                                    <ul><li> <b>Project(s):</b></li><li> <a className='active'><span>Intranet Portal</span></a></li></ul></div>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Container>
                <Container className="innerpagecontainer contentpage prj_overview_fulldiv">

                    <Box display="flex" flexDirection={{ xs: 'column', md: "row" }} >
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'column' }} className="prj_overview_div">
                            {/* About the Client */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">About the Client</Typography>
                                <Typography variant='body2' className='prjover-content'>CDM Smith is a global, privately owned engineering and construction firm specializing in water, environment, transportation, energy, and facilities. It operates through North America, Federal Services, and International Units, supported by Technical Services and Construction Units, delivering integrated solutions worldwide.</Typography>
                            </Box>
                            {/* The Challenge */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">The Challenge</Typography>
                                <Typography variant='body2' className='prjover-content' >
                                    <ul>
                                        <li><strong>Outdated Setup:</strong> Reliance on classic SharePoint, Designer workflows, and InfoPath forms.</li>
                                        <li><strong>Poor User Experience:</strong> Limited functionality and navigation in the classic intranet.</li>
                                        <li><strong>Manual Processes:</strong> Lack of automation for site provisioning and workflows.</li>
                                        <li><strong>Inefficient Search:</strong> Need for better search options like intranet, employee, and TKM search.</li>
                                        <li><strong>Modernization Need:</strong> Transition of classic web parts to modern SPFx web parts and features</li>
                                    </ul>
                                </Typography>
                            </Box>

                        </Box>
                        <Box display="flex" flexDirection={{ xs: 'column' }} >
                            {/* Image Slider Section */}
                            <Box className="prj_scrnshot" textAlign="center">
                                <ImageSlider imageList={sliderImages || []} />
                            </Box>

                        </Box>
                    </Box>

                    {/* Solution and Benefits */}
                    <Box className="prj_overview_fulldiv">
                        {/* The Solution */}

                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>The Solution</Typography>
                            <Typography variant='body2' className='prjover-content'>
                                CDM Smith engaged us to modernize their SharePoint intranet by migrating classic sites to modern sites and integrating advanced features. Below is a summary of the delivered solutions:</Typography>
                                <Typography variant='body2' className='prjover-content'>
                                <ul>
                                    <li><strong>Intranet Modernization:</strong>
                                        <ul>
                                            <li style={{ paddingLeft: '30px' }}>Migrated classic SharePoint sites to modern sites, leveraging new features.</li>
                                            <li style={{ paddingLeft: '30px' }}>Implemented a dynamic Modern Megamenu using SPFx Application Customizer for seamless navigation.</li>
                                        </ul>
                                    </li>
                                    <li><strong>Custom SPFx Webparts:</strong>
                                        <ul>
                                            <li style={{ paddingLeft: '30px' }}>Developed modern webparts like Quick Links, FAQ, Accordion FAQ, Our Team, and Script Editor for improved functionality and design.</li>
                                        </ul>
                                    </li>
                                    <li><strong>Forms Modernization:</strong>
                                        <ul>
                                            <li style={{ paddingLeft: '30px' }}>Converted 11 InfoPath/Nintex forms into PowerApps with advanced business logic and improved UI.</li>
                                            <li style={{ paddingLeft: '30px' }}>Integrated multi-stage approval workflows using Power Automate.</li>
                                        </ul>
                                    </li>
                                    <li><strong>Modern Search:</strong>
                                        <ul>
                                            <li style={{ paddingLeft: '30px' }}>Configured tailored search functionalities, including Intranet Search, Employee Search, and TKM Search with custom display templates and refiners.</li>
                                        </ul>
                                    </li>
                                    <li><strong>Automated Site Provisioning:</strong>
                                        <ul>
                                            <li style={{ paddingLeft: '30px' }}>Created auto-provisioning programs for Department and Workroom sites, enabling rapid setup with pre-defined configurations like themes, permissions, SPFx webparts, and content.</li>
                                        </ul>
                                    </li>
                                    <li><strong>Enhanced Tools &amp; Technologies:</strong>
                                        <ul>
                                            <li style={{ paddingLeft: '30px' }}>Utilized modern tools like PnP PowerShell, Node.js, React, and Visual Studio for development and deployment.</li>
                                            <li style={{ paddingLeft: '30px' }}>Incorporated PnP Modern Search V3 for advanced search capabilities.</li>
                                        </ul>
                                    </li>
                                </ul>
                                </Typography>
                        </Box>
                        {/* Benefits */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>Benefits</Typography>
                            <Typography variant='body2' className='prjover-content'>
                                <ul>
                                    <li><strong>Enhanced User Experience:</strong> Modern design and dynamic megamenu ensure seamless navigation.</li>
                                    <li><strong>Increased Productivity:</strong> Custom SPFx webparts and automated workflows simplify tasks and improve accessibility.</li>
                                    <li><strong>Automation and Scalability:</strong> Automated site provisioning reduces manual effort and ensures consistency.</li>
                                    <li><strong>Improved Collaboration:</strong> Modernized forms and workroom sites enhance internal and external teamwork.</li>
                                    <li><strong>Cost Efficiency and Future-Readiness:</strong> Replaces outdated tools, reducing costs and ensuring long-term support.</li>
                                </ul>
                            </Typography>
                        </Box>
                    </Box>
                </Container >
            </Box >
                <Box id="Case-Study-List">
                    {works.length > 0 &&
                        <CustomerStories pageName='caseStudy' workList={works} />
                    }
                </Box>
            <CaseStudyLetsGetStarted projectName='Intranet Portal' />
            {works.length > 0 ?
                <Footer hasWorkItem={false}/>:<Footer hasWorkItem={true}/>
            }
        </>
    );
};

export default SharepointConsultingCDMSmith1;
