import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import ImageSlider from './ImageSlider';
import CaseStudyLetsGetStarted from './CaseStudyLetsGetStarted';
import HeaderSection from '../../sections/HeaderSection';
import { Link } from 'react-router-dom';
import CustomerStories from '../../sections/CustomerStories';
import { workCaseStudy } from '../../interface/WorkCaseStudy';
import workJson from "../../Work.json";
import HeadImg from '../../assets/Images/common/career.jpg';
import Footer from '../../components/Footer';
import { caseStudyWorkFilter } from '../../common-functions/caseStudyWorkFilter';
import useMetaTags from '../../common-functions/useMetaTags';

const SharepointConsultingMicrosoft1: React.FC = () => {
    useMetaTags(
        "SharePoint Solutions | Case Study - Knowledge Management Portal",
        "",
        ""
    );
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const additionalKeywords = ['microsoft', 'sharepoint', 'office', 'react', 'angular', 'ai', 'machinelearning'];
        const results = caseStudyWorkFilter(workJson.workCaseStudy, additionalKeywords, 'it', 'sharepoint-consulting-wa-usa-microsoft-1');
        setWorks(results);
    }, []);
    const sliderImages = [
        '/assets/Images/CaseStudy/sharepoint-consulting-wa-usa-microsoft-1/geso1-1.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-wa-usa-microsoft-1/geso2.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-wa-usa-microsoft-1/geso3-1.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-wa-usa-microsoft-1/geso4-1.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-wa-usa-microsoft-1/geso5-1.png',
    ];
    return (
        <>
            <HeaderSection pageName='Case Study' imageUrl={HeadImg} alt="Background Image" />
            <Box className=" whitebox contentbox" id="Project-Overview" sx={{ padding: 4 }}>
                <Container className="contentpage">
                    <Box className="casestudy_container">
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} className="compnay_detail_div">
                            <Box flex={1} className="company_logo" sx={{ textAlign: 'center', margin: 5 }}>
                                <img src='/assets/Images/work/microsoft.jpg' alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                            </Box>

                            <Box flex={2} p={2} className="company_detail">
                                <Typography variant="h5" className='cmp_dtl_title'>Microsoft</Typography>
                                <Box mt={2} className="cmpdtl_fullbox" >

                                    <div className="companydetails">
                                        <ul><li> <b>Location:</b></li><li><span>WA, USA</span></li></ul>
                                        <ul><li> <b>Industry:</b></li><li><span>IT</span></li></ul>
                                        <ul><li> <b>Environment:</b></li><li><span>Office 365 - SharePoint Online</span></li></ul>
                                        <ul><li> <b>Skills:</b></li><li><span>JSOM, SharePoint REST API, SharePoint Online </span></li></ul>
                                        <ul><li> <b>Project(s):</b></li><li> <a className='active'><span>Knowledge Management Portal</span></a> <i className='orangseperator'>|</i> <a href='/case-study/sharepoint-consulting-wa-usa-microsoft-2'><span>Intranet Portal</span></a></li></ul></div>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Container>
                <Container className="innerpagecontainer contentpage prj_overview_fulldiv">
                    {/* {caseStudy?.clientSlogan != null ?  */}
                    <Typography variant="h2" gutterBottom className='prjover-main-title'>
                        Microsoft GESO Team Builds a Knowledge Management Portal on
                        Office 365 – SharePoint Online
                    </Typography>
                    {/* : null} */}

                    <Box display="flex" flexDirection={{ xs: 'column', md: "row" }} >
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'column' }} className="prj_overview_div">
                            {/* About the Client */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">About the Client</Typography>
                                <Typography variant='body2' className='prjover-content'>The Global Enterprise Services Operations (GESO) team at Microsoft is dedicated to delivering professional services for Microsoft Consulting and Premier Services businesses. GESO engages directly with clients, offering expert solutions on Microsoft technologies.</Typography>
                            </Box>
                            {/* The Challenge */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">The Challenge</Typography>
                                <Typography variant='body2' className='prjover-content' ><Typography variant='body2' className='prjover-content'>GESO aimed to integrate E-Learning and employee readiness modules into their intranet portal. Their objective was to encourage both new and experienced team members to collaborate and enrich their knowledge through learning, questioning, discussing, and completing relevant tests. SharePoint Online was the chosen technology platform for this initiative.</Typography></Typography>
                            </Box>

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">Objectives</Typography>
                                <Typography variant='body2' className='prjover-content'><Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>A platform for collaboration and knowledge sharing.</Typography></Typography>
                            </Box>

                        </Box>
                        <Box display="flex" flexDirection={{ xs: 'column' }} >
                            {/* Image Slider Section */}
                            <Box className="prj_scrnshot" textAlign="center">
                                <ImageSlider imageList={sliderImages || []} />

                            </Box>
                            {/* Client Quote */}
                            <Box className="prj_client_quote" textAlign="center" mt={4}>
                                <Typography variant="body2" component="blockquote" className="client_quote_w quote_rounded" >
                                    <span className="left">“</span><Typography variant='body2' className='prjover-content'><Typography variant='body2' className='prjover-content'>The team has great subject matter expertise on O365 platform which helps us to understand the pros and cons of approaches they take for building the solution.</Typography></Typography><span className="right">”</span>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>


                    {/* Solution and Benefits */}
                    <Box className="prj_overview_fulldiv">

                        {/* The Solution */}

                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>The Solution</Typography>
                            <Typography variant='body2' className='prjover-content'>A structured learning platform was established on Microsoft's Office 365 SharePoint Online Portal for the GESO Team.</Typography>
                            <Typography variant='body2' className='prjover-content'>A tailored solution was created to retrieve learning materials and administer them using REST APIs. These materials were meticulously organized into structured learning modules by the administrator. Upon fetching the learning materials from the SharePoint Portal, they were customized on the Portal to align with the modules. This customization involved implementing SharePoint custom master pages and page layouts designed with HTML and CSS, reflecting the key pillars. The retrieved data was then displayed on the created pages using the designated page layouts to ensure consistent look-and-feel across the portal.</Typography>
                            <Typography variant='body2' className='prjover-content'>The GESO team sought to ensure their employees remained current with the latest technologies. They were granted access to Microsoft’s learning module, enabling them to enroll in the most recent courses and maintain up-to-date knowledge of emerging technologies.</Typography>
                            <Typography variant='body2' className='prjover-content'>Each member of the GESO Team had their own user-specific page featuring visual representations of their learning modules and test results. The learning modules were assigned by administrators or experts to each employee, and the test results were displayed for their personal review.</Typography>
                            <Typography variant='body2' className='prjover-content'>The online exam layout presented randomly generated multiple-choice questions, facilitated through REST APIs in the SharePoint custom solution. The computed test results were both displayed to the user and stored in their account.</Typography>
                            <Typography variant='body2' className='prjover-content'>The latest module developed was for Support, designed to assist new GESO team members in finding solutions. This involved searching through FAQs and the available glossary. If an employee couldn't find a relevant article, they could post questions in the Experts forum. In cases where a satisfactory response was not obtained or the query remained unanswered, employees could seek assistance either online or via phone.</Typography>
                            <Typography variant='body2' className='prjover-content'>This custom SharePoint solution enables employees to access everything they need, from learning new technologies to staying updated on-the-go, and testing their skills and knowledge conveniently, all in one place.</Typography>

                        </Box>
                        {/* Benefits */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>Benefits</Typography>
                            <Typography variant='body2' className='prjover-content'>Links and tiles are organized to facilitate understanding of Microsoft's services and products.</Typography>
                            <Typography variant='body2' className='prjover-content'>GESO Team members had a visual representation to assess their skills, capabilities, and knowledge, enabling them to perform efficiently.</Typography>
                            <Typography variant='body2' className='prjover-content'>They were now informed about the new releases and programs within the organization.</Typography>
                            <Typography variant='body2' className='prjover-content'>The organization provided a platform for addressing frequently asked questions (FAQs) for GESO Team members, with support from technology experts.</Typography>

                        </Box>
                    </Box>
                </Container >
            </Box >

            <Box id="Case-Study-List">
                {works.length > 0 &&
                    <CustomerStories pageName='caseStudy' workList={works} />
                }
            </Box>
            <CaseStudyLetsGetStarted projectName='Microsoft' />
            {works.length > 0 ?
                <Footer hasWorkItem={false} /> : <Footer hasWorkItem={true} />
            }
        </>
    );
};

export default SharepointConsultingMicrosoft1;
