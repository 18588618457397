import React, { useEffect, useState } from 'react';
import { Box, Container, List, ListItem, ListItemText, Typography } from '@mui/material';
import mobileSolution from '../assets/Images/services/mobile-solutions.png';
import IOS from '../assets/Images/services/IOS.png';
import Android from '../assets/Images/services/android.png';
import CustomerStories from './CustomerStories';
import { workCaseStudy } from '../interface/WorkCaseStudy';
import workJson from "../../src/Work.json";
import Footer from '../components/Footer';
import { serviceWorkFilter } from '../common-functions/serviceWorkFilter';
import ServiceTabbingSection from '../sub-sections/ServiceCommonComponent/ServiceTabbingSection';
import ServiceCardSection from '../sub-sections/ServiceCommonComponent/ServiceCardSection';
import { CardData, TabData } from '../interface/TabData';
import CaseStudyLetsGetStarted from '../sub-sections/CaseStudy/CaseStudyLetsGetStarted';
import useMetaTags from '../common-functions/useMetaTags';

const MobileSolutionsSection: React.FC = () => {
    useMetaTags(
        "Next-Gen Mobile Solutions for Business Growth | iOS & Android Development",
        "Enhance efficiency and engagement with our custom mobile solutions. We design and develop secure, user-friendly mobile applications for iOS and Android, ensuring seamless integration with your business ecosystem.",
        "Mobile solutions, enterprise mobility, mobile app development, iOS and Android apps, mobile UX/UI design, business mobility solutions, cross-platform mobile development"
      );
    const tabData: TabData[] = [
        {
            heading: (<>iOS App Development</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/services/mobileSolution/ios-app-development.png" alt="ios-app-development" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;">
            `,
            content: (<>
                <p>Creating fast, secure, and intuitive iOS applications, Binary Republik ensures seamless performance across Apple devices. Built with Swift and Objective-C, our apps adhere to Apple’s Human Interface Guidelines (HIG) for a smooth user experience.</p>
                <p>With ARKit for augmented reality, Core ML for AI, and Apple Pay for secure transactions, we develop feature-rich solutions. CloudKit for data synchronization and Face ID authentication further enhance security, making our iOS apps perfect for businesses, enterprises, and e-commerce platforms.</p>
                <p>Discover how our iOS applications drive digital transformation by exploring <a href="/work" onClick={() => selectedTechnology('Mobile App')} className="underlineancor">our work</a>.</p>
            </>),
        },
        {
            heading: (<>Android App Development</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/services/mobileSolution/android-app-development.png" alt="android-app-development" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;">
            `,
            content: (<>
                <p>Developing high-performance and scalable Android applications, Binary Republik leverages Kotlin and Java to create apps for smartphones, tablets, TVs, and wearables. We ensure smooth performance with seamless Google Play Store deployment.</p>
                <p>By utilizing Jetpack Compose for UI, Firebase for backend services, and TensorFlow Lite for AI, we build feature-rich, business-driven applications. With multi-device compatibility and Google Assistant integration, our Android solutions enable businesses to reach a wider audience efficiently.</p>
                <p>Check out how our Android apps empower businesses by visiting <a href="/work" onClick={() => selectedTechnology('Mobile App')} className="underlineancor">our portfolio</a>.</p>
            </>),
        },
        {
            heading: (<>Flutter App Development</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/services/mobileSolution/flutter-app-development.png" alt="flutter-app-development" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;">
            `,
            content: (<>
                <p>With Flutter, Binary Republik delivers cost-effective, cross-platform applications that provide a native-like experience across iOS, Android, Web, and Desktop from a single codebase. Our solutions ensure rapid development with Flutter’s Hot Reload and smooth UI using Skia rendering.</p>
                <p>We integrate Firebase for cloud services, AI-driven features, and embedded device compatibility, making our Flutter solutions ideal for startups, enterprises, and e-commerce businesses looking for a scalable and visually engaging mobile experience.</p>
                <p>Explore how our Flutter solutions bring efficiency and innovation by checking out <a href="/work" onClick={() => selectedTechnology('Mobile App')} className="underlineancor">our recent projects</a>.</p>
            </>),
        },
        {
            heading: (<>React Native App Development</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/services/mobileSolution/react-native-app-development.png" alt="react-native-app-development" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;">
            `,
            content: (<>
                <p>For businesses seeking cross-platform mobile applications, Binary Republik provides React Native development to ensure a seamless experience on iOS and Android with a single JavaScript-based codebase. Hot Reloading accelerates development, allowing real-time updates.</p>
                <p>With native modules for high performance, Redux and GraphQL for efficient data management, and Firebase for authentication and cloud storage, our React Native solutions offer cost-effective, scalable, and high-performing applications, trusted by global brands.</p>
                <p>See how our React Native solutions transform mobile experiences by exploring <a href="/work" onClick={() => selectedTechnology('Mobile App')} className="underlineancor">our case studies</a>.</p>
            </>),
        }
    ];

    const cardsData: CardData[] = [
        {
            heading: 'Enterprise Mobility',
            items: [
                'Secure Mobile Device Management',
                'Enterprise App Integration',
                'Mobile Workflow Automation',
                'Custom Business App Development',
                'Real-Time Data Syncing',
                'Cross-Platform Mobile Solutions',
                'AI-Driven Mobile Analytics'
            ],
        },
        {
            heading: 'E-Commerce & Retail',
            items: [
                'Seamless Mobile Shopping Experience',
                'AI-Powered Product Recommendations',
                'Instant Mobile Checkout & Payments',
                'Smart Inventory & Order Management',
                'Personalized Customer Engagement',
                'Augmented Reality Shopping',
                'Loyalty Program & Push Notifications'
            ],
        },
        {
            heading: 'Healthcare Mobility',
            items: [
                'Remote Patient Monitoring',
                'AI-Driven Telemedicine Solutions',
                'Mobile Health Records Access',
                'Automated Appointment Scheduling',
                'HIPAA-Compliant Mobile Security',
                'Wearable Health Data Integration',
                'Real-Time Health Alerts & Notifications'
            ],
        },
        {
            heading: 'Logistics & Field Services',
            items: [
                'Real-Time Fleet Tracking & Monitoring',
                'Automated Delivery Scheduling',
                'Mobile Workforce Management',
                'AI-Powered Route Optimization',
                'Offline Data Access & Sync',
                'Mobile Barcode & RFID Scanning',
                'Predictive Maintenance Alerts'
            ],
        },
    ];

    const selectedTechnology = (technology: string) => {
       sessionStorage.setItem("Selected-Vertical", 'All');
       sessionStorage.setItem("Selected-Technology", technology);
    }
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const keywords = ['mobile', 'mobile solution', 'mobile-solution'];
        const additionalKeywords = ['sharepoint', 'technology', 'microsoft'];
        const results = serviceWorkFilter(workJson.workCaseStudy, keywords, additionalKeywords);
        setWorks(results);
    }, []);
    return (
        <>
            <Box sx={{ padding: 4, textAlign: 'center' }} className="contentbox whitebox " >
                <Container className="innerpagecontainer contentpage">
                    <Typography variant="h2" gutterBottom className='contentwidth to_build'>
                        Empower Your Business with Custom Mobile Solutions for Enhanced Efficiency and Growth </Typography>
                    <Typography variant="body1" sx={{ marginBottom: 2 }} className='contentwidth'>
                        Our custom mobile solutions enhance efficiency and growth with intuitive design, seamless integration, and scalability</Typography>
                  

<div className='servicesLogo'>
                        <ul>
                            <li><span><img src={IOS} alt="Salesforce Consulting" /></span></li>
                            <li><span><img src={Android} alt="Salesforce Consulting" /></span></li>
                        </ul>
                    </div>
                </Container>
            </Box>
            <Box className="outerbox powerbitab" sx={{ bgcolor: "#f2c811" }}  >
                <ServiceTabbingSection tabData={tabData} />
            </Box>
            <Box sx={{ padding: 4 }} className="contentbox whitebox contentpage"  >
                <Container className="innerpagecontainer contentpage" >
                    <ServiceCardSection cardsData={cardsData} verticalTitle='Revolutionizing Industries with Next-Gen Mobile Solutions' />
                </Container>
            </Box>
            {works.length > 0 &&
                <CustomerStories pageName='Mobile App' workList={works} />
            }
            <CaseStudyLetsGetStarted projectName='Mobile Solutions Service' />
            {works.length > 0 ?
                <Footer hasWorkItem={false} /> : <Footer hasWorkItem={true} />
            }
        </>
    );
};

export default MobileSolutionsSection;
