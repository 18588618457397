import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import ImageSlider from './ImageSlider';
import CaseStudyLetsGetStarted from './CaseStudyLetsGetStarted';
import HeaderSection from '../../sections/HeaderSection';
import workJson from "../../Work.json";
import { workCaseStudy } from '../../interface/WorkCaseStudy';
import CustomerStories from '../../sections/CustomerStories';
import HeadImg from '../../assets/Images/common/career.jpg';
import Footer from '../../components/Footer';
import { caseStudyWorkFilter } from '../../common-functions/caseStudyWorkFilter';
import useMetaTags from '../../common-functions/useMetaTags';

const SharepointConsultingWRHI1: React.FC = () => {
    useMetaTags(
        "Binary Republik | Case Study - Intranet Portal",
        "",
        ""
      );
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const additionalKeywords = ['sharepoint','microsoft','office','react','angular','ai','machinelearning'];
        const results = caseStudyWorkFilter(workJson.workCaseStudy,additionalKeywords,'healthcare','sharepoint-consulting-Johannesburg-southafrica-wrhi-1');
        setWorks(results);
    }, []);
    const sliderImages = [
        '/assets/Images/CaseStudy/sharepoint-consulting-Johannesburg-southafrica-wrhi-1/wrhi-1.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-Johannesburg-southafrica-wrhi-1/wrhi-2.png',
    ];
    return (
        <>
            <HeaderSection pageName='Case Study'  imageUrl={HeadImg} alt="Background Image"  />
            <Box className=" whitebox contentbox" id="Project-Overview" sx={{ padding: 4 }} >
                <Container className="contentpage">
                    <Box className="casestudy_container">
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} className="compnay_detail_div">
                            <Box flex={1} className="company_logo" sx={{ textAlign: 'center', margin: 5 }}>
                                <img src='/assets/Images/work/rhi-big.jpg' alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                            </Box>

                            <Box flex={2} p={2} className="company_detail">
                                <Typography variant="h5" className='cmp_dtl_title'>Wits RHI</Typography>
                                <Box mt={2} className="cmpdtl_fullbox" >
                                <div className="companydetails" >   
                                    <ul><li> <b>Location:</b></li><li><span>Johannesburg, South Africa</span></li></ul>
                                    <ul><li> <b>Industry:</b></li><li><span>Healthcare</span></li></ul>
                                    <ul><li> <b>Environment:</b></li><li><span>SharePoint 2013</span></li></ul>
                                    <ul><li> <b>Skills:</b></li><li><span>SharePoint 2013, Responsive UI, Branding, Bootstrap 3.0</span></li></ul>
                                    <ul><li> <b>Project(s):</b></li><li> <a className='active'><span>Intranet portal</span></a></li></ul></div>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Container>
                <Container className="innerpagecontainer contentpage prj_overview_fulldiv">
                    <Typography variant="h2" gutterBottom className='prjover-main-title'>
                        Wits Reproductive Health and HIV Institute (WRHI) unveils an upgraded
                        interface for its SharePoint intranet portal, offering enhanced features
                    </Typography>
                    {/* : null} */}

                    <Box display="flex" flexDirection={{ xs: 'column', md: "row" }} >
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'column' }} className="prj_overview_div">
                            {/* About the Client */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">About the Client</Typography>
                                <Typography variant='body2' className='prjover-content'>Founded in 1994, the Wits Reproductive Health and HIV Institute (WRHI) stands as one of Africa's foremost multi-disciplinary institutes. It champions the implementation of new government policies concerning HIV, sexual and reproductive health (SRH), and vaccine-preventable diseases (VPDs). Its initiatives encompass research, programmatic support, training, policy development, health systems strengthening, and global-level technical assistance.</Typography>
                            </Box>
                            {/* The Challenge */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">The Challenge</Typography>
                                <Typography variant='body2' className='prjover-content' >The WRHI's SharePoint-based intranet portal experienced limited user adoption within the organization. Consequently, there is a drive to rebrand the portal and integrate new functionalities to foster improved user collaboration.</Typography>
                            </Box>

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">Objectives</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>-Enhanced User Interface: SharePoint Branding</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>-Integrated Events Calendar</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>-Up-to-date News Feed</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>-Dynamic Weather Section</Typography>
                            </Box>

                        </Box>
                        <Box display="flex" flexDirection={{ xs: 'column' }} >
                            {/* Image Slider Section */}
                            <Box className="prj_scrnshot" textAlign="center">
                                <ImageSlider imageList={sliderImages || []} />

                            </Box>
                        </Box>
                    </Box>


                    {/* Solution and Benefits */}
                    <Box className="prj_overview_fulldiv">

                        {/* The Solution */}

                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>The Solution</Typography>
                                <Typography variant='body2' className='prjover-content'>Following WRHI’s design brief, a new user interface was meticulously crafted with a focus on addressing their challenge of low user adaptation. The navigation was designed to be intuitive, while the overall look and feel were tailored to accommodate user preferences. Upon approval of the design, a comprehensive solution was developed for deployment, encompassing branding files such as master pages, CSS, and JS files.</Typography>
                                <Typography variant='body2' className='prjover-content'>In addition to branding, WRHI desired to feature functionalities such as Weather updates, Latest News, Events Calendars, and time zone widgets on the Home page.</Typography>
                                <Typography variant='body2' className='prjover-content'>· The Weather functionality was designed to automatically display the weather report for their headquarters city by default. However, users had the flexibility to change it to display weather information for other cities as needed.</Typography>
                                <Typography variant='body2' className='prjover-content'>The Latest News section will feature an organizational news feed for employees. This content will be sourced from a SharePoint list maintained and updated by the WRHI IT Team.</Typography>
                                <Typography variant='body2' className='prjover-content'>WRHI aimed to keep its employees informed about various events occurring within the organization across multiple locations. To facilitate this, a custom visual web part was created to display these events. Additionally, employees were given the ability to add their own events to the calendar.</Typography>
                                <Typography variant='body2' className='prjover-content'>The new functionalities were developed as custom visual web parts and configured for one-click deployment to the home page as part of a customized solution, integrated seamlessly with the branding.</Typography>
                        </Box>
                        {/* Benefits */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>Benefits</Typography>
                            <Typography variant='body2' className='prjover-content'>
                                <Typography variant='body2' className='prjover-content'>Post-deployment statistics demonstrated significant user uptake for WRHI’s intranet portal.</Typography>
                                <Typography variant='body2' className='prjover-content'>They now possessed a robust platform for fostering collaboration and communication within the organization.</Typography>
                            </Typography>
                        </Box>

                    </Box>
                </Container >
            </Box >
            <Box className="Case-Study-List"  >
            {works.length > 0 &&
                    <CustomerStories pageName='caseStudy' workList={works} />
                }
            </Box>
            <CaseStudyLetsGetStarted projectName='Microsoft' />
            {works.length > 0 ?
                <Footer hasWorkItem={false}/>:<Footer hasWorkItem={true}/>
            }
        </>
    );
};

export default SharepointConsultingWRHI1;
