import React, { useEffect, useState } from 'react';
import { Box, Container, List, ListItem, ListItemText, Typography } from '@mui/material';
import openai from '../assets/Images/services/openAI.png';
import microsoftAzure from '../assets/Images/services/microsoftAzure.png';
import ServiceCarousel from '../components/ServiceCarousel';
import CustomerStories from './CustomerStories';
import { workCaseStudy } from '../interface/WorkCaseStudy';
import workJson from "../Work.json";
import Footer from '../components/Footer';
import { serviceWorkFilter } from '../common-functions/serviceWorkFilter';
import { CardData, TabData } from '../interface/TabData';
import ServiceCardSection from '../sub-sections/ServiceCommonComponent/ServiceCardSection';
import ServiceTabbingSection from '../sub-sections/ServiceCommonComponent/ServiceTabbingSection';
import CaseStudyLetsGetStarted from '../sub-sections/CaseStudy/CaseStudyLetsGetStarted';
import useMetaTags from '../common-functions/useMetaTags';

const ArtificialIntelligenceSection: React.FC = () => {
    useMetaTags(
        "Artificial Intelligence Services | AI Consulting & Development",
        "Transform your business with AI-driven solutions. We specialize in custom AI development, machine learning, NLP, and automation to enhance efficiency and decision-making.",
        "AI development services, artificial intelligence consulting, computer vision, natural language processing, AI chatbot development, predictive AI, AI-powered analytics, neural networks, AI innovation"
    );
    const tabData: TabData[] = [
        
        {
            heading: (<>Microsoft Azure AI</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/ai/azureai.png" alt="azure-ai" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;">
           `,
            content: (<>
                <p>Harness the capabilities of Microsoft Azure AI to develop smart, scalable, and data-driven applications. Its advanced machine learning models and cloud infrastructure enable organizations to optimize processes and drive innovation.</p>
                <p>Our team specializes in leveraging Azure AI’s cognitive services, deep learning models, and automation tools to build intelligent applications. From speech-to-text conversion to AI-powered insights, we deliver transformative solutions.</p>
                <p><a href="/work" onClick={() => selectedTechnology('Machine Learning, AI & React')} className="underlineancor">Explore our AI-driven projects</a>.</p>
            </>),
        },
        {
            heading: (<>Microsoft Copilot</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/ai/copilot.png" alt="ms-copilot" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;">
           `,
            content: (<>
                <p>Enhance productivity and decision-making with Microsoft Co-Pilot, an AI-powered assistant designed to streamline workflows and automate complex tasks. Co-Pilot seamlessly integrates with Microsoft 365, enabling users to boost efficiency through intelligent suggestions and real-time insights.</p>
                <p>Our expertise in Microsoft Co-Pilot ensures that businesses leverage AI-driven automation, personalized recommendations, and advanced analytics to enhance collaboration and operational effectiveness.</p>
                <p><a href="/work" onClick={() => selectedTechnology('Machine Learning, AI & React')} className="underlineancor">See how we implement AI assistance</a>.</p>
            </>),
        },
        {
            heading: (<>OpenAI</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/ai/openai.png" alt="open-ai" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;">
           `,
            content: (<>
                <p>OpenAI sets new standards in artificial intelligence, offering revolutionary tools for natural language understanding, automation, and intelligent decision-making. Its generative models redefine user experiences and business efficiency.</p>
                <p>We integrate OpenAI’s advanced models into customized enterprise solutions, including AI-driven chatbots, predictive analytics, and workflow automation, ensuring smarter decision-making and enhanced efficiency.</p>
                <p><a href="/work" onClick={() => selectedTechnology('Machine Learning, AI & React')} className="underlineancor">Discover our AI innovations</a>.</p>
            </>),
        },
        {
            heading: (<>Amazon Web Services (AWS) AI</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/ai/awsai.png" alt="aws-ai" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;">
           `,
            content: (<>
                <p>Transform AI adoption with AWS AI, offering a comprehensive suite of cloud-based machine learning solutions. Its powerful tools like SageMaker, Rekognition, and Polly empower businesses to enhance automation and insights.</p>
                <p>Our expertise in AWS AI helps organizations implement machine learning models for predictive analytics, image recognition, and voice processing, driving efficiency and innovation across industries.</p>
                <p><a href="/work" onClick={() => selectedTechnology('Machine Learning, AI & React')} className="underlineancor">See our AI implementations</a>.</p>
            </>),
        },
        {
            heading: (<>Google Cloud AI</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/ai/googleai.png" alt="google-ai" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;">
           `,
            content: (<>
                <p>Google Cloud AI provides a robust ecosystem of AI-driven solutions, from TensorFlow-powered machine learning to AutoML and Vertex AI, enabling businesses to harness AI for better decision-making.</p>
                <p>We specialize in implementing AI solutions tailored to unique business needs, optimizing workflows, improving customer interactions, and driving operational excellence through Google’s AI innovations.</p>
                <p><a href="/work" onClick={() => selectedTechnology('Machine Learning, AI & React')} className="underlineancor">Explore our AI expertise</a>.</p>
            </>),
        }
    ];
    

    const cardsData: CardData[] = [
        {
            heading: 'Cognitive AI & Decision Intelligence',
            items: [
                'AI-Driven Business Strategy Optimization',
                'Real-Time Predictive Analytics & Insights',
                'Automated Data Processing & Knowledge Mining',
                'AI-Powered Workflow & Process Automation',
                'Adaptive AI for Personalized User Experience',
                'AI-Augmented Decision-Making Systems',
                'Cognitive Computing for Complex Problem Solving',
            ],
        },
        {
            heading: 'AI-Enabled Customer Experience',
            items: [
                'Conversational AI & Smart Chatbots',
                'Voice & Speech Recognition Intelligence',
                'AI-Driven Sentiment & Behavior Analysis',
                'Automated Customer Support & Engagement',
                'Hyper-Personalization with AI Algorithms',
                'Intelligent Virtual Assistants & Agents',
                'AI-Powered Real-Time Customer Insights',
            ],
        },
        {
            heading: 'AI for Intelligent Automation',
            items: [
                'Robotic Process Automation (RPA) with AI',
                'AI-Powered Document & Image Recognition',
                'Automated AI-Driven Cybersecurity Solutions',
                'Intelligent AI-Based Data Labeling & Annotation',
                'AI-Powered Compliance & Risk Assessment',
                'Autonomous AI-Driven Business Workflows',
                'AI-Optimized Resource & Task Scheduling',
            ],
        },
        {
            heading: 'AI in Innovation & Research',
            items: [
                'Generative AI for Creative Content & Design',
                'AI-Driven Scientific Research & Simulations',
                'AI-Powered Drug Discovery & Genomics',
                'Intelligent AI Systems for Market Predictions',
                'AI-Augmented Data Visualization & Reports',
                'Autonomous AI in Robotics & Edge Computing',
                'Explainable AI & Ethical AI Development',
            ],
        },
    ];


    const selectedTechnology = (technology: string) => {
        sessionStorage.setItem("Selected-Vertical", 'All');
        sessionStorage.setItem("Selected-Technology", technology);
    }

    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const keywords = ['ai', 'artificialintelligence', 'machinelearning'];
        const additionalKeywords = ['automation', 'deeplearning', 'datascience', 'microsoft'];
        const results = serviceWorkFilter(workJson.workCaseStudy, keywords, additionalKeywords);
        setWorks(results);
    }, []);
    return (
        <>
            <Box sx={{ padding: 4, textAlign: 'center' }} className="contentbox whitebox">
                <Container className="innerpagecontainer contentpage">
                    <Typography variant="h2" gutterBottom className='contentwidth to_build'>
                        Elevate business potential with AI-driven intelligence, seamless automation, and data-powered decision-making for efficiency
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: 2 }} className='contentwidth'>
                        Unlock possibilities with AI innovation, automation, and data-driven strategies to enhance efficiency and growth
                    </Typography>
                    <div className='servicesLogo'>
                        <ul>
                            <li><span> <img
                            src={openai}
                            alt="Open AI"
                        /></span></li>
                        <li><span> <img
                            src={microsoftAzure}
                            alt="Microsoft Azure"
                        /></span></li>
                        </ul>
                    </div>
                </Container>
            </Box>
            <Box className="outerbox powerbitab" sx={{ bgcolor: "#f2c811" }}  >
                <ServiceTabbingSection tabData={tabData} />
            </Box>
            <Box sx={{ padding: 4 }} className="contentbox whitebox contentpage"  >
                <Container className="innerpagecontainer contentpage" >
                    <ServiceCardSection cardsData={cardsData} verticalTitle='Redefining industries with intelligent AI solutions driving efficiency innovation and data-powered growth' />
                </Container>
            </Box>
            {works.length > 0 &&
                <CustomerStories pageName='AI & React' workList={works} />
            }
            <CaseStudyLetsGetStarted projectName='Artificial Intelligence Service' />
            {works.length > 0 ?
                <Footer hasWorkItem={false} /> : <Footer hasWorkItem={true} />
            }
        </>
    );
};

export default ArtificialIntelligenceSection;

