import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import ImageSlider from './ImageSlider';
import CaseStudyLetsGetStarted from './CaseStudyLetsGetStarted';
import HeaderSection from '../../sections/HeaderSection';
import { Link } from 'react-router-dom';
import CustomerStories from '../../sections/CustomerStories';
import { workCaseStudy } from '../../interface/WorkCaseStudy';
import workJson from "../../Work.json";
import HeadImg from '../../assets/Images/common/career.jpg';
import Footer from '../../components/Footer';
import { caseStudyWorkFilter } from '../../common-functions/caseStudyWorkFilter';
import useMetaTags from '../../common-functions/useMetaTags';

const SharepointConsultingBlythedale2: React.FC = () => {
    useMetaTags(
        "SharePoint Solutions | Case Study - Patient Tracking System",
        "",
        ""
      );
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const additionalKeywords = ['sharepoint','microsoft','office','react','angular','ai','machinelearning'];
        const results = caseStudyWorkFilter(workJson.workCaseStudy,additionalKeywords,'healthcare','sharepoint-consulting-ny-usa-blythedale-2');
        setWorks(results);
    }, []);
    const sliderImages = [
        '/assets/Images/CaseStudy/sharepoint-consulting-ny-usa-blythedale-2/kki-1.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-ny-usa-blythedale-2/2.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-ny-usa-blythedale-2/3.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-ny-usa-blythedale-2/4.png',
    ];
    return (
        <>
            <HeaderSection pageName='Case Study'  imageUrl={HeadImg} alt="Background Image"  />
            <Box className=" whitebox contentbox" id="Project-Overview" sx={{ padding: 4}} >
                <Container className="contentpage">
                    <Box className="casestudy_container">
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} className="compnay_detail_div">
                            <Box flex={1} className="company_logo" sx={{ textAlign: 'center', margin: 5 }}>
                                <img src='/assets/Images/work/blythedale.jpg' alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                            </Box>

                            <Box flex={2} p={2} className="company_detail">
                                <Typography variant="h5" className='cmp_dtl_title'>Blythedale children’s hospital</Typography>
                                <Box mt={2} className="cmpdtl_fullbox" >
                                <div className="companydetails" >    <ul><li> <b>Location:</b></li><li><span>NY, USA</span></li></ul>
                                    <ul><li> <b>Industry:</b></li><li><span>Healthcare</span></li></ul>
                                    <ul><li> <b>Environment:</b></li><li><span>SharePoint 2010</span> </li></ul>
                                    <ul><li> <b>Skills:</b></li><li><span>Kendo Controls, SSRS, Visual Studio 2012, SQL Server 2012</span></li></ul>
                                    <ul><li> <b>Project(s):</b></li><li> <a href='/case-study/sharepoint-consulting-ny-usa-blythedale-1'><span>Business Intelligence Dashboards</span></a> <i className='orangseperator'>|</i> <a className='active'><span>Patient Tracking System</span></a></li></ul></div>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Container>
                <Container className="innerpagecontainer contentpage prj_overview_fulldiv">
                    {/* {caseStudy?.clientSlogan != null ?  */}
                    <Typography variant="h2" gutterBottom className='prjover-main-title'>
                        Kennedy Krieger Institute Implements Patient Tracking System built as a custom solution on SharePoint Platform
                    </Typography>
                    {/* : null} */}

                    <Box display="flex" flexDirection={{ xs: 'column', md: "row" }} >
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'column' }} className="prj_overview_div">
                            {/* About the Client */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">About the Client</Typography>
                                    <Typography variant='body2' className='prjover-content'><a href="https://www.kennedykrieger.org/">Kennedy Krieger Institute</a> is an internationally recognized organization based out of Baltimore, MD. It is dedicated to improving the lives of individuals with disorders of the brain, spinal cord, and musculoskeletal system through Patient Care, Special Education, Community Initiatives and research &amp; Professional Training.</Typography>
                            </Box>
                            {/* The Challenge */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">The Challenge</Typography>
                                    <Typography variant='body2' className='prjover-content'>
                                        Kennedy Krieger Institute was looking to replace their existing system with a SharePoint intranet portal. Their key requirements included appointment tracking for patients, patient transfers to various internal therapy departments, and data accessibility across various departments.
                                    </Typography>
                            </Box>

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">Objectives</Typography>
                                    <Typography variant='body2' className='prjover-content'>Our solution for KKI had to achieve the following –</Typography>
                                <Typography variant='body2' className='prjover-content'>
                                    <ul>
                                        <li style={{ paddingLeft: '30px' }}>- Staff Management dashboard</li>
                                        <li style={{ paddingLeft: '30px' }}>- Patient registration application</li>
                                        <li style={{ paddingLeft: '30px' }}>
                                            - Assigning treatments by therapy manager according to specific time slots
                                        </li>
                                        <li style={{ paddingLeft: '30px' }}>
                                            - Application to manage internal transfers to various treatment departments
                                        </li>
                                        <li style={{ paddingLeft: '30px' }}>
                                            - Modalities reports for keeping the best experience of patients.
                                        </li>
                                        <li style={{ paddingLeft: '30px' }}>
                                            - Email reminders to patients about their upcoming treatment appointments
                                        </li>
                                        <li style={{ paddingLeft: '30px' }}>- Search enhancements across the application.</li>
                                    </ul>
                                </Typography>
                            </Box>

                        </Box>
                        <Box display="flex" flexDirection={{ xs: 'column' }} >
                            {/* Image Slider Section */}
                            <Box className="prj_scrnshot" textAlign="center">
                                <ImageSlider imageList={sliderImages || []} />

                            </Box>
                            {/* Client Quote */}
                            <Box className="prj_client_quote" textAlign="center" mt={4}>
                                <Typography variant="body2" component="blockquote" className="client_quote_w quote_rounded" >
                                    <span className="left">“</span><Typography variant='body2' className='prjover-content'><Typography variant='body2' className='prjover-content'>They offer high quality software solutions and services, their agile approach to development and resources make them valuable in a market that is changing by the minute. The team is excellent and very customer focused!</Typography></Typography><span className="right">”</span>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    {/* Solution and Benefits */}
                    <Box className="prj_overview_fulldiv">

                        {/* The Solution */}

                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>The Solution</Typography>
                                <Typography variant='body2' className='prjover-content'>KKI’s existing SharePoint intranet was leveraged to build a solution on top of it. We used Kendo UI to develop a custom SharePoint solution using MS Visual Studio 2013 to meet the above mentioned objectives.</Typography>
                                <Typography variant='body2' className='prjover-content'>This custom solution contained various dashboards and web services directly interacting with SQL Server, the back-end server used as data source. The web services would directly call the custom SQL stored procedures developed to fetch and represent the real-time data.</Typography>
                                <Typography variant='body2' className='prjover-content'>A dashboard for hospital staff was developed, where the Care Center staff, Physical Therapist and Therapy Managers were able to access the portal.</Typography>
                                <Typography variant='body2' className='prjover-content'>To track patients, a dashboard was developed using MS Visual Studio 2013, where the records were represented graphically in different stages like referrals, evaluations (awaiting evaluations, scheduled evaluation), treatment, etc.</Typography>
                                <Typography variant='body2' className='prjover-content'>The therapy manager could add, retrieve and update the details of the patients, as per their process for the visual data as reviewed through the dashboard.</Typography>
                                <Typography variant='body2' className='prjover-content'>For the new patient, they were notified and evaluated in stages as scheduled by the Therapy manager. In all the stages, the respective staff, Care Center staff and the Therapists, are notified through mail.</Typography>
                                <Typography variant='body2' className='prjover-content'>Along with this the details of modalities used by the therapist was also a part of the patient tracking system. Condition of the patients were documented and stored after every session. This would help the therapists understand treatment history and how the patient responds to specific treatments, even if the therapist changes, or has to go-through of the entire case at one-go.</Typography>
                                <Typography variant='body2' className='prjover-content'>There were various reports like outcomes, modality and diagnosis report created for the managers would receive the patients. A follow-up reports is also generated for the patient after the relieving formalities is over. Archiving feature was provided to help the staff to manage the records efficiently.</Typography>
                                <Typography variant='body2' className='prjover-content'>Search functionality was also developed to help the staff get all the details of the patient, with either KKI Number or Name as filters. This would help the staff during emergency and have all the information handy.</Typography>
                            
                        </Box>
                        {/* Benefits */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>Benefits</Typography>
                            <Typography variant='body2' className='prjover-content'>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>
                                    - An end-to-end system for patient tracking that completes an entire patient tracking flow - right from the appointment scheduling of a patient, to getting discharged and the follow-ups thereafter.
                                </Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>
                                    - A central location for all patient-related information and ease of accessibility through improved search functionality.
                                </Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>
                                    - Managing patients according to their allocated time slots saved a lot of time for KKI and also helped in pre-planning the arrangements for the patients.
                                </Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>
                                    - All the therapy course allotment and transfers became a one-click solution.
                                </Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>
                                    - Patients were aware of the treatments they were undergoing through email communication.
                                </Typography>
                            </Typography>
                        </Box>

                    </Box>
                </Container >
            </Box >
                <Box id="Case-Study-List">
                    {/* Title */}
                    {works.length > 0 &&
                    <CustomerStories pageName='caseStudy' workList={works} />
                    }
                </Box>
            <CaseStudyLetsGetStarted projectName='Patient Tracking System' />
            {works.length > 0 ?
                <Footer hasWorkItem={false}/>:<Footer hasWorkItem={true}/>
            }
        </>
    );
};

export default SharepointConsultingBlythedale2;
