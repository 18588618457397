// src/pages/HomePage.tsx
import React, { useCallback, useEffect, useState } from 'react';
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Typography,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    SelectChangeEvent,
    Stack,
    TableSortLabel,
    Backdrop,
} from "@mui/material";
import { fetchShowCase } from '../services/ApiService';
import ExportExcel from '../sub-sections/Common/ExportExcel';

interface RowData {
    id: string;
    created: string;
    projectName: string;
    companyName: string;
    name: string;
    email: string;
    phone: string;
}

const ShowcaseRequest: React.FC = () => {
    const [month, setMonth] = useState(0);
    const [year, setYear] = useState(0);
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
    const [sortColumn, setSortColumn] = useState<keyof RowData | null>(null);
    const [userRole, setUserRole] = useState("admin");
    const months = [
        { value: 1, label: "January" },
        { value: 2, label: "February" },
        { value: 3, label: "March" },
        { value: 4, label: "April" },
        { value: 5, label: "May" },
        { value: 6, label: "June" },
        { value: 7, label: "July" },
        { value: 8, label: "August" },
        { value: 9, label: "September" },
        { value: 10, label: "October" },
        { value: 11, label: "November" },
        { value: 12, label: "December" }
    ];
    const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i);

    const fetchData = async () => {
        try {
            const response = await fetchShowCase(month, year);
            setData(response); // Set the fetched data to state
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        fetchData();
    }, [month, year]);
    useEffect(() => {
        if (data.length > 0) { setLoading(false); }
        else {
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        }
    }, [data])

    const handleMonthChange = (event: SelectChangeEvent<number>) => {
        setMonth(event.target.value as number);
    };

    const handleYearChange = (event: SelectChangeEvent<number>) => {
        setYear(event.target.value as number);
    };
    const formatDate = (dateString: string | Date): string => {
        const date = new Date(dateString);
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };
    const handleSort = (column: keyof RowData) => {
        const isAsc = sortColumn === column && sortOrder === "asc";
        setSortOrder(isAsc ? "desc" : "asc");
        setSortColumn(column);

        const sortedData = [...data].sort((a, b) => {
            if (a[column] < b[column]) return isAsc ? 1 : -1;
            if (a[column] > b[column]) return isAsc ? -1 : 1;
            return 0;
        });
        setData(sortedData);
    };
    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, marginBottom: 2 }}>
                <Backdrop
                    sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Stack direction="row" alignItems="center">
                    <InputLabel htmlFor="Month">Month</InputLabel>
                    <FormControl variant="outlined" sx={{ m: 1, minWidth: 220 }}>
                        <Select
                            labelId="month-label"
                            value={month}
                            onChange={handleMonthChange} // Pass the reference directly
                            className="filter-dropdown"
                        >
                            <MenuItem value="0">All</MenuItem>
                            {months.map((m) => (
                                <MenuItem key={m.value} value={m.value}>
                                    {m.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>

                <Stack direction="row" alignItems="center">
                    <InputLabel htmlFor="Year">Year</InputLabel>
                    <FormControl variant="outlined" sx={{ m: 1, minWidth: 220 }}>
                        <Select
                            labelId="year-label"
                            value={year}
                            onChange={handleYearChange}
                            className="filter-dropdown"
                        >
                            <MenuItem value="0">All</MenuItem>
                            {years.map((y) => (
                                <MenuItem key={y} value={y}>
                                    {y}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction="row" alignItems="center">
                    <ExportExcel month={month} year={year} type={'showcase'} /></Stack>
            </Box>

            {data.length > 0 ? (
                <>
                    <TableContainer component={Paper} className="gridtable">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {["created", "projectName", "companyName", "name", "email", "phone"].map((column) => (
                                        <TableCell key={column}>
                                            <TableSortLabel
                                                active={sortColumn === column}
                                                direction={sortColumn === column ? sortOrder : "asc"}
                                                onClick={() => handleSort(column as keyof RowData)}
                                            >
                                                {column === 'created' ? 'Date' : column === 'projectName' ? 'Project Name' : column === 'companyName' ? 'Company Name' : column.charAt(0).toUpperCase() + column.slice(1)}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{formatDate(row.created)}</TableCell>
                                        <TableCell>{row.projectName}</TableCell>
                                        <TableCell>{row.companyName}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>{row.phone}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) : loading ? <></> : (
                <Typography align="center" variant="h6" color="textSecondary">
                    No Records Found
                </Typography>
            )}
        </div>
    );
};

export default ShowcaseRequest;
