import React from 'react';
import Grid2 from '@mui/material/Grid2';
import { Card, CardContent, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Event } from '../../interface/Event';

const EventCard: React.FC<{event:Event}> = ({event}) => {
    const renderEventDate = (eventDate: Date) => {
        const date = new Date(eventDate);
    
        // Extract the day, month (short format), and year
        const day = date.getDate();
        const month = date.toLocaleString('en-US', { month: 'short' }); // Example: "Nov"
        const year = date.getFullYear();
    
        return (
          <div className="spe_date_grop">
            <Typography variant="body2" className="spedate">
              {day}
            </Typography>
            <div className="spe_monthyear">
              <Typography variant="body2" className="speMonth">
                {month}
              </Typography>
              <Typography variant="body2" className="speyear">
                {year}
              </Typography>
            </div>
          </div>
        );
      };
    return (
        <Grid2 size={{xs:12, sm:6 , md:4 , lg:4}} > {/* Adjusted for responsiveness */}
            <Card sx={{ backgroundColor: '#363636', color: 'white', height: 300 }}>
                <CardContent>
                    {renderEventDate(event.eventDate)}
                    {/* <Typography variant="h4">{new Date(event.eventDate).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}</Typography> */}
                    <Typography variant="body1">{event.eventSortLocation == null ? event.eventLocation : event.eventSortLocation}</Typography>
                    <hr />
                    <Typography variant="body2">{event.eventTitle}</Typography>
                    <Typography sx={{ marginBottom: 2 }} variant="body2"  className="eventDescription">{event.eventDescription}</Typography>
                    <Link to={event.eventLink} style={{ color: 'white' }}>View More</Link>
                </CardContent>
            </Card>
        </Grid2>
    );
};

export default EventCard;
