import React, { useState, useRef } from 'react';
import Carousel from 'react-multi-carousel';
import { Box, Dialog, DialogContent, IconButton } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import 'react-multi-carousel/lib/styles.css';
import { ImageSliderLeftArrow, ImageSliderRightArrow } from '../../components/CustomArrows';
interface ImageSliderProps {
  imageList: string[];
}
const ImageSlider: React.FC<ImageSliderProps> = ({ imageList }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [open, setOpen] = useState(false); // To control opening/closing of the lightbox

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + imageList.length) % imageList.length);
  };
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageList.length);
  };
  const handleOpen = (index: number) => {
    setCurrentIndex(index); // Set the clicked image as the current image
    setOpen(true); // Open the lightbox
  };
  const handleClose = () => {
    setOpen(false); // Close the lightbox
  };

  const carouselRef = useRef<HTMLDivElement | null>(null);  // Reference to the carousel for displying  of arrow

  const handleMouseMove = (event: React.MouseEvent) => {
    if (imageList.length > 1) {
      const dialogRect = event.currentTarget.getBoundingClientRect();
      const cursorPosition = event.clientX - dialogRect.left;
      // Check if the cursor is on the left or right side of the dialog
      if (cursorPosition < dialogRect.width / 2) {
        setShowLeftArrow(true);   // Show left arrow
        setShowRightArrow(false); // Hide right arrow
      } else {
        setShowRightArrow(true);  // Show right arrow
        setShowLeftArrow(false);  // Hide left arrow
      }
    }
    else {
      setShowLeftArrow(false);
      setShowRightArrow(false);
    }
  };
  const handleMouseLeave = () => {
    setShowLeftArrow(false);
    setShowRightArrow(false);
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <>
      <div style={{ position: 'relative' }}>
        <Carousel
          swipeable={false}
          draggable={false}
          responsive={responsive}
          infinite={imageList.length > 1 ? true : false}
          autoPlay={imageList.length > 1 ? true : false}
          autoPlaySpeed={3000}
          transitionDuration={0}
          customLeftArrow={
            <></>
          }
          customRightArrow={<></>}
        >
          {imageList.map((img, index) => (
            <div key={index}>
              <img
                src={img}
                alt={`Slide ${index + 1}`}
                style={{
                  minHeight: '160px',
                  objectFit: 'contain',
                  width: '100%'
                }}
                onClick={() => handleOpen(index)}
              />
            </div>
          ))}
        </Carousel>
      </div>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        style={{ overflow: "hidden" }}
        className='case-study-dialogOverflowNone imagedialog'
      >
        <IconButton onClick={handleClose} className="popupclosebtn"
          sx={{
            position: 'absolute',
            color: 'white',
            bgcolor: 'black',
            top: '-16px',
            right: '-19px',
            zIndex: 3,
            padding: '0'
          }}>
          <HighlightOffIcon fontSize="medium" />
        </IconButton>
        <DialogContent sx={{ position: "relative", textAlign: "center", padding: '0' }}
          ref={carouselRef}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        >
          <Box
            component="img"
            src={imageList[currentIndex]} // Load only the current image
            alt={`Image ${currentIndex + 1}`}
            sx={{ width: "100%", objectFit: "fill" }}
          />
          {/* Navigation Controls */}
          {showLeftArrow ? <ImageSliderLeftArrow onClick={handlePrev} /> : <></>}

          {showRightArrow ? <ImageSliderRightArrow onClick={handleNext} /> : <></>}
        </DialogContent>

      </Dialog>


    </>
  );
};

export default ImageSlider;

