import React, { useEffect, useState } from 'react';
import { Box, Container, List, ListItem, ListItemText, Typography } from '@mui/material';
import customAppDev from '../assets/Images/services/custom_application_development.png';
import ServiceCarousel from '../components/ServiceCarousel';
import Dotnet from '../assets/Images/services/dotnet.png';
import Angular from '../assets/Images/services/angular.png';
import ReactLogo from '../assets/Images/services/react.png';
import CustomerStories from './CustomerStories';
import { workCaseStudy } from '../interface/WorkCaseStudy';
import workJson from "../../src/Work.json";
import Footer from '../components/Footer';
import { serviceWorkFilter } from '../common-functions/serviceWorkFilter';
import { CardData, TabData } from '../interface/TabData';
import ServiceTabbingSection from '../sub-sections/ServiceCommonComponent/ServiceTabbingSection';
import ServiceCardSection from '../sub-sections/ServiceCommonComponent/ServiceCardSection';
import CaseStudyLetsGetStarted from '../sub-sections/CaseStudy/CaseStudyLetsGetStarted';
import useMetaTags from '../common-functions/useMetaTags';

const CustomApplicationDevelopmentSection: React.FC = () => {
    useMetaTags(
        "Custom Application Development Services | Scalable & Secure Solutions",
        "Build robust, future-ready applications with our custom application development services. From strategy to deployment, we create tailored solutions to optimize business performance.",
        "Custom app development services, AI-powered applications, mobile and web solutions, secure software development, digital transformation solutions"
      );
    const tabData: TabData[] = [
        {
            heading: (<>.NET Development</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/services/softwareDevelopment/dotnet-development.png" alt="dotnet-development" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;">
            `,
            content: (<>
                <p>Delivering secure and scalable enterprise applications, Binary Republik leverages .NET and .NET Core to build high-performance solutions for web, desktop, and cloud environments. With a robust MVC architecture and API-driven approach, our applications ensure efficiency and maintainability.</p>
                <p>We integrate Azure Cloud, SQL Server, and Microservices architecture to enhance scalability and performance. With support for Blazor, ASP.NET, and Entity Framework, our .NET solutions provide seamless integration, high security, and enterprise-level capabilities for businesses of all sizes.</p>
                <p>Discover how our .NET solutions drive innovation by exploring <a href="/work" onClick={() => selectedTechnology('Application Development,ASP.NET,Angular,React,Web app')} className="underlineancor">our projects</a>.</p>
            </>),
        },
        {
            heading: (<>React Development</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/services/softwareDevelopment/react-development.png" alt="react-development" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;">
            `,
            content: (<>
                <p>Building highly responsive and interactive web applications, Binary Republik utilizes React to create scalable and component-based UIs. Our React-based solutions ensure fast rendering, seamless user experience, and modern frontend capabilities.</p>
                <p>With expertise in Next.js for server-side rendering, Redux for state management, and API integrations, we deliver efficient, high-performance applications. React’s flexibility allows us to build progressive web apps, dashboards, and enterprise portals with a modern and user-centric approach.</p>
                <p>See how our React expertise transforms web experiences by exploring <a href="/work" onClick={() => selectedTechnology('Application Development,ASP.NET,Angular,React,Web app')} className="underlineancor">our case studies</a>.</p>
            </>),
        },
        {
            heading: (<>Angular Development</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/services/softwareDevelopment/angular-development.png" alt="angular-development" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;">
            `,
            content: (<>
                <p>For businesses needing enterprise-grade, scalable applications, Binary Republik provides Angular development services that ensure structured architecture and maintainability. Our Angular solutions are built with TypeScript, RxJS, and Angular CLI, enabling robust development.</p>
                <p>We implement dynamic UI components, REST API integration, and modular architecture to build secure, high-performance web applications. With progressive web app (PWA) capabilities and advanced dependency injection, our Angular solutions support large-scale business applications efficiently.</p>
                <p>Explore how our Angular solutions deliver excellence by checking out <a href="/work" onClick={() => selectedTechnology('Application Development,ASP.NET,Angular,React,Web app')} className="underlineancor">our recent projects</a>.</p>
            </>),
        },
        {
            heading: (<>Python Development</>),
            imgSrc: `
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/services/softwareDevelopment/python-development.png" alt="python-development" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;">
            `,
            content: (<>
                <p>With a focus on AI, automation, and data-driven applications, Binary Republik delivers Python-based solutions that power machine learning models, web applications, and enterprise software. Our expertise includes Django, Flask, FastAPI, and AI-driven development.</p>
                <p>We build data analytics platforms, automation tools, and AI-driven applications using TensorFlow, PyTorch, and Pandas. Python’s versatility enables us to create secure, scalable, and high-performance solutions tailored for business intelligence, automation, and backend services.</p>
                <p>Discover how our Python expertise enables AI-driven innovation by exploring <a href="/work" onClick={() => selectedTechnology('Application Development,ASP.NET,Angular,React,Web app')} className="underlineancor">our portfolio</a>.</p>
            </>),
        }
    ];
    
    const cardsData: CardData[] = [
        {
            heading: 'Bespoke Software Development',
            items: [
                'Tailor-Made Solutions for Unique Business Needs',
                'Scalable & Future-Proof Architecture',
                'AI-Driven Process Automation',
                'Cross-Platform & Native Development',
                'Seamless Third-Party API Integrations',
                'Agile Development & Rapid Prototyping',
                'Enterprise-Grade Security & Compliance'
            ],
        },
        {
            heading: 'Web & Mobile App Development',
            items: [
                'Custom Web & Mobile Applications',
                'Native iOS & Android Development',
                'Cross-Platform Solutions with React Native & Flutter',
                'Intuitive UI/UX for Enhanced User Experience',
                'Cloud-Integrated & Scalable Infrastructure',
                'Real-Time Data Synchronization & Offline Support',
                'Performance Optimization & Continuous Support'
            ],
        },
        {
            heading: 'Enterprise Application Solutions',
            items: [
                'Robust ERP & CRM System Development',
                'AI & Data-Driven Business Intelligence',
                'Scalable Microservices Architecture',
                'Seamless API & Database Integrations',
                'Multi-Tenant & SaaS-Based Applications',
                'Advanced Role-Based Access Controls',
                'Continuous Monitoring & Performance Enhancement'
            ],
        },
        {
            heading: 'Custom API & Backend Development',
            items: [
                'High-Performance REST & GraphQL APIs',
                'Secure Authentication & Data Encryption',
                'Microservices & Serverless Architecture',
                'Seamless Integration with Third-Party Services',
                'Automated Scaling & Load Balancing',
                'Efficient Data Storage & Retrieval Solutions',
                'Comprehensive API Documentation & Support'
            ],
        },
    ];

    const selectedTechnology = (technology: string) => {
        sessionStorage.setItem("Selected-Vertical", 'All');
        sessionStorage.setItem("Selected-Technology", technology);
    }
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const keywords = ['custom application development', 'application development', 'application'];
        const additionalKeywords = ['technology', 'microsoft'];
        const results = serviceWorkFilter(workJson.workCaseStudy, keywords, additionalKeywords);
        setWorks(results);
    }, []);
    return (
        <>
            <Box sx={{ padding: 4, textAlign: 'center' }} className="contentbox whitebox " >
                <Container className="innerpagecontainer contentpage">
                    <Typography variant="h2" gutterBottom className='contentwidth to_build'>
                    Crafting bespoke applications from the ground up, we deliver secure, scalable, and high-performance software solutions</Typography>

                    <Typography variant="body1" sx={{ marginBottom: 2 }} className='contentwidth'>
                    Transforming your vision into reality, we build cutting-edge applications with seamless performance, security, and scalability</Typography>

                    
                    <div className='servicesLogo'>
                        <ul>
                            <li><span><img src={Dotnet}  alt="Dotnet"/></span></li>
                            <li><span><img src={Angular}  alt="Angular"/></span></li>
                            <li><span><img src={ReactLogo}  alt="React"/></span></li>
                        </ul>
                    </div>
                </Container>
            </Box>
            <Box className="outerbox powerbitab" sx={{ bgcolor: "#f2c811" }}  >
                <ServiceTabbingSection tabData={tabData} />
            </Box>
            <Box sx={{ padding: 4 }} className="contentbox whitebox contentpage"  >
                <Container className="innerpagecontainer contentpage" >
                    <ServiceCardSection cardsData={cardsData} verticalTitle='Custom Application Development Tailored for Your Unique Business Needs' />
                </Container>
            </Box>
            {works.length > 0 &&
                <CustomerStories pageName='Application Development' workList={works} />}
                <CaseStudyLetsGetStarted projectName='Custom Application Development Service' />
            {works.length > 0 ?
                <Footer hasWorkItem={false} /> : <Footer hasWorkItem={true} />
            }
        </>
    );
};

export default CustomApplicationDevelopmentSection;
