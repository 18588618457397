// src/pages/HomePage.tsx
import React from 'react';
import HeaderSection from '../sections/HeaderSection';
import ControlPanelHeader from '../sections/ControlPanelHeader';
import ContactRequest from '../sections/ContactRequest';
import ShowcaseRequest from '../sections/ShowcaseRequest';
import SiteVisitors from '../sections/SiteVisitors';
import JobRequest from '../sections/JobRequest';
import { Box } from '@mui/material';
import Login from '../sections/Login';
import HeadImg from '../assets/Images/common/career.jpg';
import Footer from '../components/Footer';

interface Tab{
  tabName:string;
}
const ControlPanel: React.FC<Tab> = ({tabName}) => {
  const renderContent = () => {
    switch (tabName) {
      case "ContactRequest":
        return (
          <>
            <HeaderSection pageName="Contact Request"  imageUrl={HeadImg} alt="Background Image" />
            <Box  className="outerbox controlpanel" sx={{bgcolor:"#fff"}}  >
                 <ControlPanelHeader />
         
            <ContactRequest/></Box>
            <Footer hasWorkItem={false}/>
          </>
        );
      case "JobRequest":
        return (
          <>
            <HeaderSection pageName="Job Request"  imageUrl={HeadImg} alt="Background Image" />
            <Box  className="outerbox contactreq" sx={{bgcolor:"#fff"}}  >
            <ControlPanelHeader />
            <JobRequest/></Box>
            <Footer hasWorkItem={false}/>
          </>
        );
      case "ShowcaseRequest":
        return (
          <>
            <HeaderSection pageName="Showcase Request"  imageUrl={HeadImg} alt="Background Image" />
            <Box  className="outerbox controlpanel" sx={{bgcolor:"#fff"}}  >
            <ControlPanelHeader />
            <ShowcaseRequest/></Box>
            <Footer hasWorkItem={false}/>
          </>
        );
      case "SiteVisitors":
        return (
          <>
            <HeaderSection pageName="Site Visitors"  imageUrl={HeadImg} alt="Background Image" />
            <Box  className="outerbox controlpanel" sx={{bgcolor:"#fff"}}  >
            <ControlPanelHeader />
            <SiteVisitors/></Box>
            <Footer hasWorkItem={false}/>
          </>
        );
      case "Login":
        return (
          <>
            <HeaderSection pageName="Login"  imageUrl={HeadImg} alt="Background Image" />
            <Box  className="outerbox controlpanel" sx={{bgcolor:"#fff"}}  >
            <Login/></Box>
            <Footer hasWorkItem={false}/>
          </>
        );
        
    }
  };
  return <>{renderContent()}</>;
};

export default ControlPanel;
