import React, { useEffect, useState } from 'react';
import { Typography, Button, Grid, Divider, Box, ListItem } from '@mui/material';
import footerLogo from '../assets/svg/BR-ICON.svg';
import facebook from '../assets/svg/facebook.svg';
import twiiter from '../assets/svg/twitter.svg';
import linkdin from '../assets/svg/linkedin.svg';
import insta from '../assets/svg/insta.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
const Footer: React.FC<{ hasWorkItem: boolean }> = ({ hasWorkItem }) => {
  // State to store screen width
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const navigate = useNavigate();
  const location = useLocation();
  const currentYear = new Date().getFullYear();
  // Update screen width on resize
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const updateFilter = () => {
    sessionStorage.setItem("Selected-Vertical", "All");
    sessionStorage.setItem("Selected-Technology", "All");
  }
  // Determine class name based on screen size
  const className = screenWidth < 900 ? 'mobile' : '';

  // State to keep track of the toggle status
  const [toggleState, setToggleState] = useState({
    isOurServiceToggled: false,
    isVerticalToggled: false,
    isTechnologyToggled: false,
  });

  // Function to toggle the state dynamically
  const handleToggle = (param: keyof typeof toggleState) => {
    setToggleState((prevState) => ({
      ...prevState,
      [param]: !prevState[param],
    }));
  };
  //sets selected vertical link in the local storage
  const selectedVertical = (vertical: string) => {
    sessionStorage.setItem("Selected-Vertical", vertical);
    sessionStorage.setItem("Selected-Technology", 'All');
    if (location.pathname === '/work') {
      // Trigger custom action or re-render content
      navigate(0);
    }
  }

  //sets selected technology link in the local storage
  const selectedTechnology = (technology: string) => {
    sessionStorage.setItem("Selected-Vertical", 'All');
    sessionStorage.setItem("Selected-Technology", technology);
    if (location.pathname === '/work') {
      // Trigger custom action or re-render content
      navigate(0);
    }
  }

  const pageReload = () => {
    if (window.location.pathname === '/' && !window.location.hash) {
      window.location.reload(); // Reload the page if already on the home page
    } else {
      window.location.href = '/'; // Redirect to the home page if not on home page
    }
  }
  const handelLogoClick = () => {
    if (location.pathname === '/' && !location.hash) {
      document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      window.location.href = '/'; // Redirect to the home page if not on home page
    }
  }
  const handleClick = () => {
    localStorage.setItem('isServiceClick', 'true');
    if (location.pathname !== "/") {
      navigate(`/#services`); // Navigate to home with hash
    } else {
      window.location.hash = 'services'; // Only update hash if already on home
    }
  }
  const handleProcessClick = () => {
    const processSectionId = 'process';

    if (location.pathname !== "/") {
      navigate(`/#${processSectionId}`);
    } else {
      window.location.hash = processSectionId;
      // Ensure scrolling into view
      const processElement = document.getElementById(processSectionId);
      if (processElement) {
        processElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };
  return (

    <Box id="Footer"
      className="footer dargraybgcolor">
      <Box className="OurFooter">
        <Box className="boxcontent">
          <Box>
            {/* 1. Title and Button */}
            {hasWorkItem == false ? <Box textAlign="center" className="joinourteam">
              <Typography variant="h2" className='jointeamheading'>
                Join our team
              </Typography>
              <Button
                href="/careers"
                variant="contained"
                sx={{
                  backgroundColor: 'red',
                  color: 'white',
                  borderRadius: '25px',
                  padding: '10px 20px',
                  fontWeight: 'bold',
                  textDecoration: 'none',
                  '&:hover': {
                    backgroundColor: 'darkred',
                  },
                }}
              >
                Explore BR careers
              </Button>
              {/* Horizontal Line */}
              <Divider className='graydivider' />
            </Box> : <Box textAlign="center" className="joinourteam">
              <Typography variant="h2" className='jointeamheading'>
                Other Stories
              </Typography>
              <Button
                href="/work"
                variant="contained"
                sx={{
                  backgroundColor: 'red',
                  color: 'white',
                  borderRadius: '25px',
                  padding: '10px 20px',
                  fontWeight: 'bold',
                  textDecoration: 'none',
                  '&:hover': {
                    backgroundColor: 'darkred',
                  },
                }}
              >
                Explore BR works
              </Button>
              {/* Horizontal Line */}
              <Divider className='graydivider' />
            </Box>}

            {/* 2. Service Sections */}
            <Box className="footerlinks">
              <ul>
                <li className='ourservice'>
                  <b className={`FooterLinkTitle ${toggleState.isOurServiceToggled ? "active" : ""}`}
                    onClick={() => handleToggle("isOurServiceToggled")}>
                    Our Services
                  </b><br></br>

                  <div className={className + " " + `${toggleState.isOurServiceToggled ? "show-model" : "hide-model"}`} >
                    <a href="/artificial-intelligence/" title="Our Service">Artificial Intelligence</a><br></br>
                    <a href="/microsoft-power-platform/" title="Our Service">Microsoft Power Platform</a><br></br>
                    <a href="/sharepoint-consulting" title="Our Service"> SharePoint Consulting</a><br></br>
                    <a href="/azure-aws-cloud/" title="Our Service">Azure, AWS Cloud</a><br></br>
                    <a href="/salesforce-consulting/" title="Our Service">Salesforce Consulting</a><br></br>
                    <a href="/custom-application-development/" title="Our Service">Custom Application Development</a><br></br>
                    <a href="/mobile-solutions/" title="Our Service">Mobile Solutions</a><br></br>
                    <a href="/content-management-systems/" title="Our Service">Content Management Systems</a><br></br></div>
                </li>
                <li className="vertilcal">
                  <ul> <li>
                    <b className={`FooterLinkTitle ${toggleState.isVerticalToggled ? 'active' : ''}`} onClick={() => handleToggle('isVerticalToggled')}>
                      Vertical
                    </b><br></br>
                    <div className={className + " " + `${toggleState.isVerticalToggled ? "show-model" : "hide-model"}`}>
                      <a href="/work" onClick={() => selectedVertical("Management Consulting")}>Management Consulting</a><br></br>
                      <a href="/work" onClick={() => selectedVertical("Real Estate")}>Real Estate</a><br></br>
                      <a href="/work" onClick={() => selectedVertical("Engineering")}>Engineering</a><br></br>
                      <a href="/work" onClick={() => selectedVertical("Infrastructure")}>Infrastructure</a><br></br>
                      <a href="/work" onClick={() => selectedVertical("Public Sector")}>Public Sector</a><br></br>
                      <a href="/work" onClick={() => selectedVertical("Healthcare")}>Healthcare</a><br></br>
                      <a href="/work" onClick={() => selectedVertical("Government")}>Government</a><br></br>
                      <a href="/work" onClick={() => selectedVertical("Manufacturing")}>Manufacturing</a><br></br>


                    </div></li>
                    <li className='pt-5vh'>

                      {/* Section 3: Public Sector */}
                      <div className={className + " " + `${toggleState.isVerticalToggled ? "show-model" : "hide-model"}`}>
                        <a href="/work" onClick={() => selectedVertical("Hospitality")}>Hospitality</a><br></br>
                        <a href="/work" onClick={() => selectedVertical("Pharma")}>Pharma</a><br></br>
                        <a href="/work" onClick={() => selectedVertical("Media Group")}>Media</a><br></br>
                        <a href="/work" onClick={() => selectedVertical("Education")}>Education</a><br></br>
                        <a href="/work" onClick={() => selectedVertical("Finance")}>Finance</a><br></br>
                        <a href="/work" onClick={() => selectedVertical("Marketing")}>Marketing</a><br></br>
                        <a href="/work" onClick={() => selectedVertical("Energy")}>Energy</a><br></br>
                        <a href="/work" onClick={() => selectedVertical("Others")}>Others</a><br></br>

                      </div>
                    </li>
                    <li className='pt-5vh'>

                      {/* Section 3: Public Sector */}
                      <div className={className + " " + `${toggleState.isVerticalToggled ? "show-model" : "hide-model"}`}>
                        <a href="/work" onClick={() => selectedVertical("NGO")}>NGO</a><br></br>
                        <a href="/work" onClick={() => selectedVertical("IT")}>IT</a><br></br>
                        <a href="/work" onClick={() => selectedVertical("BFSI")}>BFSI</a><br></br>

                      </div>
                    </li>
                  </ul>
                </li>

                <li className='technology'>
                  <ul><li >
                    <b className={`FooterLinkTitle ${toggleState.isTechnologyToggled ? 'active' : ''}`}
                      onClick={() => handleToggle('isTechnologyToggled')}>
                      Technology
                    </b><br></br>
                    <div className={className + " " + `${toggleState.isTechnologyToggled ? "show-model" : "hide-model"}`}>
                      <a href="/work" onClick={() => selectedTechnology('SharePoint 2016,MOSS 2007,SharePoint 2010,SharePoint 2013,SharePoint 2019')}>SharePoint On-Premise</a><br></br>
                      <a href="/work" onClick={() => selectedTechnology('Office 365 - SharePoint Online')}>Office 365 - SharePoint Online</a><br></br>
                      <a href="/work" onClick={() => selectedTechnology('ServiceNow')}>ServiceNow</a><br></br>
                      <a href="/work" onClick={() => selectedTechnology('Mobile App')}>Mobile App</a><br></br>

                      <a href="/work" onClick={() => selectedTechnology('Machine Learning, AI & React')}> Machine Learning, AI & React </a><br></br>
                      <a href="/work" onClick={() => selectedTechnology('Power BI,Power Apps,Power Automate')}>Power Platform</a><br></br>
                      <a href="/work" onClick={() => selectedTechnology('Project Server')}>Project Server</a><br></br>
                      <a href="/work" onClick={() => selectedTechnology('Microsoft Exchange')}>Microsoft Exchange</a><br></br>


                    </div>
                  </li>
                    <li className='pt-5vh'>
                      <div className={className + " " + `${toggleState.isTechnologyToggled ? "show-model" : "hide-model"}`}>
                        <a href="/work" onClick={() => selectedTechnology('Application Development,ASP.NET,Angular,React,Web app')}>FullStack</a><br></br>
                        <a href="/work" onClick={() => selectedTechnology('Salesforce')}>Salesforce</a><br></br>

                        <a href="/work" onClick={() => selectedTechnology('CMS')}>CMS</a><br />
                        <a href="/work" onClick={() => selectedTechnology('TFS')}>TFS</a><br></br>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </Box>
          </Box>
        </Box>
        {/* Horizontal Line */}
        <Divider className='orangedivider' />

        {/* 3. Footer Bottom */}
        <Box className="boxcontent">
          <Box className="FooterBottom footerlinks">
            <Box className="FooterbottomLeft">
              <Box className="Footerbrlogo">
                {/* Left: Logo */}

                <a href='/' onClick={handelLogoClick}>
                  <img
                    src={footerLogo}
                    alt="Company Logo"
                  />
                </a>
              </Box>

              {/* Center: Two-line content floated left after logo */}
              <Box className="footerlastlinks">
                {/* <HashLink to="/#work" >Work</HashLink><span className="saprator"> | </span> */}
                <a href="/work" onClick={updateFilter}>Work</a> <span className="saprator"> | </span>
                <a href="#services" onClick={() => handleClick()}>Services</a> <span className="saprator"> | </span>
                <a href="#process" onClick={() => handleProcessClick()} >Process</a> <span className="saprator"> | </span>
                {/* <Link to="/work" title="Know About BinaryRepublik">Our Work</Link> | */}
                <a href="/careers" >Careers</a> <span className="saprator"> | </span>
                <a href="/contact/" >Contact Us</a> <span className="saprator"> | </span>
                <a href="/sharepoint-events" >Events</a> <span className="saprator"> | </span>
                <a href="http://blog.binaryrepublik.com"  >Blog</a>
                <br></br> <span>
                  Copyright © 2025. <a href="/" title='Binary Republik' onClick={pageReload}>Binary Republik</a>. <span>All Rights Reserved. | <a href="/privacy-policy/" title='Our Policy'>Privacy Policy</a></span></span>
              </Box>
            </Box>
            {/* Right: Social media icons */}
            <Box className="socialarea">
              <Link to="https://www.linkedin.com/company/binary-republik" target="_blank" rel="noopener noreferrer" title="Connect with BR on LinkedIn">
                <img src={linkdin} alt="LinkedIn" />
              </Link>
              <Link to="https://www.instagram.com/binaryrepublik/" target="_blank" rel="noopener noreferrer" title="Connect with BR on Instagram">
                <img src={insta} alt="Instagram" />
              </Link>
              <Link to="https://www.facebook.com/BinaryRepublik/" target="_blank" title="Connect with BR on Facebook" rel="noopener noreferrer">
                <img src={facebook} alt="Facebook" />
              </Link>
              <Link to="https://x.com/binaryrepublik" target="_blank" rel="noopener noreferrer" title="Connect with BR on Twitter">
                <img src={twiiter} alt="Twitter" />
              </Link>

            </Box></Box>
        </Box>
      </Box></Box>
  );
};

export default Footer;