import React, { useRef, useState } from 'react';
import { Button, Container, TextField, Typography, Box, LinearProgress } from '@mui/material';
import { postApplyNow } from '../../services/ApiService';
import { useLocation } from 'react-router-dom';
import CheckIcon from "@mui/icons-material/Check";

interface FormValues {
  name: string;
  email: string;
  contact: string;
  message: string;
  profile: string;
  file: File | null;
}

const ApplyNow: React.FC = () => {
  const location = useLocation();
  const job = location.state?.job;
  const [progress, setProgress] = useState(0);
  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    email: '',
    contact: '',
    message: '',
    profile: job?.openingTitle || '',
    file: null,
  });

  const [formErrors, setFormErrors] = useState<{ [key: string]: string | boolean }>({
    name: '',
    email: '',
    contact: '',
    message: '',
    profile: job?.openingTitle || '',
    file: '',
  });
  const [status, setStatus] = useState<"submit" | "progress" | "sent" | "sentbutton">("submit");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadFileWarning, setUploadFileWarning] = useState(false);
  const allowedChars = /^[\d\+\-(\)\s]*$/;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click(); // Open file selection popup
  };
  const handleFocus = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name } = e.target;
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsSubmitting(false);
    setStatus("submit");
    const { id, value } = e.target;
    const nameAllowedChars = /^[A-Za-z ]*$/;
    if (id === "contact" && allowedChars.test(value) && value.length <= 16) {
      setFormValues({ ...formValues, [id]: value });
    } else if (id === "name") {
      // Check if the name field matches the allowed characters
      if (nameAllowedChars.test(value)) {
        // If valid, set error to false
        setFormErrors((prev) => ({ ...prev, name: false }));
        setFormValues((prev) => ({ ...prev, [id]: value }));
      } else {
        // If invalid, set error to true
        setFormErrors((prev) => ({ ...prev, name: true }));
      }
    }
    else if (id !== "contact") {
      setFormValues({ ...formValues, [id]: value });
    }
    setFormErrors((prev) => ({ ...prev, [id]: '' })); // Clear specific field error
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSubmitting(false);
    setStatus("submit");
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFormErrors((prev) => ({ ...prev, file: "" })); // Clear file error

      if (e.target.files[0].size > 3 * 1024 * 1024) {
        setUploadFileWarning(true);
        setFormErrors((prev) => ({ ...prev, file: "File size exceeds 3 MB" }));
        return;
      }
      // Validate file type (DOC, DOCX, PDF, RTF, ODT)
      // const validTypes = [
      //   "application/msword",
      //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      //   "application/pdf",
      //   "application/rtf",
      //   "application/vnd.oasis.opendocument.text",
      //   "application/x-vnd.oasis.opendocument.text",
      // ];

      const validExtensions = [".doc", ".docx", ".pdf", ".rtf", ".odt"];
      const fileName = file.name.toLowerCase();
      if (!validExtensions.some(ext => fileName.endsWith(ext))) {
        setUploadFileWarning(true);
        setFormErrors((prev) => ({
          ...prev,
          file: "Supported formats: DOC, DOCX, PDF, RTF, ODT",
        }));
        return;
      }

      setFormValues({ ...formValues, file: e.target.files[0] }); // Update state
    }
  };

  const validateField = (id: string, value: string): string => {
    setUploadFileWarning(false);
    if (id === 'name' && value.trim() === '') return 'Required Field';
    if (id === 'email') {
      if (value.trim() === "") {
        return 'Required Field';
      } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
        return 'Invalid Email';
      }
    }
    if (id === 'message' && value.trim() === '') return 'Required Field';
    if (id === 'file' && !formValues.file) return 'Required Field';
    return '';
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    const errorMessage = validateField(id, value);
    setFormErrors((prev) => ({ ...prev, [id]: errorMessage }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatus("progress");
    setProgress(0);

    // Validate all fields
    const errors: { [key: string]: string } = {};
    Object.keys(formValues).forEach((key) => {
      if (key !== 'file') {
        const value = (formValues as any)[key];
        const error = validateField(key, value);
        if (error) errors[key] = error;
      }
    });

    if (!formValues.file) errors.file = 'Required Field';

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setIsSubmitting(false);
      return;
    }

    try {
      const interval = setInterval(() => {
        setProgress((oldProgress) => {
          const newProgress = Math.min(oldProgress + Math.random() * 15, 100);
          if (newProgress === 100) {
            clearInterval(interval);
            setTimeout(() => {
              setIsSubmitting(false);
            }, 2000);
          }
          return newProgress;
        });
      }, 200);
      const response = await postApplyNow(formValues);

      if (response === 1) {
        setStatus("sentbutton");
        setTimeout(() => {
          setStatus("sentbutton");
        }, 2000)
        setFormValues({
          name: '',
          email: '',
          contact: '',
          message: '',
          profile: job?.openingTitle || '',
          file: null,
        });
      }
      else {
        setIsSubmitting(false);
        setStatus("submit");
        // alert('There was an issue submitting your application. Please try again.');
      }
    } catch (error) {
      setIsSubmitting(false);
      alert('An unexpected error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form id="frm" onSubmit={handleSubmit}>
      <Container id="apply" >
        <Box className="apply_form_container">

          <Typography variant="h3">Apply Now</Typography>
          <Box
            className="apply_form formstyles"
            display="flex"
            flexWrap="wrap"
            gap={2}
          >
            <TextField
              id="name"
              label="Name"
              variant="standard"
              fullWidth
              value={formValues.name}
              onChange={handleInputChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              error={!!formErrors.name}
              helperText={formErrors.name}
              inputProps={{
                pattern: "^[A-Za-z ]+$", // Allows only letters and spaces in any order
                title: "Only letters are allowed (no numbers or special characters)"
              }}
            />
            <TextField
              id="email"
              label="Email"
              variant="standard"
              fullWidth
              value={formValues.email}
              onChange={handleInputChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              error={!!formErrors.email}
              helperText={formErrors.email}
            />
            <TextField
              id="contact"
              label="Contact"
              variant="standard"
              fullWidth
              value={formValues.contact}
              onChange={handleInputChange}
              inputProps={{
                maxLength: 16,
                pattern: "[\\d\\+\\-\\(\\)]*"
              }}
            />

            <Box
              className="BrowseFile"
            >
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />

              {/* Disabled TextField to Show Selected File Name */}
              {/* <TextField
                    variant="standard"
                    fullWidth
                    value={formValues.file ? formValues.file.name : "No file chosen"}
                    disabled
                    error={!!formErrors.file}
                    helperText={formErrors.file}
                  /> */}

              <Typography variant="body1" color="textSecondary">
                {formValues.file ? formValues.file.name : "Upload Resume"}
              </Typography>
              {formErrors.file && (
                <Typography variant="body2" className={uploadFileWarning == true ? "Mui-warn" : "Mui-error"}>
                  {formErrors.file}
                </Typography>
              )}

              {/* Custom Browse Button */}
              <Button variant="contained" onClick={handleButtonClick} className='btnbrowse'>
                Browse
              </Button></Box>
            <Box
              sx={{
                flex: '1 1 100%', // Full width for the button
                textAlign: 'center',
                mt: 2,
              }}
            >
              <TextField
                id="message"
                label="Message"
                variant="standard"
                fullWidth
                multiline
                rows={4}
                value={formValues.message}
                onChange={handleInputChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                error={!!formErrors.message}
                helperText={formErrors.message}
              />
              <Button
                variant="contained"
                color="error"
                type="submit"
                className="submitbtn"
                disabled={isSubmitting}
              >
                {/* {!isSubmitting ? "SENT" : ""} */}
                {status === "submit" && "SUBMIT"}
                {status === "sent" && <CheckIcon />}
                {status === "sentbutton" && "SENT"}
                {/* Centered Progress Line */}
                {status === "progress" && (
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                      position: "absolute",
                      top: "50%", // Centered vertically
                      left: 0,
                      transform: "translateY(-50%)", // Adjust to center properly
                      width: "100%",
                      height: 10, // Adjust thickness
                      backgroundColor: "rgba(255, 0, 0, 0.3)", // Light red background
                      "& .MuiLinearProgress-bar": {
                        background: "linear-gradient(90deg, #ff0000, #b30000)", // Red shades
                      },
                    }}
                  />
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </form>
  );
};

export default ApplyNow;