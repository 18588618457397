// CustomArrows.tsx
import React from 'react';
import EastIcon from '@mui/icons-material/East';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
interface ArrowProps {
  onClick?: () => void;
  className?: string;
}

export const CustomLeftArrow: React.FC<ArrowProps> = ({ onClick }) => (
  <div className="custom-arrow left">
    <KeyboardBackspaceIcon  onClick={onClick}/> 
  </div>
);

export const CustomRightArrow: React.FC<ArrowProps> = ({ onClick }) => (
  <div className="custom-arrow right">
    <EastIcon  onClick={onClick}/>
  </div>
);

export const DisabledLeftArrow: React.FC<ArrowProps> = ({ onClick }) => (
  <div className="custom-arrow left">
    <KeyboardBackspaceIcon style={{opacity:.3}} /> 
  </div>
);

export const DisabledRightArrow: React.FC<ArrowProps> = ({ onClick }) => (
  <div className="custom-arrow right">
    <EastIcon style={{opacity:.3}}  />
  </div>
);


export const CarouselLeftArrow = ({ onClick }: ArrowProps) => (
  <button
    onClick={onClick}
     className='arrow'
    style={{
      position: 'absolute',
      left: 0,
    }}
    aria-label="Previous slide"
  >
    <ArrowBackIosIcon  style={{marginLeft:'8px'}}/>
  </button>
);

export const CarouselRightArrow = ({ onClick }: ArrowProps) => (
  <button
    onClick={onClick}
    className='arrow'
    style={{
      position: 'absolute',
      right: 0,
    }}
    aria-label="Next slide"
  >
    <ArrowForwardIosIcon />
  </button>
);

export const ImageSliderLeftArrow  = ({onClick}: ArrowProps) => (
  <button
  onClick={onClick}
  style={{
    fontSize: '5px',
    borderRadius:'50%',
    position: 'absolute',
    top: '50%',
    left: '12px',
    transform: 'translateY(-50%)',
    background: 'rgba(0, 0, 0)',
    color: 'white',
    border: '2px solid white',
    padding: '0px',
    cursor: 'pointer',
    zIndex: 2,
    height:'29px',
    width:'29px'
  }}
>
  <ChevronLeftIcon />
</button>
)
export const ImageSliderRightArrow  = ({onClick}: ArrowProps) => (
  <button
  onClick={onClick}
  style={{
    fontSize: '5px',
    borderRadius:'50%',
    position: 'absolute',
    top: '50%',
    right: '12px',
    transform: 'translateY(-50%)',
    background: 'rgba(0, 0, 0)',
    color: 'white',
    border: '2px solid white',
    padding: '0px',
    cursor: 'pointer',
    zIndex: 2,
    height:'29px',
    width:'29px'
  }}
>
<ChevronRightIcon  />
</button>
)