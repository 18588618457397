// src/pages/HomePage.tsx
import React, { useRef } from 'react';
import Services from '../sections/Services';
import Process from '../sections/Process';
import Technologies from '../sections/Technologies';
import CustomerStories from '../sections/CustomerStories'
import HeroSectionCopy from '../sections/HeroSectionCopy';
import Footer from '../components/Footer';

const HomePageCopy: React.FC = () => {
  const topProcessRef = useRef<HTMLDivElement>(null);
  return (
  <>
    <div className='bannerTextCenter'>
      
      <HeroSectionCopy />
      </div>   
    <div className='viewAllTextAdded'>
    <CustomerStories pageName='home' workList={[]} /></div>
      <Services />
      <Process topProcessRef={topProcessRef}/>
      <Technologies />
      <Footer hasWorkItem={false}/>
    </>
  );
};

export default HomePageCopy;
