import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import ImageSlider from './ImageSlider';
import CaseStudyLetsGetStarted from './CaseStudyLetsGetStarted';
import HeaderSection from '../../sections/HeaderSection';
import CustomerStories from '../../sections/CustomerStories';
import { Link } from 'react-router-dom';
import workJson from "../../Work.json";
import { workCaseStudy } from '../../interface/WorkCaseStudy';
import HeadImg from '../../assets/Images/common/career.jpg';
import Footer from '../../components/Footer';
import { caseStudyWorkFilter } from '../../common-functions/caseStudyWorkFilter';
import useMetaTags from '../../common-functions/useMetaTags';


const MobileAppFibronostics1: React.FC = () => {
    useMetaTags(
        "SharePoint Solutions | Case Study - Knowledge Management Portal",
        "",
        ""
      );
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const additionalKeywords = ['application development','web app','react','angular','ai','machinelearning'];
        const results = caseStudyWorkFilter(workJson.workCaseStudy,additionalKeywords,'healthcare','mobile-app-singapore-fibronostics-1');
        setWorks(results);
    }, []);
    const sliderImages = [
        '/assets/Images/CaseStudy/mobile-app-singapore-fibronostics-1/fibro1.png',
        '/assets/Images/CaseStudy/mobile-app-singapore-fibronostics-1/fibro2.png',
        '/assets/Images/CaseStudy/mobile-app-singapore-fibronostics-1/fibro3.png',
        '/assets/Images/CaseStudy/mobile-app-singapore-fibronostics-1/fibro4.png',
        '/assets/Images/CaseStudy/mobile-app-singapore-fibronostics-1/fibro5.png',
        '/assets/Images/CaseStudy/mobile-app-singapore-fibronostics-1/fibro6.png',
    ];
    return (
        <>
            <HeaderSection pageName='Case Study' imageUrl={HeadImg} alt="Background Image" />
            <Box className=" whitebox contentbox" id="Project-Overview" sx={{ padding: 4 }} >
                <Container className="contentpage">
                    <Box className="casestudy_container">
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} className="compnay_detail_div">
                            <Box flex={1} className="company_logo" sx={{ textAlign: 'center', margin: 5 }}>
                                <img src='/assets/Images/work/fibronostics.jpg' alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                            </Box>

                            <Box flex={2} p={2} className="company_detail">
                                <Typography variant="h5" className='cmp_dtl_title'>Fibronostics</Typography>
                                <Box mt={2} className="cmpdtl_fullbox" >
                                <div className="companydetails" >                            

                                    <ul><li> <b>Location:</b></li><li><span>Singapore</span></li></ul>
                                    <ul><li> <b>Industry:</b></li><li><span>Healthcare</span></li></ul>
                                    <ul><li> <b>Environment:</b></li><li><span>Application Development</span></li></ul>
                                    <ul><li> <b>Skills:</b></li><li><span>React Native, Flask API, Visual Studio Code </span></li></ul>
                                    <ul><li> <b>Project(s):</b></li><li> <a className='active'><span>Healthcare diagnostic web application</span></a></li></ul></div>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Container>
                <Container className="innerpagecontainer contentpage prj_overview_fulldiv">
                    <Typography variant="h2" gutterBottom className='prjover-main-title'>
                        Optimizing Liver Disease Diagnostics with Secure Data Management and Advanced Reporting Solutions for Fibronostics
                    </Typography>

                    <Box display="flex" flexDirection={{ xs: 'column', md: "row" }} >
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'column' }} className="prj_overview_div">
                            {/* About the Client */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">About the Client</Typography>
                                <Typography variant='body2' className='prjover-content'>Fibronostics is a healthcare technology company delivering algorithmbased diagnostic solutions for chronic conditions like liver disease (NAFLD & NASH), coronary heart disease, stroke, diabetes, and sleep apnea. Its flagship tools, LIVERFASt and HealthFACTR, provide non-invasive, diagnostics and actionable risk predictions, empowering healthcare professionals to enhance patient care and outcomes.</Typography>
                            </Box>
                            {/* The Challenge */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">The Challenge</Typography>
                                <Typography variant='body2' className='prjover-content' ><Typography variant='body2' className='prjover-content'>The client faced challenges in managing and processing large volumes of liver disease data accurately while ensuring secure authentication and protecting sensitive patient information. They needed a system capable of supporting hierarchical entities with tailored access for diverse user roles, such as admins, operators, and prescribers. Generating detailed and reliable medical reports in PDF and CSV formats was essential, along with ensuring consistent database schema versioning and seamless migrations. Additionally, maintaining data consistency across microservices in a distributed architecture and delivering a responsive, user-friendly interface for various stakeholders were key requirements.</Typography></Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection={{ xs: 'column' }} >
                            {/* Image Slider Section */}
                            <Box className="prj_scrnshot" textAlign="center">
                                <ImageSlider imageList={sliderImages || []} />

                            </Box>
                        </Box>
                    </Box>


                    {/* Solution and Benefits */}
                    <Box className="prj_overview_fulldiv">

                        {/* The Solution */}

                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>The Solution</Typography>
                            <Typography variant='body2' className='prjover-content'>Here’s a summary of the solution provided to the client based on the project requirements</Typography>
                            <Typography variant='body2' className='prjover-content'>
                                <ul>
                                    <li>Users can log in with credentials.</li>
                                    <li>Users can reset their password. </li>
                                    <li>After logging in, users are redirected to the Dashboard, the central hub for accessing system features. </li>
                                    <li><strong> Take Test &amp; Create Test:</strong> Displays available tests, generates results based on biomarker inputs, and notifies users upon submission. Batch Upload allows bulk uploading of test data via CSV/XLSX, with notifications and record management.</li>
                                    <li><strong>Browse Results:</strong> View, filter, and export test results in CSV/PDF, with bulk export options. </li>
                                    <li><strong> Reports:</strong> Includes Bulk Export and Batch Upload reports, with management and download options. </li>
                                    <li><strong> Admin Tab:</strong> Manages entities, users, roles, permissions, product visibility, and system configurations.</li>
                                    <li><strong> User Profile Tab:</strong> Allows profile management, toggling features, and viewing personal details. </li>
                                    <li><strong> My Entity Tab:</strong> Admins manage entities, sub-entities, product access, and users within the group. </li>
                                    <li><strong> Beta UI &amp; Session Tabs:</strong> Users opt into experimental features and start Test/Demo sessions for categorized tests. </li>
                                    <li><strong> About Section:</strong> Displays system version and downloadable PDFs. </li>
                                    <li><strong> Take Test Tab &amp; Create Test:</strong> Displays available tests for each entity with options to take tests, browse results, upload batches, and view analytics. </li>
                                    <li>This solution efficiently managed data, secured access, and provided powerful reporting and analytics for liver disease testing.</li>
                                </ul>
                            </Typography>
                        </Box>
                        {/* Benefits */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>Benefits</Typography>
                            <Typography variant='body2' className='prjover-content'>
                                <ul>
                                    <li><strong>Streamlined Test Management:</strong> Simplifies the process of conducting, managing, and tracking tests, including bulk uploads. </li>
                                    <li><strong> Efficient Reporting &amp; Exporting:</strong> Bulk export options allow easy downloading of test results and reports in CSV or PDF formats. </li>
                                    <li><strong> Centralized Results Access:</strong> Provides users with quick access to test results, with filters for easy navigation and sorting. </li>
                                    <li><strong> Customizable Roles &amp; Permissions:</strong> Tailored access control for users, ensuring secure management of data and settings. </li>
                                    <li><strong> Automatic Notifications:</strong> Notifications sent to stakeholders (operator, patient, prescriber) after test submission. </li>
                                    <li><strong> Enhanced Security &amp; Integrity:</strong> Test data is locked post-submission, ensuring secure, tamper-proof results. </li>
                                    <li><strong> Simplified Admin Controls:</strong> Admins can manage users, entities, and product visibility easily, along with system configurations. </li>
                                    <li><strong> User-Centric Profile Management:</strong> Allows users to manage their profile, toggle settings, and access personal and entity information.</li>
                                </ul>
                            </Typography>
                        </Box>

                    </Box>
                </Container >
            </Box >
            <Box id="Case-Study-List">
                {/* Title */}
                {works.length > 0 &&
                    <CustomerStories pageName='caseStudy' workList={works} />
                }
            </Box>
            <CaseStudyLetsGetStarted projectName='Fibronostics' />
            {works.length > 0 ?
                <Footer hasWorkItem={false} /> : <Footer hasWorkItem={true} />
            }
        </>
    );
};

export default MobileAppFibronostics1;
