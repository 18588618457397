// src/sections/Services.tsx
import React from 'react';
import { Box, Typography, Grid, Card, CardMedia, CardContent } from '@mui/material';
import ai from '../assets/Images/services/ArtificialIntelligence.png';
import sharepointconsulting from '../assets/Images/services/SharePointConsulting.png';
import salesforceconsulting from '../assets/Images/services/SalesforceConsulting.png';
import azure from '../assets/Images/services/AzureCloudAWS.jpg';
import microsofplatform from '../assets/Images/services/MicrosoftPowerPlatform.png';
import mobilesolution from '../assets/Images/services/MobileSoluton.png';
import customdevelopement from '../assets/Images/services/CustomDevelopmentApplication.png';
import { Link } from 'react-router-dom';
import cms from '../assets/Images/services/ContentManagementSystem.png';

const servicesList = [
  { service: 'Artificial Intelligence', image: ai, path: '/artificial-intelligence/' },
  { service: 'Microsoft Power Platform', image: microsofplatform, path: '/microsoft-power-platform/' },
  { service: 'SharePoint Consulting', image: sharepointconsulting, path: '/sharepoint-consulting/' },
  { service: 'Azure, AWS Cloud', image: azure, path: '/azure-aws-cloud/' },
  { service: 'Salesforce Consulting', image: salesforceconsulting, path: '/salesforce-consulting/' },
  { service: 'Custom Application Development', image: customdevelopement, path: '/custom-application-development/' },
  { service: 'Mobile Solutions', image: mobilesolution, path: '/mobile-solutions/' },
  { service: 'Content Management Systems', image: cms, path: '/content-management-systems/' },
];

const Services: React.FC = () => {
  return (
    <Box id="services"
      className="outerbox blackbgcolor">
      <Box className="OurServices">
        <Box className="boxcontent">
          <Typography variant="h5" gutterBottom className="heading whitecolor">
            Services
          </Typography>
          <Typography variant="body1" className='content whitecolor'>
          Binary Republik provides comprehensive IT consulting services to help businesses leverage technology for growth, efficiency, and innovation. Our expertise spans across Microsoft technologies, AI solutions, mobile applications, and Salesforce, enabling digital transformation tailored to your unique business needs.
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: '2rem' }}
        >
          {servicesList.map((serviceItem, index) => (
            <Grid item xs={12} sm={4} md={3} key={index}>
              <a href={serviceItem.path} className='cardlink'>
                <Card className="OurServicescard">
                  <div className="bggradint">
                    <CardMedia
                      component="img"
                      image={serviceItem.image} // Now using the image from the serviceItem object
                      alt={`Service ${serviceItem.service}`}
                      className="OurServicesImg"
                    />
                    <Typography variant="body2" className='OurServicesText'>
                      {serviceItem.service} {/* Now displaying the service name */}
                    </Typography>
                  </div>
                </Card>
              </a>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Services;
