import React from 'react';
import HeaderSection from '../sections/HeaderSection';
import contactImage from '../assets/Images/common/contact.jpg';
import LetsTalk from '../sections/LetsTalk';
import HeadImg from '../assets/Images/common/contact.jpg';

import Footer from '../components/Footer';
import useMetaTags from '../common-functions/useMetaTags';
 
const ContactUs: React.FC = () => {
  useMetaTags(
    "Connect with SharePoint Experts - Contact US | Binary Republik",
    "SharePoint Consulting – Contact US | Binary Republik is an Offshore SharePoint Consulting & Development Company based out of India focusing on SharePoint Implementations, Office 365, SharePoint App Model, SharePoint 2016, SharePoint 2013, SharePoint Customization &amp; SharePoint Branding.",
    ""
  );
  return (
    <>
      <HeaderSection pageName="Let's Talk" imageUrl={HeadImg} alt="Background" />
      <LetsTalk />
      <Footer hasWorkItem={false}/>
    </>
  );
};
 
export default ContactUs;