import React, { useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import SiteVisitorDetail from "../sub-sections/Common/SiteVisitorDetail";
import { useLocation } from "react-router-dom";

interface TextTitle {
    pageName: string;
    imageUrl: string;
    alt: string;
}
const HeaderSection: React.FC<TextTitle> = ({ pageName, imageUrl, alt }) => {
    const location = useLocation();
    return (
        <div>
            <Box className="hero" sx={{ position: 'relative', height: '50vh', overflow: 'hidden' }}>
                <Box
                    id="HeroSection"
                    className="image-background"
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        zIndex: 0
                    }}
                >
                    <img
                        src={imageUrl} // Replace with your image URL
                        alt={alt}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            position: 'absolute',
                            top: 0,
                            left: 0
                        }}
                        loading="lazy"
                    />
                    {/* Transparent overlay */}
                    <Box
                        className="overlay"
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(240, 128, 128, 0.3)', // light red color with 50% opacity
                            zIndex: 1,
                            opacity: .5
                        }}
                    />
                </Box>
                <noscript>
                    <Typography variant="body1" className="noscriptMsg">
                        Javascript is disabled in your browser
                    </Typography>
                </noscript>

                <Box className="landing_content" sx={{ position: 'relative', zIndex: 2 }}>
                    <Box className="heading">
                        <Typography className="heading_title" variant="h1" color="white">
                            {pageName}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <SiteVisitorDetail />
        </div>
    );
};

export default HeaderSection;
