import HeaderSection from '../sections/HeaderSection';
import { Box, Stack, Typography, Card, CardContent, Container } from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import { Link, useLocation } from 'react-router-dom';
import EventCard from '../sub-sections/Events/EventCard';
import { Event } from '../interface/Event';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import HeadImg from '../assets/Images/common/event-header.jpg';
import Footer from '../components/Footer';
import { useEffect, useState } from 'react';
import { fetchArchivedEvent } from '../services/ApiService';
import useMetaTags from '../common-functions/useMetaTags';

const ArchiveSharePointEvents: React.FC = () => {
  useMetaTags(
    "Global SharePoint Events - Archive | Binary Republik",
    "Global SharePoint Events - Archive",
    ""
  );
  const [archiveEvents, setArchiveEvents] = useState<Event[]>([]);
  useEffect(() => {
    const loadEvents = async () => {
      try {
        const ArchiveEvents: Event[] = await fetchArchivedEvent();
        setArchiveEvents(ArchiveEvents);
      } catch (error) {
        console.error('Failed to load events:', error);
      }
    };

    loadEvents();
  }, []);

  const renderEventDate = (eventDate: Date) => {
    const date = new Date(eventDate);
    // Extract the day, month (short format), and year
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' }); // Example: "Nov"
    const year = date.getFullYear();

    return (
      <div className="spe_date_grop">
        <Typography variant="body2" className="spedate">
          {day}
        </Typography>
        <div className="spe_monthyear">
          <Typography variant="body2" className="speMonth">
            {month}
          </Typography>
          <Typography variant="body2" className="speyear">
            {year}
          </Typography>
        </div>
      </div>
    );
  };
  const [firstEvent, ...restEvents] = archiveEvents;
  return (
    <>
      <HeaderSection pageName='Events' imageUrl={HeadImg} alt="Background Image" />
      <Box className="graybox">
        <Box className="innerpagecontainer ">
          <Container sx={{ display: 'flex', justifyContent: 'flex-end', mb: 4, }}  >
            <Stack direction="row" spacing={2} alignItems="center" className="spe-page-list">
              <Link to="/sharepoint-events">
                Upcoming
              </Link>
              <Typography>|</Typography>

              <Link to="/Archive-SharePoint-Events"
                style={{ textDecoration: 'none', color: 'inherit' }} className="active">
                Previous
              </Link>

            </Stack>
          </Container>
          <Container >
            {/* Card Grid Layout */}
            {archiveEvents.length > 0 && (
              <Grid2 container spacing={3}>
                {/* First Row - 8 and 4 columns */}
                <Grid2 size={{ md: 8 }}>
                  <Card sx={{ backgroundColor: '#e2593a', color: 'white', height: 300, position: 'relative' }}>
                    <CardContent>
                      {/* Location in the top-right corner */}
                      <div className="spe_loction"><Typography
                        variant="body2"
                      >
                        <RoomOutlinedIcon /> {firstEvent.eventLocation}

                      </Typography></div>

                      {/* Event Date */}
                      {renderEventDate(firstEvent.eventDate)}

                      <hr></hr>
                      {/* Event Title */}
                      <Typography variant="body1" className="eventTitle">
                        {firstEvent.eventTitle}
                      </Typography>

                      {/* Event Description */}
                      <Typography variant="body2" className="eventDescription" sx={{ marginBottom: 2 }} >
                        {firstEvent.eventDescription}
                      </Typography>

                      {/* Event Link */}
                      <Link to={firstEvent.eventLink} className='morelink'>
                        View More <ArrowCircleRightIcon />
                      </Link>
                    </CardContent>
                  </Card>
                </Grid2>

                {/* Other Event Cards */}
                {restEvents.map((event: Event, index: number) => (
                  <EventCard key={index} event={event} />
                ))}
              </Grid2>
            )}
          </Container>
        </Box>
      </Box>
      <Footer hasWorkItem={false} />
    </>
  );
};

export default ArchiveSharePointEvents;
