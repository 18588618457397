import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import ImageSlider from './ImageSlider';
import CaseStudyLetsGetStarted from './CaseStudyLetsGetStarted';
import HeaderSection from '../../sections/HeaderSection';
import { Link } from 'react-router-dom';
import CustomerStories from '../../sections/CustomerStories';
import { workCaseStudy } from '../../interface/WorkCaseStudy';
import workJson from "../../Work.json";
import HeadImg from '../../assets/Images/common/career.jpg';
import Footer from '../../components/Footer';
import { caseStudyWorkFilter } from '../../common-functions/caseStudyWorkFilter';
import useMetaTags from '../../common-functions/useMetaTags';

const SharepointConsultingMicrosoft2: React.FC = () => {
    useMetaTags(
        "SharePoint Solutions | Case Study - Intranet Portal",
        "",
        ""
      );
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const additionalKeywords = ['staffing','microsoft','sharepoint','office','react','angular','ai','machinelearning'];
        const results = caseStudyWorkFilter(workJson.workCaseStudy,additionalKeywords,'staffing & recruitment','sharepoint-consulting-wa-usa-microsoft-2');
        setWorks(results);
    }, []);
    return (
        <>
        <HeaderSection pageName='Case Study'  imageUrl={HeadImg} alt="Background Image"  />
            <Box className=" whitebox contentbox" id="Project-Overview" sx={{ padding: 4}}>
                <Container className="contentpage">
                    <Box className="casestudy_container">
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} className="compnay_detail_div">
                            <Box flex={1} className="company_logo" sx={{ textAlign: 'center', margin: 5 }}>
                                <img src='/assets/Images/work/microsoft.jpg' alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                            </Box>

                            <Box flex={2} p={2} className="company_detail">
                                <Typography variant="h5" className='cmp_dtl_title'>Microsoft</Typography>
                                <Box mt={2} className="cmpdtl_fullbox" >
                                <div className="companydetails"> 
                                    <ul><li> <b>Location:</b></li><li><span>WA, USA</span></li></ul>
                                    <ul><li> <b>Industry:</b></li><li><span>Staffing & Recruitment</span></li></ul>
                                    <ul><li> <b>Environment:</b></li><li><span>SharePoint Online/Office 365</span></li></ul>
                                    <ul><li> <b>Skills:</b></li><li><span>SharePoint, Power Platform (Power Apps, Power BI & Ms flow)</span></li></ul>
                                    <ul><li> <b>Project(s):</b></li><li>
                                    <a  className='active'><span>Intranet Portal</span></a>    <i className='orangseperator'>|</i>  <a href='/case-study/sharepoint-consulting-wa-usa-microsoft-1'><span>Knowledge Management Portal</span></a>
                                   </li></ul>
                                    </div>
                                
                                
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Container>
                <Container className="innerpagecontainer contentpage prj_overview_fulldiv">
                    {/* {caseStudy?.clientSlogan != null ?  */}
                    <Typography variant="h2" gutterBottom className='prjover-main-title'>
                        Artech Consulting: Streamlining Global Staffing Operations with PowerApps, SharePoint, and Automation Solutions
                    </Typography>
                    {/* : null} */}

                    <Box display="flex" flexDirection={{ xs: 'column', md: "row" }} >
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'column' }} className="prj_overview_div">
                            {/* About the Client */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">About the Client</Typography>
                                <Typography variant='body2' className='prjover-content'> Artech Consulting LLC, founded in 2003 and now part of Artech Information Systems LLC, is a Seattle-based consulting firm specializing in marketing, project management, and technical solutions. As part of a global MWBE enterprise, Artech serves Fortune/Global 500 companies with a workforce of over 7,500 professionals worldwide.</Typography>
                            </Box>
                            {/* The Challenge */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">The Challenge</Typography>
                                <Typography variant='body2' className='prjover-content' >They were facing challenges in managing Work Orders, Onboarding, Amendments, and Maintenance requests due to:</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}><b>1. Inefficient Processes:</b> Fragmented systems and lack of integration with tools like PRM.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}><b>2. Manual Workflows:</b> No automation for notifications or data pre-population, causing delays.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}><b>3. Role Management Issues:</b> Difficulty managing roles and permissions effectively.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}><b>4. Disconnected Systems:</b> Limited integration between SharePoint Lists and other tools.They needed a streamlined, automated solution to simplify and optimize these processes.</Typography>
                            </Box>

                            
                        </Box>
                        <Box display="flex" flexDirection={{ xs: 'column' }} >
                            <Box className="prj_client_quote" textAlign="center" mt={4}>
                            </Box>
                        </Box>
                    </Box>

                    {/* Solution and Benefits */}
                    <Box className="prj_overview_fulldiv">

                        {/* The Solution */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>The Solution</Typography>
                                <Typography variant='body2' className='prjover-content'>To address the client's requirements, we developed a comprehensive Global Staffing Operations (GSO) Management Portal using PowerApps as an independent Canvas App integrated with multiple SharePoint lists and the Partner Resource Management (PRM) tool. The portal streamlined key operations like Work Orders, Onboarding, Amendments, and Maintenance. Key components include:</Typography>
                                <Typography variant='body2' className='prjover-content'><b>1. Functional Modules - The portal includes:</b></Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>Work Order Management: Auto-generates Work Orders from PRM submissions or manual requests.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>Onboarding Requests: Streamlines onboarding with options for partial onboarding and edits.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>Amendments: Manages active and completed amendment requests.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>Maintenance: Tracks and updates active and completed maintenance tasks.</Typography>
                                
                                <Typography variant='body2' className='prjover-content'><b>2. User Roles and Permissions</b></Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}><b>Requestors:</b> Initiate and track Work Orders, Onboarding, Amendments, and Maintenance requests, with dedicated views to track assigned tasks.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}><b>GSO/IGD Users:</b> Manage, review, and track requests with detailed oversight. Role-based permissions, enabled via Power Automate, ensure secure data access.</Typography>
                                
                                
                                <Typography variant='body2' className='prjover-content'><b>3. Notifications and Deep Linking</b></Typography>
                                <Typography variant='body2' className='prjover-content'>Power Automate enables stage-specific notifications with deep links, ensuring seamless communication and efficient tracking.</Typography>
                                
                                <Typography variant='body2' className='prjover-content'><b>4. Pre-populated Forms and Grids</b></Typography>
                                <Typography variant='body2' className='prjover-content'>Modules feature pre-filled forms linked to SharePoint Lists and a Master-Child grid design for efficient data exploration.</Typography>
                                <Typography variant='body2' className='prjover-content'><b>5. Reporting and Analytics</b></Typography>
                                <Typography variant='body2' className='prjover-content'>Integrated <b>Power BI reports</b> provide a comprehensive status tracker for requests, enabling users to monitor progress, identify bottlenecks, and make data-driven decisions.</Typography>
                                <Typography variant='body2' className='prjover-content'><b>6. Technology Stack</b></Typography>
                                <Typography variant='body2' className='prjover-content'>The solution was built using the following Microsoft technologies:</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}><b>• PowerApps:</b> For the Canvas App development.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}><b>• SharePoint Online:</b> As the backend for storing and managing data.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}><b>• Power Automate:</b> For workflows, notifications, and role-based permissions.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}><b>• Power BI:</b> For analytics and reporting.</Typography>
                                <Typography variant='body2' className='prjover-content'><b>7. Key Features</b></Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}><b>• Home Page:</b> Intuitive dashboard providing role-based access and navigation.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}><b>• Deep Customization:</b> Tailored forms and workflows to align with the client’s operational requirements</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}><b>• Scalability:</b> Designed to accommodate future enhancements and increased workload.</Typography>
                        </Box>
                        {/* Benefits */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>Benefits</Typography>
                                <Typography variant='body2' className='prjover-content'><b>1. Streamlined Operations:</b> Automation of key processes like Work Orders, Onboarding, Amendments, and Maintenance reduces manual effort and improves efficiency</Typography>
                                <Typography variant='body2' className='prjover-content'><b>2. Enhanced User Experience:</b> Pre-populated forms and intuitive grids simplify data entry and navigation, saving time.</Typography>
                                <Typography variant='body2' className='prjover-content'><b>3. Improved Tracking:</b> Real-time notifications with deep links keep users informed and help track the progress of requests.</Typography>
                                <Typography variant='body2' className='prjover-content'><b>4. Role-based Access Control:</b> Secure data access with role-specific permissions ensures that users only see relevant information.</Typography>
                                <Typography variant='body2' className='prjover-content'><b>5. Scalability and Flexibility:</b>  The modular structure allows for easy updates and expansion to accommodate future requirements.</Typography>
                        </Box>
                    </Box>
                </Container >
            </Box >
           
                <Box id="Case-Study-List">
                    {works.length > 0 &&
                    <CustomerStories pageName='caseStudy' workList={works} />
                    }
                </Box>
            <CaseStudyLetsGetStarted projectName='Microsoft' />
            {works.length > 0 ?
                <Footer hasWorkItem={false}/>:<Footer hasWorkItem={true}/>
            }
        </>
    );
};

export default SharepointConsultingMicrosoft2;
