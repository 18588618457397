import React, { useState, useEffect } from 'react';
import HeaderSection from '../sections/HeaderSection';
import { Box, Button, Typography } from '@mui/material';
import TrainingCard from '../sub-sections/Careers/TrainingCard';
import { TrainingData } from '../interface/TrainingData';
import { fatchUpcomingTrainingData } from '../services/ApiService';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link } from 'react-router-dom';
import HeadImg from '../assets/Images/common/career.jpg';
import Footer from '../components/Footer';
import { Training } from '../interface/Training';
import useMetaTags from '../common-functions/useMetaTags';

const SharePointTrainings: React.FC = () => {
  useMetaTags(
    "SharePoint Training | Binary Republik",
    "SharePoint Training",
    ""
  );
  const [trainingData, setTrainingData] = useState<TrainingData[]>([]);
  const [sortedYearsAsc, setSortedYearsAsc] = useState<number[]>([]);
  const [expandedYear, setExpandedYear] = useState<number | null>(null); // Tracks expanded year for month list
  const [selectedYear, setSelectedYear] = useState<number | null>(null); // Tracks selected year for training list
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);

  useEffect(() => {
    const fetchTrainingData = async () => {
      try {
        const rawData: TrainingData[] = await fatchUpcomingTrainingData();
        const formattedData = rawData.map((item) => ({
          year: item.year,
          monthTrainings: item.monthTrainings,
        }));
        setTrainingData(formattedData);

        const years = formattedData.map((data) => data.year).sort((a, b) => a - b); // Ascending order
        setSortedYearsAsc(years);

      } catch (error) {
        console.error('Error fetching training data:', error);
      }
    };

    fetchTrainingData();
  }, []);

  const handleArrowClick = (year: number) => {
    setExpandedYear((prev) => (prev === year ? null : year));
  };

  const handleYearClick = (year: number) => {
    setSelectedYear(year); // Toggle training list for the year
    setSelectedMonth(null);
  };

  const handleMonthClick = (month: string) => {
    if (expandedYear !== null) {
      setSelectedMonth(month); // Set the selected month
      setSelectedYear(expandedYear); // Use the expanded year for filtering
    }
  };

  const filteredTrainingsToShow =
    selectedYear !== null
      ? selectedMonth !== null
        ? { [selectedMonth]: trainingData.find((data) => data.year === selectedYear)?.monthTrainings[selectedMonth] || [] }
        : { ...trainingData.find((data) => data.year === selectedYear)?.monthTrainings }
      : trainingData
        .reduce((acc: { [key: string]: Training[] }, data: TrainingData) => {
          const sortedMonthTrainings = Object.entries(data.monthTrainings)
            .sort(([monthA], [monthB]) => {
              const monthOrder = [
                'Dec', 'Nov', 'Oct', 'Sep', 'Aug', 'Jul', 'Jun', 'May', 'Apr', 'Mar', 'Feb', 'Jan'
              ];
              return monthOrder.indexOf(monthB) - monthOrder.indexOf(monthA); // Sorting months in descending order
            })
            .reduce((monthAcc, [month, trainings]) => {
              // Sort trainings by trainingDate in descending order
              const sortedTrainings = trainings.sort((a, b) => new Date(b.trainingDate).getTime() - new Date(a.trainingDate).getTime());
              monthAcc[month] = sortedTrainings;
              return monthAcc;
            }, {} as { [month: string]: Training[] });

          return { ...acc, ...sortedMonthTrainings };
        }, {});
  return (
    <>
      <HeaderSection pageName="Trainings" imageUrl={HeadImg} alt="Background Image" />

      <Box className="graybox">
        <Box className="archivedTrainings" sx={{ display: 'flex', gap: 2, padding: 2 }}>
          {/* Training Cards */}
          <Box sx={{ flex: 2 }}>
            {filteredTrainingsToShow && (
              <>
                {Object.entries(filteredTrainingsToShow).map(([month, trainings]) => (
                  <Box key={month}>
                    {trainings.map((training, index) => (
                      <TrainingCard
                        key={index}
                        TrainingDate={training.trainingDate}
                        TrainingTitle={training.trainingTitle}
                        TrainingDescription={training.trainingDescription}
                      />
                    ))}
                  </Box>
                ))}
              </>
            )}
            <div className="readmorebtn">
              <a href="/Archive-SharePoint-Trainings" className="decorationtext">Older</a>
            </div>
          </Box>

          {/* Year Selection */}
          <Box >
            <Box className="event-accordion">
              <Typography variant="h6">Upcoming Trainings</Typography>
              <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                {sortedYearsAsc.map((year) => {
                  const yearData = trainingData.find((data) => data.year === year);
                  const months = Object.entries(yearData?.monthTrainings || {});

                  return (
                    <li key={year}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <Box onClick={() => handleArrowClick(year)} sx={{ display: 'flex', alignItems: 'center' }}>
                          {expandedYear === year ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                        </Box>
                        <Typography onClick={() => handleYearClick(year)} sx={{ marginLeft: 1 }}>
                          {`${year} (${months.length})`}
                        </Typography>
                      </Box>
                      {expandedYear === year && (
                        <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                          {months.map(([month, trainings]) => (
                            <li
                              key={month}
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleMonthClick(month)}
                            >
                              {month} ({trainings.length})
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul></Box>
          </Box>
        </Box>
      </Box>
      <Footer hasWorkItem={false} />
    </>
  );
};

export default SharePointTrainings;
