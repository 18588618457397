import React, { useEffect, useRef } from 'react';
import HeaderSection from '../sections/HeaderSection';
import WorkSection from '../sections/WorkSection';
import { useLocation } from 'react-router-dom';
import HeadImg from '../assets/Images/common/Work.jpg';
import Footer from '../components/Footer';
import useMetaTags from '../common-functions/useMetaTags';

const OurWork: React.FC = () => {
  useMetaTags(
    "Microsoft SharePoint Consulting Services from the Experts | Binary Republik",
    "Passion for technology and will to making it easier, simpler and more productive - is a consensus on our floor. We take pride in what we do and are excited about the difference we make to our end users.",
    ""
  );
    const location = useLocation();
   
  const issalesforce = location.state?.issalesforce ?? false; 
  return (
    <div>
    <HeaderSection  pageName='Our Work'  imageUrl={HeadImg} alt="Background Image" />
     <WorkSection isSalesforce={issalesforce}/>
     <Footer hasWorkItem={false}/>
    </div>
  );
};

export default OurWork;
