import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import ImageSlider from './ImageSlider';
import CaseStudyLetsGetStarted from './CaseStudyLetsGetStarted';
import HeaderSection from '../../sections/HeaderSection';
import workJson from "../../Work.json";
import CustomerStories from '../../sections/CustomerStories';
import { workCaseStudy } from '../../interface/WorkCaseStudy';
import HeadImg from '../../assets/Images/common/career.jpg';
import Footer from '../../components/Footer';
import { caseStudyWorkFilter } from '../../common-functions/caseStudyWorkFilter';
import useMetaTags from '../../common-functions/useMetaTags';

const SharepointConsultingPega1: React.FC = () => {
    useMetaTags(
        "SharePoint Solutions | Case Study - Career Map",
        "",
        ""
      );
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const additionalKeywords = ['sharepoint','microsoft','office','react','angular','ai','machinelearning'];
        const results = caseStudyWorkFilter(workJson.workCaseStudy,additionalKeywords,'it','sharepoint-consulting-ma-usa-pega-1');
        setWorks(results);
    }, []);
    const sliderImages = [
        '/assets/Images/CaseStudy/sharepoint-consulting-ma-usa-pega-1/1.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-ma-usa-pega-1/2.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-ma-usa-pega-1/3.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-ma-usa-pega-1/4.png',
    ];
    return (
        <>
            <HeaderSection pageName='Case Study'  imageUrl={HeadImg} alt="Background Image" />
            <Box className=" whitebox contentbox" id="Project-Overview" sx={{ padding: 4}} >
                <Container className="contentpage">
                    <Box className="casestudy_container">
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} className="compnay_detail_div">
                            <Box flex={1} className="company_logo" sx={{ textAlign: 'center', margin: 5 }}>
                                <img src='/assets/Images/work/case-study-pega.jpg' alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                            </Box>

                            <Box flex={2} p={2} className="company_detail">
                                <Typography variant="h5" className='cmp_dtl_title'>PEGA SYSTEMS INC.</Typography>
                                <Box mt={2} className="cmpdtl_fullbox" >
                                    <div className="companydetails">
                                        <ul><li> <b>Location:</b></li><li><span>MA, USA</span></li></ul>
                                        <ul><li> <b>Industry:</b></li><li><span>SharePoint 2010</span> </li></ul>
                                        <ul><li> <b>Environment :</b></li><li><span>IT</span> </li></ul>
                                        <ul><li> <b>Skills:</b></li><li><span>JWPlayer, jQuery Map, JSPlumb Zoom plugin, Layer2, SQL Server</span></li></ul>
                                        <ul><li> <b>Project(s):</b></li><li><a className='active'><span>Career Map</span></a> <i className='orangseperator'>|</i> <a href='/case-study/sharepoint-consulting-ma-usa-pega-2'><span>Career MapHR Onboarding Process</span></a></li></ul>
                                       </div>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                <Container className="innerpagecontainer contentpage prj_overview_fulldiv">
                    <Typography variant="h2" gutterBottom className='prjover-main-title'>
                        PEGA leverages the Microsoft SharePoint platform to offer its employees a comprehensive Career Growth Vision
                    </Typography>
                    {/* : null} */}

                    <Box display="flex" flexDirection={{ xs: 'column', md: "row" }} >
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'column' }} className="prj_overview_div">
                            {/* About the Client */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">About the Client</Typography>
                                <Typography variant='body2' className='prjover-content'>PEGA Systems, headquartered in Cambridge, MA, is a leading software company renowned for its specialization in developing CRM and BPM solutions.</Typography>
                                <Typography variant='body2' className='prjover-content'>Their applications optimize vital business processes, seamlessly connecting enterprises with their customers across multiple channels in real-time, and dynamically adjusting to rapidly evolving needs. PEGA's clientele encompasses numerous world-renowned enterprises, including many among the prestigious Global 2000 list.</Typography>
                            </Box>
                            {/* The Challenge */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">The Challenge</Typography>
                                <Typography variant='body2' className='prjover-content'>PEGA offers its employees ample opportunities for career advancement through a well-defined career roadmap, encompassing both vertical progression and lateral moves across various functions within the organization.</Typography>
                                <Typography variant='body2' className='prjover-content'>However, they were required to manually map all processes and periodically update them to ensure accuracy. These manual procedures proved arduous and time-consuming for HR managers, lacking any visual representation of available career opportunities for employees, thereby constraining their effectiveness.</Typography>
                            </Box>

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">Objectives</Typography>
                                <Typography variant='body2' className='prjover-content'>During our consultation with PEGA's team based in Boston, we discussed the following objectives:</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>- Develop a visually interactive application for employees, offering them a clear career roadmap to explore opportunities within their departments and potentially across functions.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>- Equip managers and the HR department with an intuitive interface to effortlessly administer the application with minimal manual intervention.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>- Implement a search functionality on the SharePoint intranet portal to enable easy identification of job families, grades, cross-functional prospects, job descriptions, and eligibility criteria.</Typography>
                            </Box>

                        </Box>
                        <Box display="flex" flexDirection={{ xs: 'column' }} >
                            {/* Image Slider Section */}
                            <Box className="prj_scrnshot" textAlign="center">
                                <ImageSlider imageList={sliderImages || []} />
                            </Box>
                        </Box>
                    </Box>
                    {/* Solution and Benefits */}
                    <Box className="prj_overview_fulldiv">

                        {/* The Solution */}

                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>The Solution</Typography>
                            <Typography variant='body2' className='prjover-content'>Pega Systems utilizes an on-premise intranet portal built on SharePoint 2010, making it the logical choice to fulfill this particular requirement.</Typography>
                            <Typography variant='body2' className='prjover-content'>The process commenced with an analysis of their data architecture in SQL Server, followed by seamlessly integrating the data into the SharePoint platform using Layer 2 connectivity. To organize the data effectively, it was extracted from SQL Server and incorporated into a SharePoint Custom list, streamlining management tasks.</Typography>
                            <Typography variant='body2' className='prjover-content'>JSPlumb, an open-source toolkit, was employed for the graphical user interface (GUI) to dynamically represent career map nodes and flowcharts. This enabled employees to grasp the complete hierarchy of their department within the organization.</Typography>
                            <Typography variant='body2' className='prjover-content'>A custom search feature was implemented in SharePoint to allow employees to search for specific keywords related to job families or grades. This functionality enabled employees to access detailed information about job grades, attributes of job families, and cross-functional job opportunities based on the keywords entered.</Typography>
                            <Typography variant='body2' className='prjover-content'>Therefore, this streamlined one-click SharePoint deployment solution not only automated management tasks for PEGA but also empowered its employees to comprehend the career map and organizational hierarchy more effectively.</Typography>
                        </Box>
                        {/* Benefits */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>Benefits</Typography>
                            <Typography variant='body2' className='prjover-content'>An interactive career map now illuminates the array of opportunities available within the organization for employees, offering them invaluable insights into their career prospects and avenues for skill enhancement.</Typography>
                            <Typography variant='body2' className='prjover-content'>Employees now possess a clearer comprehension of the hierarchical structure across various job positions, enabling them to strategically plan their careers based on the opportunities available within specific departments.</Typography>
                            <Typography variant='body2' className='prjover-content'>The Career Map simplifies the process for PEGA to provide employees with insights into job categories, levels, and grades within the organization.</Typography>
                            <Typography variant='body2' className='prjover-content'>The enhanced search functionality now enables employees to assess their current and future grades within the job family, facilitating informed decisions about their career paths.</Typography>
                            <Typography variant='body2' className='prjover-content'>The HR department can now efficiently advertise open positions to the existing talent pool within the organization.</Typography>
                        </Box>

                    </Box>
                </Container >
            </Box >
               <Box id="Case-Study-List">
                    {/* Title */}
                    {works.length > 0 &&
                        <CustomerStories pageName='caseStudy' workList={works} />
                    }
                </Box>
            <CaseStudyLetsGetStarted projectName='PEGA SYSTEMS INC.' />
            {works.length > 0 ?
                <Footer hasWorkItem={false}/>:<Footer hasWorkItem={true}/>
            }
        </>
    );
};

export default SharepointConsultingPega1;
