import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

const ControlPanelHeader = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0); // Default active tab (Job Request)

  const tabPaths = [
    "/controlpanel/contactvisitors",
    "/controlpanel/careervisitors",
    "/controlpanel/showcasevisitors",
    "/controlpanel/sitevisitors",
    "/controlpanel/salesaggregator",
  ];
  useEffect(() => {
    const currentTab = tabPaths.indexOf(location.pathname);
    setActiveTab(currentTab !== -1 ? currentTab : 0); // Default to first tab if path not found
  }, [location.pathname]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ bgcolor: "grey.900", padding: "0.5rem" }} className="visitorsmenu">
      
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        classes={{
          indicator: "MuiTabs-indicator",
        }}
          scrollButtons="auto"
          variant="scrollable"
      >
        <Tab
          label="Contact Request"
          component={Link}
          to="/controlpanel/contactvisitors"
          sx={{ color: "white" }}
        />
        <Tab
          label="Job Request"
          component={Link}
          to="/controlpanel/careervisitors"
          sx={{ color: "white" }}
        />
        <Tab
          label="Showcase Request"
          component={Link}
          to="/controlpanel/showcasevisitors"
          sx={{ color: "white" }}
        />
        <Tab
          label="Site Visitors"
          component={Link}
          to="/controlpanel/sitevisitors"
          sx={{ color: "white" }}
        />
        <Tab
          label="Sales Aggregator"
          component={Link}
          to="/#"
          sx={{ color: "white" }}
        />
      </Tabs>
    </Box>
  );
};

export default ControlPanelHeader;
