import React, { useEffect, useState } from 'react';
import { Box, Container, List, ListItem, ListItemText, Typography } from '@mui/material';
import SharePoint from '../assets/Images/services/sharepoint-consulting.png';
import CustomerStories from './CustomerStories';
import { workCaseStudy } from '../interface/WorkCaseStudy';
import workJson from "../Work.json";
import Footer from '../components/Footer';
import { serviceWorkFilter } from '../common-functions/serviceWorkFilter';
import { CardData, TabData } from '../interface/TabData';
import ServiceTabbingSection from '../sub-sections/ServiceCommonComponent/ServiceTabbingSection';
import ServiceCardSection from '../sub-sections/ServiceCommonComponent/ServiceCardSection';
import CaseStudyLetsGetStarted from '../sub-sections/CaseStudy/CaseStudyLetsGetStarted';
import useMetaTags from '../common-functions/useMetaTags';

const SharepointConsultingSection: React.FC = () => {
    useMetaTags(
        "SharePoint Consulting Services | Binary Republik",
        "Engage with a highly skilled team of SharePoint Consultants for your Organization",
        "SharePoint Consulting Service, Sharepoint consulting companies, Sharepoint consulting companies in USA, Offshore SharePoint Consulting partners"
      );
    const tabData: TabData[] = [
        {
             heading: (<>SharePoint On-Premise</>),
            imgSrc: `
                <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/services/SharepointConsulting/SharePointOnPrem.png" alt="sharepoint-onpremise" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;"> `,
            content: (<>
                <p>With over two decades of expertise, our SharePoint consultants have been delivering tailored solutions since SharePoint 2003. We specialize in all SharePoint On-Premise versions, including MOSS 2007, SharePoint 2010, SharePoint 2013, SharePoint 2016, SharePoint 2019, and the latest SharePoint Subscription Edition.</p>
                <p>Our services include Strategic Consulting, Global Implementations, Farm Configurations, Health Checks, AI-driven Search, Modern UX & Branding, Power Platform Integration, SharePoint Workflow Automation, Microsoft Syntex for intelligent document processing, Business Intelligence, Public-Facing Sites, Enterprise Intranet & Extranet Portals, Hybrid Cloud Integration, SharePoint Upgrades, and Migration from Legacy Systems.</p>
                <p>By leveraging Microsoft’s latest innovations, we ensure rapid deployment, enhanced collaboration, and robust security for your SharePoint environment. Explore our expertise in SharePoint development.<a href="/work" onClick={() => selectedTechnology('SharePoint 2016,MOSS 2007,SharePoint 2010,SharePoint 2013,SharePoint 2019')} className="underlineancor"> More...</a></p>
            </>),
        },
        {
             heading: (<>Office 365 - SharePoint Online</>),
            imgSrc: `
                <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/services/SharepointConsulting/365_sharepointonline.png" alt="office365-sharepoint" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;"> `,
            content: (<>
                <p>Office 365 offers a modern digital workspace with AI-driven automation, seamless integration, and real-time collaboration. SharePoint Online, part of Microsoft 365, empowers organizations with intelligent content management, advanced security, and cloud-based scalability.</p>
                <p>Our expertise includes AI-powered Microsoft Syntex for automated document processing, Power Automate for intelligent workflows, Viva Connections for employee engagement, and seamless integration with Microsoft Teams, Power BI, and OneDrive.</p>
                <p>We help businesses migrate from on-premise environments, ensuring a smooth transition with minimal disruption. Our solutions enable secure external sharing, compliance management, and adaptive governance to enhance business productivity.</p>
                <p>Explore our Office 365 solutions here: <a href="/work" onClick={() => selectedTechnology('Office 365 - SharePoint Online')} className="underlineancor">More...</a></p>
            </>),
        },
        {
             heading: (<>SharePoint Migrations</>),
            imgSrc: `
                <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown animated fadeinScroll" src="/assets/Images/services/SharepointConsulting/sharepoint-to-sharepoint-migration.png" alt="sharepoint-migration" data-wow-duration="1500ms" data-wow-delay="300ms" style="display: block;"> `,
            content: (<>
                <p>Upgrade to the latest SharePoint versions with confidence. We specialize in migrations from SharePoint 2003, MOSS 2007, SharePoint 2010, SharePoint 2013, SharePoint 2016, and SharePoint 2019 to SharePoint Subscription Edition and SharePoint Online in Microsoft 365.</p>
                <p>Our migration strategy follows Microsoft Best Practices and includes pre-migration assessments, content reorganization, metadata enrichment, and security audits. We leverage AI-powered Microsoft Syntex and Power Automate to enhance document management and process automation.</p>
                <p>We ensure a seamless transition with minimal downtime, data integrity, and improved performance. Whether it’s a lift-and-shift migration, hybrid deployment, or re-architecting for the cloud, our expertise guarantees optimal results.</p>
                <p>Discover our SharePoint Migration success stories: <a href="/work" onClick={() => selectedTechnology('SharePoint 2016,MOSS 2007,SharePoint 2010,SharePoint 2013,SharePoint 2019')} className="underlineancor">More...</a></p>
            </>),
        }
    ];

    const cardsData: CardData[] = [
        {
            heading: 'Intranet & Collaboration Solutions',
            items: ['Modern Intranet Portals', 'Team Collaboration', 'Document Management', 'Employee Engagement', 'Knowledge Sharing', 'Internal Communication Channels'],
        },
        {
            heading: 'Business Process Automation',
            items: ['Automated Workflows', 'Document Approvals', 'Task Automation', 'Business Rule Management', 'Process Streamlining', 'Employee Productivity Enhancement'],
        },
        {
            heading: 'Migration & Upgrades',
            items: ['On-Prem to Cloud Migration', 'SharePoint Version Upgrades', 'Tenant-to-Tenant Migration', 'Hybrid SharePoint Deployment', 'Data Integrity & Migration Testing'],
        },
        {
            heading: 'Integration & Customization',
            items: ['Third-Party App Integrations', 'CRM & ERP Integrations', 'Custom SharePoint Web Parts', 'Power Apps Development', 'Data Connection with Power BI', 'Custom SharePoint Workflows'],
        },
    ];
    const selectedTechnology = (technology: string) => {
        sessionStorage.setItem("Selected-Vertical", 'All');
        sessionStorage.setItem("Selected-Technology", technology);
    }

    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const keywords = ['sharepoint'];
        const additionalKeywords = ['microsoft', 'ai', 'artificialintelligence', 'machinelearning', 'azure', 'aws', 'cloud'];
        const results = serviceWorkFilter(workJson.workCaseStudy, keywords, additionalKeywords);
        setWorks(results);
    }, []);
    return (
        <>
            <Box sx={{ padding: 4, textAlign: 'center' }} className="contentbox whitebox "  >
                <Container className="innerpagecontainer contentpage">
                    <Typography variant="h2" gutterBottom className='contentwidth to_build'>
                        To build a robust, feature-rich, and intuitive SharePoint experience, you need expert SharePoint consultants
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: 2 }} className='contentwidth'>
                        Your investment with SharePoint will payoff only when your employees use these tools for higher productivity
                    </Typography>
                    <div className='servicesLogo'>
                        <ul>
                            <li><span>
                                <img
                                     src={SharePoint}
                        alt="sharepoint-consulting"
                                /></span></li>

                        </ul>
                    </div>
                   
                </Container>
            </Box>
            <Box className="outerbox powerbitab  threeTab" sx={{ bgcolor: "#f2c811" }}  >
                {/* <SalesforceServices /> */}
                <ServiceTabbingSection tabData={tabData} />
            </Box>
            <Box sx={{ padding: 4 }} className="contentbox whitebox contentpage"  >
                <Container className="innerpagecontainer contentpage" >
                    <ServiceCardSection cardsData={cardsData} verticalTitle='Driving Seamless Collaboration and Workflow Efficiency Across Verticals with SharePoint Expertise' />
                </Container>
            </Box>
            {/* <ServiceCarousel category='sharepointConsulting' /> */}
            {works.length > 0 &&
                <CustomerStories pageName='SharePoint 2019' workList={works} />
            }
            <CaseStudyLetsGetStarted projectName='Sharepoint Consulting Service' />
            {works.length > 0 ?
                <Footer hasWorkItem={false} /> : <Footer hasWorkItem={true} />
            }
        </>
    );
};

export default SharepointConsultingSection;