import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import ImageSlider from './ImageSlider';
import CaseStudyLetsGetStarted from './CaseStudyLetsGetStarted';
import HeaderSection from '../../sections/HeaderSection';
import { Link } from 'react-router-dom';
import workJson from "../../Work.json";
import CustomerStories from '../../sections/CustomerStories';
import { workCaseStudy } from '../../interface/WorkCaseStudy';
import HeadImg from '../../assets/Images/common/career.jpg';
import Footer from '../../components/Footer';
import { caseStudyWorkFilter } from '../../common-functions/caseStudyWorkFilter';
import useMetaTags from '../../common-functions/useMetaTags';

const SharepointConsultingUSAmeribank1: React.FC = () => {
    useMetaTags(
        "SharePoint Solutions | Case Study - Intranet Portal - BPM",
        "",
        ""
      );
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const additionalKeywords = ['sharepoint','microsoft','office','react','angular','ai','machinelearning'];
        const results = caseStudyWorkFilter(workJson.workCaseStudy,additionalKeywords,'bfsi','sharepoint-consulting-fl-usa-usameribank-1');
        setWorks(results);
    }, []);
    const sliderImages = [
        '/assets/Images/CaseStudy/sharepoint-consulting-fl-usa-usameribank-1/usameribank-1.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-fl-usa-usameribank-1/usameribank-2.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-fl-usa-usameribank-1/usameribank-3.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-fl-usa-usameribank-1/usameribank-4.png',
        '/assets/Images/CaseStudy/sharepoint-consulting-fl-usa-usameribank-1/usameribank-5.png',
    ];
    return (
        <>
            <HeaderSection pageName='Case Study'  imageUrl={HeadImg} alt="Background Image"  />
            <Box className=" whitebox contentbox" id="Project-Overview" sx={{ padding: 4}} >
                <Container className="contentpage">
                    <Box className="casestudy_container">
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} className="compnay_detail_div">
                            <Box flex={1} className="company_logo" sx={{ textAlign: 'center', margin: 5 }}>
                                <img src='/assets/Images/work/usameribank.jpg' alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                            </Box>

                            <Box flex={2} p={2} className="company_detail">
                                <Typography variant="h5" className='cmp_dtl_title'>USAmeribank</Typography>
                                <Box mt={2} className="cmpdtl_fullbox" >
                                <div className="companydetails" >     <ul><li> <b>Location:</b></li><li><span>FL, USA</span></li></ul>
                                    <ul><li> <b>Industry:</b></li><li><span>BFSI</span></li></ul>
                                    <ul><li> <b>Environment:</b></li><li><span>SharePoint 2013</span></li></ul>
                                    <ul><li> <b>Skills:</b></li><li><span>ShareGate, State Machine Workflow, JSLink, JSOM, Rest API</span></li></ul>
                                    <ul><li> <b>Project(s):</b></li><li> <a className='active'><span>Intranet Portal - BPM</span></a></li></ul></div>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                <Container className="innerpagecontainer contentpage prj_overview_fulldiv">
                    {/* {caseStudy?.clientSlogan != null ?  */}
                    <Typography variant="h2" gutterBottom className='prjover-main-title'>
                        USAmeribank Transitions to SharePoint 2013, Enhancing Intranet Portal with Updated Branding and Streamlined Business Process Automation
                    </Typography>
                    {/* : null} */}

                    <Box display="flex" flexDirection={{ xs: 'column', md: "row" }} >
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'column' }} className="prj_overview_div">
                            {/* About the Client */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">About the Client</Typography>
                                <Typography variant='body2' className='prjover-content'>Established in 2007 by the Steans Family, USAmeribank is an independent financial institution that serves both businesses and individuals in Florida and Alabama. With a systematic and methodical business approach, we strive to provide tailored solutions to meet the diverse needs of our clients.</Typography>
                                <Typography variant='body2' className='prjover-content'>With assets exceeding $3 billion, USAmeriBank offers customers customized and flexible solutions to manage their daily financial needs and achieve their objectives, embracing what we refer to as "Craft Banking.”</Typography>

                            </Box>
                            {/* The Challenge */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">The Challenge</Typography>
                                <Typography variant='body2' className='prjover-content' >USAmeriBank previously had their intranet portal set up in MOSS 2007. However, this platform proved to be unstable, resulting in numerous issues for employees. Consequently, the support team was inundated with requests that couldn't be effectively addressed due to suboptimal practices during the initial configuration phase.</Typography>
                                <Typography variant='body2' className='prjover-content'>With the launch of SharePoint 2013, USAmeriBank sought an upgrade to the newest version. Additionally, they aimed to implement an automated project approval process—a streamlined workflow featuring a back-and-forth approach. This automation would replace their current manual process, saving time and minimizing labor-intensive tasks.</Typography>
                            </Box>

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">Objectives</Typography>
                                <Typography variant='body2' className='prjover-content'>"In response to the challenge, we formulated the following objectives: <br />   Ensure the stability of the existing architecture.</Typography>
                                <Typography variant='body2' className='prjover-content'>"Our objectives included: <br />  Migrating from MOSS 2007 to SharePoint 2013.</Typography>
                                <Typography variant='body2' className='prjover-content'>"Additionally, our goals encompassed: <br />  Implementing SharePoint Branding to provide a fresh look and feel for the portal.</Typography>
                                <Typography variant='body2' className='prjover-content'>"Furthermore, we aimed at: <br />  Automating business processes to enhance efficiency.</Typography>
                                <Typography variant='body2' className='prjover-content'>"We also recognized the necessity for: <br />  Classifying projects within the portal based on various user-selected criteria.</Typography>
                                <Typography variant='body2' className='prjover-content'>"Lastly, our objectives entailed: <br />  Incorporating robust search functionality to facilitate easy project discovery on the portal."</Typography>
                            </Box>

                        </Box>
                        <Box display="flex" flexDirection={{ xs: 'column' }} >
                            {/* Image Slider Section */}
                            <Box className="prj_scrnshot" textAlign="center">
                                <ImageSlider imageList={sliderImages || []} />

                            </Box>
                        </Box>
                    </Box>

                    {/* Solution and Benefits */}
                    <Box className="prj_overview_fulldiv">

                        {/* The Solution */}

                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>The Solution</Typography>
                                <Typography variant='body2' className='prjover-content'>"An extensive health-check was conducted to analyze and pinpoint issues within the current intranet portal. Identified issues were effectively addressed and resolved.</Typography>
                                <Typography variant='body2' className='prjover-content'>"Given the scope of migrating to SharePoint 2013, a new architecture was meticulously designed and configured to accommodate the upgrade. Content from the previous environment was seamlessly migrated to the SharePoint 2013 platform using ShareGate tool and PowerShell Scripts.</Typography>
                                <Typography variant='body2' className='prjover-content'>"The intranet portal underwent a comprehensive rebranding process in accordance with design guidelines, incorporating enhancements and leveraging the new features of SharePoint 2013.</Typography>
                                <Typography variant='body2' className='prjover-content'>"To streamline the manual project approval process, a state machine workflow was developed. This 3-stage workflow, consisting of Initiation, Mentoring, and Sharing phases, was crafted as a custom solution using Microsoft Visual Studio 2013.</Typography>
                                <Typography variant='body2' className='prjover-content'>"Within this workflow, users submit A3 reports for approval by their supervisors. Upon submission, the process advances to the Mentoring stage, where supervisors can either request revisions or approve the document, progressing to the next phase. In the final Sharing stage, projects approved by the CI Coach group are published, while others are returned to the user for revision.</Typography>
                                <Typography variant='body2' className='prjover-content'>"Additionally, a dashboard was created to provide users and supervisors with a comprehensive overview of submitted projects and their current statuses.</Typography>
                                <Typography variant='body2' className='prjover-content'>"Furthermore, a custom search feature was implemented, allowing users to efficiently search for projects using filters such as department, supervisor, title, or relevant keywords."</Typography>
                        </Box>
                        {/* Benefits */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>Benefits</Typography>
                                <Typography variant='body2' className='prjover-content'>A reliable environment for their SharePoint Intranet Portal.</Typography>
                                <Typography variant='body2' className='prjover-content'>Enhanced user experience through redesigned branding and migration to SharePoint 2013 from MOSS 2007.</Typography>
                                <Typography variant='body2' className='prjover-content'>The implementation of Business Process Automation significantly reduced manual time and effort for employees.</Typography>
                                <Typography variant='body2' className='prjover-content'>Dashboard implemented for improved tracking of pending project statuses.</Typography>
                                <Typography variant='body2' className='prjover-content'>Enhanced access to information facilitated by improved search functionality utilizing SharePoint Tailored Webpart.</Typography>
                        </Box>

                    </Box>
                </Container >
            </Box >
              <Box id="Case-Study-List">
                    {works.length > 0 &&
                    <CustomerStories pageName='caseStudy' workList={works} />
                }
                </Box>
            <CaseStudyLetsGetStarted projectName='Intranet Portal - BPM' />
            {works.length > 0 ?
                <Footer hasWorkItem={false}/>:<Footer hasWorkItem={true}/>
            }
        </>
    );
};

export default SharepointConsultingUSAmeribank1;
