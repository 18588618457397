import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import CaseStudyLetsGetStarted from './CaseStudyLetsGetStarted';
import HeaderSection from '../../sections/HeaderSection';
import workJson from "../../Work.json";
import { workCaseStudy } from '../../interface/WorkCaseStudy';
import CustomerStories from '../../sections/CustomerStories';
import HeadImg from '../../assets/Images/common/career.jpg';
import Footer from '../../components/Footer';
import { caseStudyWorkFilter } from '../../common-functions/caseStudyWorkFilter';
import useMetaTags from '../../common-functions/useMetaTags';

const ApplicatioDevelopmentPinscriptive1: React.FC = () => {
    useMetaTags(
        "SharePoint Solutions | Case Study ",
        "",
        ""
      );
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const additionalKeywords = ['application development','ai','machinelearning','microsoft'];
        const results = caseStudyWorkFilter(workJson.workCaseStudy,additionalKeywords,'healthcare','application-development-ca-usa-pinscriptive-1');
        setWorks(results);
    }, []);
    return (
        <>
           <HeaderSection pageName='Case Study'  imageUrl={HeadImg} alt="Background Image" />
            <Box className=" whitebox contentbox" id="Project-Overview" sx={{ padding: 4}} >
                <Container className="contentpage">
                    <Box className="casestudy_container">
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} className="compnay_detail_div">
                            <Box flex={1} className="company_logo" sx={{ textAlign: 'center', margin: 5 }}>
                                <img src='/assets/Images/work/pinscriptive.jpg' alt="Company Logo" style={{ width: '100%', height: '100%' }} />
                            </Box>

                            <Box flex={2} p={2} className="company_detail">
                                <Typography variant="h5" className='cmp_dtl_title'>Pinscriptive</Typography>
                                <Box mt={2} className="cmpdtl_fullbox" >
                               <div className="companydetails" >
                                    <ul><li> <b>Location:</b></li><li><span>CA, USA</span></li></ul>
                                    <ul><li> <b>Industry:</b></li><li><span>Healthcare</span></li></ul>
                                    <ul><li> <b>Environment:</b></li><li><span>Application Development</span> </li></ul>
                                    <ul><li> <b>Skills:</b></li><li><span>Meteor, jQuery, Healthcare Startup, Big Data Analytics, MySQL, Machine Learning</span></li></ul>
                                    <ul><li> <b>Project(s):</b></li><li><a className='active'><span>Specialty Drug Dashboard</span></a></li> </ul>
                                    </div>                                   
                                     </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                <Container className="innerpagecontainer contentpage prj_overview_fulldiv">
                    <Typography variant="h2" gutterBottom className='prjover-main-title'>
                        Pinscriptive Inc. employs AI, Big Data Analytics, and the Meteora framework to aid patients afflicted with Hepatitis C
                    </Typography>
                    {/* : null} */}

                    <Box display="flex" flexDirection={{ xs: 'column', md: "row" }} >
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'column' }} className="prj_overview_div">
                            {/* About the Client */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">About the Client</Typography>
                                <Typography variant='body2' className='prjover-content'>Pinscriptive Inc., established in 2014 in Greater LA, is dedicated to offering software analytics on specialty drugs for healthcare providers.</Typography>
                                <Typography variant='body2' className='prjover-content'>These analytics assist healthcare providers in making value-based decisions and provide evidence to identify the most suitable drugs for individual patients, facilitating the production of the most appropriate specialty drugs. As a result, healthcare providers can confidently...</Typography>
                                <Typography variant='body2' className='prjover-content'>The company's platform utilizes data analytics to pinpoint clinically effective specialty medications at an optimal cost for each patient, empowering healthcare professionals to make informed decisions regarding specialty drugs. This approach reduces costs and enhances outcomes, ensuring more effective treatment for patients.</Typography>
                            </Box>
                            {/* The Challenge */}

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">The Challenge</Typography>
                                <Typography variant='body2' className='prjover-content' >
                                    <Typography variant='body2' className='prjover-content'>Pinscriptive undertook the development of a specialized tool designed to process raw data specifically for patients with Hepatitis C stored in MySQL Server. This tool was tasked with transforming the processed data into visual representations.</Typography>
                                    <Typography variant='body2' className='prjover-content'>The functionality and visualization of the tool relied on various parameters. Accuracy was paramount, necessitating identification and handling of missing data.</Typography>
                                    <Typography variant='body2' className='prjover-content'>Furthermore, rigorous verification and authentication protocols were implemented to ensure the utmost accuracy of the represented data, recognizing the critical potential impact on patient outcomes.</Typography>
                                    <Typography variant='body2' className='prjover-content'>This tool was intended for use by physicians, pharmacists, healthcare providers, and insurance companies alike.</Typography>
                                </Typography>
                            </Box>

                            <Box flex={1} className="prj_overview">
                                <Typography variant="h6" className="prjover-title">Objectives</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>-Processing aggregated data from MySQL Server using algorithms tailored specifically for Hepatitis C.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>-Visualize data through graphical representations.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>-A dashboard to showcase patients' characteristics, payer information, and other pertinent details.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>-The dashboard should exhibit distributions such as cost, treatment, and genotype breakdowns using charts and other visualizations.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>-Offering filters to search patient details based on age range, ethnicity, and other medical attributes.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>-Physicians can access details of specialty drugs from various providers and utilize this information to compare results.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>-A feature for physicians to access a list of recommended drugs based on insurance coverage and risk characteristics.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>-For pharmacists, there should be a distinct comparison based on various parameters.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>-A dedicated Analytics tab for viewing both descriptive and predictive analytics.</Typography>
                                <Typography variant='body2' className='prjover-content' style={{ paddingLeft: '30px' }}>-Verification of patient treatment to ensure data accuracy and treatment appropriateness.</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection={{ xs: 'column' }} >
                        </Box>
                    </Box>

                    {/* Solution and Benefits */}
                    <Box className="prj_overview_fulldiv">
                        {/* The Solution */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>The Solution</Typography>
                            <Typography variant='body2' className='prjover-content'>Binary Republik was supplied with raw data from Pinscriptive. The task involved processing the data using adaptive algorithms capable of identifying Hepatitis C patients. Additionally, this process encompassed the removal of irrelevant data from the aggregated dataset.</Typography>
                            <Typography variant='body2' className='prjover-content'>This system was designed specifically for use by physicians, healthcare providers, insurance companies, and pharmacists.</Typography>
                            <Typography variant='body2' className='prjover-content'>Employing diverse adaptive algorithms and big data analytics techniques, we processed the data and presented it to specialized doctors for authentication. Further segmentation of the data was required based on the users utilizing the system.</Typography>
                            <Typography variant='body2' className='prjover-content'>We needed to develop an architecture and platform that would be beneficial for various user groups, considering the processed data. This processed data was visualized using custom visualization tools, including Python and various other technologies.</Typography>
                            <Typography variant='body2' className='prjover-content'>Data analysis is conducted using a set of algorithms to uncover correlations within the clinical data. Subsequently, various charts are generated to visually represent the clinical data. Predictive analytics are then applied to further analyze the data, determining the optimal drug for the patient while considering the ideal scenario. Additionally, predictive models are developed based on the received data to forecast outcomes for unknown patients.</Typography>
                            <Typography variant='body2' className='prjover-content'>Therefore, this tool proves valuable for specialty biopharmaceutical manufacturers, providing a model that transitions their products "from volume to value." It supports risk-based contracts with precision analytics, thereby boosting sales, fostering innovation in specialty drugs, managing healthcare budgets, and offering insights into current market trends and scenarios.</Typography>
                        </Box>
                        {/* Benefits */}
                        <Box flex={1} className="prj_overview">
                            <Typography variant="h6" className='prjover-title'>Benefits</Typography>
                            <Typography variant='body2' className='prjover-content'>This product will facilitate the delivery of insights into specialty drug usage and costs, harnessing the power of Big Data analytics, a suite of proprietary algorithms, and the integration of real-world data from Electronic Medical Records (EMRs).</Typography>
                            <Typography variant='body2' className='prjover-content'>By employing machine learning, population analytics, and leveraging existing knowledge and experience, this tool aids in managing specialty drug trends, thereby enabling the implementation of a disruptive pricing model.</Typography>
                            <Typography variant='body2' className='prjover-content'>Assists insurance companies, patients, and their physicians in discovering cost-effective specialty drug solutions by identifying the precise drug for the right patient at the appropriate price</Typography>
                            <Typography variant='body2' className='prjover-content'>Most precise data regarding Hepatitis C patients.</Typography>
                            <Typography variant='body2' className='prjover-content'>Assists payers, at-risk health systems, and accountable care organizations in optimizing specialty drug decisions through foolproof decision-making, taking into account both quality and cost insights.</Typography>
                            <Typography variant='body2' className='prjover-content'>Clients can access visualizations to gain insights into the market landscape.</Typography>
                            <Typography variant='body2' className='prjover-content'>Physicians can determine whether the data is missing or if the treatment is incorrect.</Typography>
                        </Box>
                    </Box>
                </Container >
            </Box >
             <Box id="Case-Study-List">
            {works.length>0 &&
                    <CustomerStories pageName='caseStudy' workList={works} />
            }
            </Box>
            <CaseStudyLetsGetStarted projectName='Pinscriptive' />
            {works.length > 0 ?
                <Footer hasWorkItem={false}/>:<Footer hasWorkItem={true}/>
            }
        </>
    );
};

export default ApplicatioDevelopmentPinscriptive1;
