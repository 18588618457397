import React, { useEffect, useState } from 'react';
import { Box, Container, List, ListItem, ListItemText, Typography } from '@mui/material';
import Salesforce from '../assets/Images/technologies/Salesforce.png';
import SalesforCeconsulting from '../assets/Images/services/salesforce-consulting.png';
import CustomerStories from './CustomerStories';
import SalesforceServices from '../sub-sections/HomePageServices/SalesforceServices';
import workJson from "../../src/Work.json";
import { workCaseStudy } from '../interface/WorkCaseStudy';
import Footer from '../components/Footer';
import { serviceWorkFilter } from '../common-functions/serviceWorkFilter';
import useMetaTags from '../common-functions/useMetaTags';
import ServiceTabbingSection from '../sub-sections/ServiceCommonComponent/ServiceTabbingSection';
import { CardData, TabData } from '../interface/TabData';
import ServiceCardSection from '../sub-sections/ServiceCommonComponent/ServiceCardSection';
import CaseStudyLetsGetStarted from '../sub-sections/CaseStudy/CaseStudyLetsGetStarted';

const SalesforceConsultingSection: React.FC = () => {
  useMetaTags(
    "Salesforce Implementation & Optimization | CRM Consulting Experts",
    "Unlock the full power of Salesforce with tailored consulting services. From setup to automation, we help businesses enhance productivity, scale efficiently, and achieve measurable success. Get started now!",
    "salesforce strategy, salesforce cloud solutions, salesforce workflow automation, salesforce data migration, salesforce business transformation, CRM implementation, salesforce certified consultants"
  );
    const tabData: TabData[] = [
        {
          heading: (<>Salesforce Design & <br></br>Implementation</>),
          imgSrc: `<div class="techno_icon">
            <a class="sharepoint_logo1"> <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown" src="/assets/Images/services/salesforce/dilogo1.png" alt="sales-cloud"> </a>
            <a class="sharepoint_logo2"> <img class="animation-img-class animation-delay600 disnone img-responsive wow fadeInDown" src="/assets/Images/services/salesforce/dilogo2.png" alt="service-cloud"> </a>
            <a class="sharepoint_logo3"> <img class="animation-img-class animation-delay900 disnone img-responsive wow fadeInDown" src="/assets/Images/services/salesforce/dilogo3.png" alt="marketing-cloud"> </a>
            <a class="sharepoint_logo4"> <img class="animation-img-class animation-delay1200 disnone img-responsive wow fadeInDown" src="/assets/Images/services/salesforce/dilogo5.png" alt="commerce-cloud"> </a>
            <a class="sharepoint_logo5"> <img class="animation-img-class animation-delay1500 disnone img-responsive wow fadeInDown" src="/assets/Images/services/salesforce/AIcloud.png" alt="AI-cloud"> </a>
          </div>`,
          content: (
            <>
              <p>Implementing Salesforce successfully requires a strategic approach to optimize business processes, increase efficiency, and improve customer relationships.</p>
              <p>We offer end-to-end Salesforce Implementation Services, integrating AI-powered automation, Einstein Analytics, and industry-specific solutions to help you maximize ROI.</p>
              <p>Our Services include - <a href="/work" onClick={() => selectedTechnology('Salesforce')}>Sales Cloud</a>, <a href="/work" onClick={() => selectedTechnology('Salesforce')}>Service Cloud</a>, <a href="/work" onClick={() => selectedTechnology('Salesforce')}>Marketing Cloud</a>, <a href="/work" onClick={() => selectedTechnology('Salesforce')}>Commerce Cloud</a>, and <a href="/work" onClick={() => selectedTechnology('Salesforce')}>AI Cloud</a>.</p>
            </>
          ),
        },
        {
          heading: (<>Salesforce <br></br>Customization</>),
          imgSrc: `<div class="techno_icon">
            <a class="app6"> <img src="/assets/Images/services/salesforce/salceforce-hor.png" class="animation-img-class animation-delay300 img-responsive wow fadeInDown" alt="salesforce"> </a>
            <a class="app2"> <img class="animation-img-class animation-delay600 img-responsive wow fadeInDown" src="/assets/Images/services/salesforce/reactjs.png" alt="React.js"> </a>
            <a class="app3"> <img class="animation-img-class animation-delay900 img-responsive wow fadeInDown" src="/assets/Images/services/salesforce/html-css.png" alt="HTML CSS"> </a>
            <a class="app4"> <img class="animation-img-class animation-delay1200 img-responsive wow fadeInDown" src="/assets/Images/services/salesforce/vue.png" alt="Vue.js"> </a>
          </div>`,
          content: (
            <>
              <p>Outdated processes slow down growth. Our Salesforce Customization Services help automate workflows, integrate AI, and implement Lightning Web Components (LWC) for a modernized user experience.</p>
              <p>We analyze your business processes, identify gaps, and implement custom Salesforce solutions to enhance productivity and streamline operations.</p>
              <p>We specialize in Force.com development, Apex programming, AI-powered workflows, and Salesforce Lightning. Explore our <a href="/work" onClick={() => selectedTechnology('Salesforce')}>Custom Development Portfolio</a>.</p>
            </>
          ),
        },
        {
          heading: (<>Integration With <br></br>Salesforce</>),
          imgSrc: `<div class="techno_icon inte_thumb">
            <img class="animation-img-class animation-delay300 disnone img-responsive wow fadeInDown" src="/assets/Images/services/salesforce/integration.png" alt="Salesforce Integration">
          </div>`,
          content: (
            <>
              <p>Struggling with disconnected systems and data silos? Our Salesforce Integration Services seamlessly connect Salesforce with ERP, AI-powered analytics, marketing platforms, and third-party applications.</p>
              <p>We integrate with RESTful & SOAP APIs, QuickBooks, Azure, AWS, SAP, Google Cloud, and AI-driven tools to enable real-time data sync and automation.</p>
              <p>Explore our <a href="/work" onClick={() => selectedTechnology('Salesforce')}>Salesforce Integration Projects</a>.</p>
            </>
          ),
        },
        {
          heading: (<>Salesforce <br></br>Migration</>),
          imgSrc: `<div class="techno_icon migr_thumb">
            <img class="ti-animation-fadein ti-animation-delay600 img-responsive wow fadeInDown" src="/assets/Images/services/salesforce/migration.png" alt="Salesforce Migration">
          </div>`,
          content: (
            <>
              <p>Moving to Salesforce? Our Salesforce Migration Services ensure a smooth transition with zero data loss, AI-based mapping, and post-migration optimization.</p>
              <p>We use advanced ETL tools, data validation techniques, and AI-driven migration insights to help businesses migrate from legacy systems, spreadsheets, or other CRM platforms to Salesforce.</p>
              <p>Check out our <a href="/work" onClick={() => selectedTechnology('Salesforce')}>Salesforce Migration Projects</a>.</p>
            </>
          ),
        },
        {
          heading: (<>Salesforce Mobility <br></br>Solution</>),
          imgSrc: `<div class="techno_icon mobsol_thumb">
            <img class="animation-img-class animation-delay300 img-responsive wow fadeInDown" src="/assets/Images/services/salesforce/mobility_solution.png" alt="Salesforce Mobility Solution" style="width: 150px;margin-top: 9%;">
          </div>`,
          content: (
            <>
              <p>Need to access Salesforce on the go? Our Salesforce Mobility Solutions leverage Salesforce Mobile SDK, AI-powered chatbots, and custom mobile app development.</p>
              <p>We specialize in building progressive web apps (PWAs), AI-driven mobile dashboards, and cross-platform Salesforce mobile solutions.</p>
              <p>View our latest <a href="/work" onClick={() => selectedTechnology('Salesforce')}>Mobile Projects</a>.</p>
            </>
          ),
        },
      ];
      
    const cardsData: CardData[] = [
        {
            heading: 'Sustainable Energy Solutions',
            items: ['Smart Grid Management', 'Renewable Energy Forecasting', 'Customer Energy Portals', 'Automated Compliance Reporting', 'Predictive Maintenance for Infrastructure', 'Carbon Footprint Tracking'],
        },
        {
            heading: 'Media and Entertainment Evolution',
            items: ['Audience Engagement Platforms', 'AI-Driven Content Creation', 'Streaming Service Optimization', 'Royalty and Rights Management', 'Ad Campaign Performance Tracking', 'Virtual Event Hosting'],
        },
        {
            heading: 'Logistics and Supply Chain Innovation',
            items: ['Real-Time Fleet Tracking', 'Warehouse Automation', 'Demand Forecasting', 'Supplier Collaboration Portals', 'Last-Mile Delivery Solutions', 'Sustainability in Logistics'],
        },
        {
            heading: 'Travel and Hospitality Transformation',
            items: ['Personalized Guest Experiences', 'AI-Powered Booking Systems', 'Loyalty Program Management', 'Real-Time Feedback Analysis', 'Operational Efficiency Tools', 'Crisis Management Solutions'],
        },
    ];
    
    const selectedTechnology = (technology: string) => {
        sessionStorage.setItem("Selected-Vertical", 'All');
        sessionStorage.setItem("Selected-Technology", technology);
    }
    useMetaTags(
        "SharePoint Consulting Services | Binary Republik",
        "Engage with a highly skilled team of SharePoint Consultants for your Organization",
        "SharePoint Consulting Service, Sharepoint consulting companies, Sharepoint consulting companies in USA, Offshore SharePoint Consulting partners"
    );
    const [works, setWorks] = useState<workCaseStudy[]>([]);
    useEffect(() => {
        const keywords = ['salesforce'];
        const additionalKeywords = ['ai', 'artificialintelligence', 'machinelearning', 'azure', 'aws', 'cloud','salesforce','technology','mobile'];

        const results = serviceWorkFilter(workJson.workCaseStudy, keywords, additionalKeywords);
        setWorks(results);
    }, []);
    
    return (
        <>
            <Box sx={{ padding: 4, textAlign: 'center' }} className="contentbox whitebox " >
                <Container className="innerpagecontainer contentpage">
                    <Typography variant="h2" gutterBottom className='contentwidth to_build'>
                    Revolutionize Your Business with Tailored Salesforce Strategies That Deliver Real Results</Typography>
                    <Typography variant="body1" sx={{ marginBottom: 2 }} className='contentwidth'>
                    Unlock Salesforce’s full potential with expert development, tailored solutions, and seamless CRM optimization to drive growth and efficiency </Typography>
                    
                    <div className='servicesLogo'>
                        <ul>
                            <li><span>
                                <img
                                     src={SalesforCeconsulting}
                        alt="Salesforce Consulting"
                                /></span></li>

                        </ul>
                    </div>
                </Container>
            </Box>
            <Box className="outerbox powerbitab" sx={{ bgcolor: "#f2c811" }}  >
            {/* <SalesforceServices /> */}
                        <ServiceTabbingSection tabData={tabData} />
                        </Box>
                    <Box sx={{ padding: 4 }} className="contentbox whitebox contentpage"  >
                        <Container className="innerpagecontainer contentpage" >
                            <ServiceCardSection cardsData={cardsData} verticalTitle='Transforming Industries with Tailored Salesforce Solutions Across Verticals'  />
                        </Container>
                    </Box>
            {works.length > 0 &&
                <CustomerStories pageName='salesforce' workList={works} />}
                 <CaseStudyLetsGetStarted projectName='Salesforce Consulting Service' />
            {works.length > 0 ?
                <Footer hasWorkItem={false} /> : <Footer hasWorkItem={true} />
            }
        </>
    );
};

export default SalesforceConsultingSection;
